import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./Tokens.module.scss";
import btnStyles from "../UI/Button/Button.module.scss";
import { Button } from "../UI/Button/Button";
import { TokenImageByURI } from "../TokenImageByURI/TokenImageByURI";

const InvestmentLink = ({ getLink, token }) => {
  const { t } = useTranslation();
  const gettingLink = getLink(token);

  if (typeof gettingLink === "string") {
    return (
      <NavLink to={gettingLink}>
        <Button className={btnStyles.small}>{t("tokens.token.invest")}</Button>
      </NavLink>
    );
  }

  if (typeof gettingLink === "function") {
    return (
      <Button className={btnStyles.small} onClick={gettingLink}>
        {t("tokens.token.invest")}
      </Button>
    );
  }

  return null;
};

export const Token = observer(({ token, getLink, onTokenClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.token}
      onClick={onTokenClick.bind(null, token)}
      role="presentation"
    >
      {token?.soldOut && (
        <div className={styles.token__soldOut}>
          <div className={styles.line} />
          <div className={styles.text}>{t("tokens.token.soldOut")}</div>
        </div>
      )}
      <div className={styles.token__header}>
        <div className={styles.token__icon}>
          <TokenImageByURI tokenId={token.id} />
          {/*  <img alt="token" src={token.icon} /> */}
        </div>
        <div className={styles.token__name}>{token.name}</div>
        <InvestmentLink token={token} getLink={getLink} />
      </div>
      <div className={styles.token__content}>
        <div className={styles.token__block}>
          <div className={styles.description__item}>
            <span className={styles.label}>{t("tokens.token.location")}</span>
            <span className={styles.location}>{token.location}</span>
          </div>
          <div className={styles.description__item}>
            <span className={styles.label}>
              {t("tokens.token.myInvestment")}
            </span>
            <span>{token.equivalentMy || "0,00 €"}</span>
          </div>
          <div className={styles.description__item}>
            <span className={styles.label}>{t("tokens.token.myTokens")}</span>
            <span>{token.investedMy || "0"}</span>
          </div>
        </div>

        <div className={styles.token__block}>
          <div className={styles.description__item}>
            <span className={styles.label}>{t("tokens.token.price")}</span>
            <span>
              {token.tokenPrice} {token.currency}
            </span>
          </div>
          {/* <div className={styles.description__item}> */}
          {/*   <span className={styles.label}> */}
          {/*     {t("tokens.token.tokenStatistics")} */}
          {/*   </span> */}
          {/*   <span>{token.tokensStatistics}</span> */}
          {/* </div> */}
        </div>
      </div>
      <div className={cn(styles.description__item, styles.marginTop24)}>
        <span className={styles.label}>{t("tokens.token.productType")}</span>
        <span>{token.name}</span>
      </div>
    </div>
  );
});
