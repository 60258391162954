import { useTranslation } from "react-i18next";
import { Dropdown } from "../UI/Dropdown/Dropdown";
import { GENDER } from "../../store/consts";

export const GenderDropdown = ({ defaultValue, onChange, error, disabled }) => {
  const { t } = useTranslation();

  const options = [
    {
      title: t(`genderOptions.${GENDER.MALE}`),
      value: GENDER.MALE,
    },
    {
      title: t(`genderOptions.${GENDER.FEMALE}`),
      value: GENDER.FEMALE,
    },
    {
      title: t(`genderOptions.${GENDER.OTHER}`),
      value: GENDER.OTHER,
    },
  ];

  return (
    <Dropdown
      options={options}
      label={t("settings.gender")}
      showResetOption={false}
      defaultValue={defaultValue}
      onChange={onChange}
      width={112}
      error={error}
      disabled={disabled}
    />
  );
};
