import { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./RadioGroup.module.scss";

export const RadioGroup = ({
  items,
  name,
  className,
  defaultActiveItem,
  onChange = () => {},
}) => {
  const [active, setActive] = useState(defaultActiveItem);

  const onChangeHandler = (item) => {
    setActive(item.value);
    onChange(item);
  };

  useEffect(() => {
    if (defaultActiveItem !== undefined) {
      setActive(defaultActiveItem.value);
    }
  }, [defaultActiveItem]);

  return (
    <div
      className={cn({
        [`${className}`]: !!className,
        [`${styles.radioGroup}`]: true,
      })}
    >
      {items.map((btn) => {
        return (
          <label
            key={btn.value}
            className={cn(styles.radioGroup__item)}
            role="presentation"
          >
            <input
              type="radio"
              name={name}
              onChange={onChangeHandler.bind(null, btn)}
            />
            <span
              className={cn({
                [`${styles.radioGroup__radio}`]: true,
                [`${styles.active}`]: btn.value === active,
              })}
            />
            <span className={styles.radioGroup__text}>{btn.title}</span>
          </label>
        );
      })}
    </div>
  );
};
