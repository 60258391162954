import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Checkbox } from "../../../components/UI/Checkbox/Checkbox";
import styles from "../InvestmentData.module.scss";
import { StepsButtons } from "./StepsButtons";
import useStore from "../../../hooks/useStore";
import formatMoney from "../../../utils/formatMoney";

export const Investigation = observer(({ setDisabledNext }) => {
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const {
    tokens: {
      token,
      tokens,
      getAccumulatedTokenValue,
      accumulatedTokenValue,
      selfTokens,
    },
    investment: {
      activeTransaction,
      steps: { confirmTwiceAverage, confirmDisposable, updateField },
    },
  } = useStore();

  const toStep2 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/2`);
  };

  const onPrevClick = () => {
    const existsToken = selfTokens?.find(({ id }) => id === token.id);

    navigate(
      existsToken
        ? `/tokens/${tokenId}/investment`
        : `/home/${token.id}/investment/new`
    );
  };

  const tokenName = useMemo(
    () => tokens?.find((token) => token.id === +tokenId)?.name,
    [tokenId, tokens.length]
  );
  const tokenPrice = useMemo(
    () => tokens?.find((token) => token.id === +tokenId)?.tokenPrice,
    [tokenId, tokens.length]
  );

  const onChangeConfirmTwiceAverage = () => {
    updateField("confirmTwiceAverage", !confirmTwiceAverage);
  };

  const getAccumulatedValue = () => {
    return +activeTransaction?.investmentAmount
      ?.replaceAll("€", "")
      ?.trim()
      ?.replaceAll(".", "")
      ?.replaceAll(",", ".");
    // + parseInt(tokenPrice, 10) * accumulatedTokenValue
  };

  const showConfirmTwice = () => {
    return getAccumulatedValue() > 1000 && getAccumulatedValue() <= 10000;
  };

  const isDisabledNext = () => {
    if (getAccumulatedValue() <= 1000) {
      return false;
    }
    if (!showConfirmTwice()) {
      return !confirmTwiceAverage;
    }
    return !confirmDisposable && !confirmTwiceAverage;
  };

  const onChangeConfirmDisposable = () => {
    updateField("confirmDisposable", !confirmDisposable);
  };

  useEffect(() => {
    setDisabledNext(!confirmDisposable && !confirmTwiceAverage);
  }, [confirmDisposable, confirmTwiceAverage]);

  useEffect(() => {
    const getData = async () => {
      await getAccumulatedTokenValue({ tokenId });
    };
    getData();
  }, [tokenId]);

  return (
    <div className={styles.investigation}>
      <h4 className={styles.header}>
        {t("investment.investToToken")} {tokenName || token.name}
      </h4>
      {/* <p className={styles.text}>{t("investment.chooseTheAmountToInvest")}</p> */}

      <p className={styles.amount}>
        <span className={styles.amount__header}>{t("investment.amount")}</span>
        <span className={styles.amount__value}>
          {activeTransaction?.investmentAmount}
        </span>
      </p>

      <div className={styles.investigation__footer}>
        {getAccumulatedValue() > 1000 && (
          <>
            <h4 className={styles.header}>{t("investment.selfDisclosure")}</h4>
            <p className={styles.text}>{t("investment.investmentTerms1")}</p>
            <p className={cn(styles.text, styles.resetMargin)}>
              {t("investment.investmentTerms2")}
            </p>
          </>
        )}
        <p className={styles.text}>
          {getAccumulatedValue() > 1000 &&
            getAccumulatedValue() <= 10000 &&
            t("investment.investmentTerms3", {
              value: formatMoney(getAccumulatedValue()),
            })}
          {getAccumulatedValue() > 10000 &&
            t("investment.investmentTerms4", {
              value: formatMoney(getAccumulatedValue()),
            })}
        </p>
        <div className={styles.investigation__checkboxes}>
          {getAccumulatedValue() > 1000 && (
            <div className={styles.item}>
              <Checkbox
                label={t("investment.confirmTwiceAverageIncome")}
                initialState={confirmTwiceAverage}
                onChange={onChangeConfirmTwiceAverage}
                mode="dark"
              />
            </div>
          )}
          {showConfirmTwice() && (
            <div className={styles.item}>
              <Checkbox
                label={t("investment.confirmDisposableAssets")}
                initialState={confirmDisposable}
                onChange={onChangeConfirmDisposable}
                mode="dark"
              />
            </div>
          )}
        </div>
      </div>
      <StepsButtons
        showPrev
        onNextClick={toStep2}
        onPrevClick={onPrevClick}
        disabledNext={isDisabledNext()}
      />
    </div>
  );
});
