import React from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/index.scss";
import { ProjectRoutes } from "../ProjectRoutes/ProjectRoutes";
import RootStore from "../../store";
import { StoreContext } from "../../store/storeContext";
import { Spinner } from "../UI/Spinner/Spinner";

const store = RootStore.create({});

function App() {
  return (
    <StoreContext.Provider value={store}>
      <BrowserRouter>
        <Spinner>
          <ProjectRoutes />
        </Spinner>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
        />
      </BrowserRouter>
    </StoreContext.Provider>
  );
}

export default App;
