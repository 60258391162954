import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { SearchInput } from "../../components/UI/SearchInput/SearchInput";
import useSizes from "../../hooks/useSizes";
import { RecentTransactions } from "../../components/Transactions/RecentTransactions";
import { TransactionsTable } from "../../components/Transactions/TransactionsTable";
import { Pagination } from "../../components/UI/Pagination/Pagination";
import styles from "./TransactionHistory.module.scss";
import { Dropdown } from "../../components/UI/Dropdown/Dropdown";
import { ReactComponent as Calendar } from "../../icons/calendar.svg";
import useStore from "../../hooks/useStore";
import { EmptyTransactionHistory } from "../../components/Transactions/EmptyTransactionHistory";
import { Page } from "../../components/Page/Page";

export const TransactionHistory = observer(() => {
  const { t } = useTranslation();
  const { isMobile } = useSizes();

  const options = new Array(12).fill(undefined).map((_, index) => {
    return {
      title: t(`dates.month.${index}`),
      value: index,
    };
  });

  const {
    app: { markLoading },
    me: {
      transactions: {
        pagination,
        updatePagination,
        getTransactions,
        groupedTransactionsByDate,
        list,
        loading,
        tableTransactions,
      },
    },
  } = useStore();

  const onChangeQuery = (value) => {
    updatePagination("query", value);
  };

  const onChangeMonth = ({ value }) => {
    updatePagination("month", value);
  };

  const onPaginate = ({ selected }) => {
    updatePagination("pageNumber", selected);
  };

  const getTransactionsData = async (pagination) => {
    markLoading(true);
    await getTransactions(pagination);
    markLoading(false);
  };

  useEffect(
    () => () => {
      updatePagination("pageNumber", 0);
      updatePagination("month", null);
      updatePagination("query", "");
    },
    []
  );

  return (
    <Page>
      <div className={styles.transaction_history}>
        <div className={styles.transaction_history__header}>
          <SearchInput
            placeholder={t("transactions.searchTransaction")}
            onChange={onChangeQuery}
          />
          <Dropdown
            width={160}
            icon={<Calendar />}
            placeholder={t("transactions.month")}
            options={options}
            onChange={onChangeMonth}
          />
        </div>
        {isMobile && (
          <RecentTransactions
            getTransactions={getTransactionsData}
            showEmptyState={false}
          />
        )}
        {!isMobile && (
          <TransactionsTable getTransactions={getTransactionsData} />
        )}

        {!loading && tableTransactions.length === 0 && (
          <EmptyTransactionHistory />
        )}

        {groupedTransactionsByDate?.length > 0 && !loading && (
          <div className={styles.transaction_history__pagination}>
            <Pagination
              onPaginate={onPaginate}
              pageCount={pagination.total}
              initialPage={pagination.pageNumber}
            />
          </div>
        )}
      </div>
    </Page>
  );
});
