import { useMemo } from "react";

export const useCountriesOptions = ({ countries }) => {
  return useMemo(() => {
    return countries?.map((country) => ({
      title: country.name,
      value: country.id,
      icon: country.flag,
      country,
    }));
  }, [countries.length]);
};
