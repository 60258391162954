/* eslint-disable no-underscore-dangle */
import {
  BOUNDS,
  EXPERIENCE_DURATION,
  EXPERIENCE_VALUE,
  GENDER,
  KIND_OF_ACTIVITY,
  PASSPORTS,
  PAYMENT_STATUS,
  STUDENT_LEVEL,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "../../store/consts";

const translation = {
  general: {
    invest: "Invest",
    open: "Open",
    sell: "Sell",
    delete: "Delete",
    edit: "Edit",
    saveChanges: "save changes",
    next: "next",
    ok: "Ok",
    copiedToClipboard: "Copied to clipboard",
    abort: "Abort",
    yes: "Yes",
    no: "No",
    save: "Save",
    billing: "Billing",
    update: "Update",
    changeDetails: "Change details",
    return: "Return",
    download: "Download",
    done: "Done",
    more: "More",
  },
  risk: {
    riskNotice: "RISK NOTICE:",
    riskDescription: `The acquisition of this security is associated with 
      considerable risks and can lead to the complete loss of the invested capital.`,
  },
  messanges: {
    success: "Success!",
    dataUploaded: "Data uploaded.",
  },
  dates: {
    monthShort: "mths",
    years: "Years",
  },
  login: {
    login: "Login",
    pleaseSignInToYourAccount: "Please sign in to your account",
    email: "E-Mail",
    password: "Password",
    forgotPassword: "Forgot password ?",
    signIn: "Sign in",
    dontHaveAnAccount: "Don’t have an Account?",
    signUp: "Sign Up",
    verifyItsYou: "Verify it’s you",
    confirmTheEmail: "Confirm the email you provided in your settings",
    verificationCode: "Verification Code",
    didNotGotTheCode: "Didn’t got the code?",
    resend: "Resend",
    letsStart: "Let’s start",
    pleaseEnterYourCredentials: "Please enter your credentials to proceed",
    confirmPass: "Confirm Password",
    resetPassword: "Reset password",
    enterYourEmail: "Enter your Email we’ll send you 5 digits code",
    enterFiveDigitsCode: "Enter 5 digits code",
    createNewPassword: "Create a new password",
    comeUpWithNewIdiaForYourPassword: "Come up with new idea for your password",
    errors: {
      minLength: "{{count}} symbols",
      capitalLetter: "Capital letter",
      lowerCase: "Lowercase",
      number: "Number",
      theCodeIsntCorrect: "The code isn’t correct",
      wrongPassword: "Passwort ist nicht korrekt. Versuchen Sie es nochmal!",
      enterCorrectEmail: "Bitte geben Sie eine gültige Email Adresse an",
      passwordMustMatch: "Passwort muss übereinstimmen.",
      userDoesNotExists: "The email you entered does not exist in our system",
      emailIsRequired: "E-mail is required",
    },
    privacy: {
      iAgreeToThe: "I agree to the",
      termsOfService: "Terms of service",
      and: "and",
      privacyPolicy: "Privacy Policy",
    },
  },
  dropdown: {
    kyc: {
      studentLevel: {
        [STUDENT_LEVEL.KEINE]: "Keine",
        [STUDENT_LEVEL.HIGH_SCHOOL_DIPLOMA]: "High school diploma",
        [STUDENT_LEVEL.TEACHING_VOCATIONAL_TRAINING]:
          "Teaching vocational training",
        [STUDENT_LEVEL.REAL_SCHOOL_QUALIFICATION]: "Real school qualification",
        [STUDENT_LEVEL.COMPLETED]: "Completed",
      },
      employmentType: {
        [KIND_OF_ACTIVITY.KEINE]: "None",
        [KIND_OF_ACTIVITY.STUDENT_IN]: "Student/in",
        [KIND_OF_ACTIVITY.UNEMPLOYED]: "Unemployed",
        [KIND_OF_ACTIVITY.SELF_EMPLOYED]: "Self employed",
        [KIND_OF_ACTIVITY.EMPLOYEE]: "Employee",
        [KIND_OF_ACTIVITY.OTHER]: "Other",
      },
      passportTypes: {
        [PASSPORTS.ID_PASS]: "Pass ID",
        [PASSPORTS.PASSPORT]: "Passport",
      },
    },
  },
  mainNav: {
    home: "Home",
    wallet: "Wallet",
    tokens: "My Tokens",
    transactions: "Transactions",
    contracts: "Contracts",
    settings: "Settings",
    invest: "Investment",
  },
  home: {
    welcome: "Hello, {{name}}",
    hereYouCanManageYourInvestments: "Here you can manage your investments",
    search: "Search something...",
    toUnlockTheAppFeaturesMakeYourFirstInvestment:
      "To unlock the app features, make your first investment ",
  },
  errors: {
    somethingWentWrong: "Something went wrong",
    onlyNumbersCharacters: "The field requires numeric characters",
    wrongCredentials: "Wrong password or email",
    emailInUse: "Email is already in use!",
  },
  tokens: {
    tokens: "Tokens",
    seeAll: "See all",
    myTokens: "My Tokens",
    analytics: "Analytics",
    noTokens: "You have no tokens",
    noTokensText: `Check out our offers on our website or directly here
       in the app and add tokens of exclusive properties to your portfolio.`,
    token: {
      location: "Location:",
      availableTokens: "Available tokens:",
      soldOut: "Sold out",
      price: "Token price:",
      properties: "Properties:",
      propertyHighlights: "Property Highlights",
      percentageFounded: "founded",
      amountAvailable: "available",
      interestRate: "Interest rate:",
      duration: "Duration:",
      balance: "Balance",
      expectedYield: "Expected Yield:",
      rentPerToken: "Rent per token:",
      yourInvestment: "Your Investment:",
      calculateProfit: "Calculate profit",
      amount: "Amount",
      tokenPrice: "token price:",
      totalTokens: "total tokens:",
      userTokens: "Tokens:",
      invest: "Invest",
      yourTokens: "Your Tokens",
      myInvestment: "My investment",
      yours: "your",
      amountError:
        "The investment amount must be between" +
        " €{{minimumInvestment}} and €{{maximumInvestment}} and be divisible by 100.",
      funded: "funded",
      sellingTokensIsNotUnavailableNow: "Selling tokens is unavailable now",
      productType: "Product type:",
      infoCard: {
        available: "Available",
        equivalent: "Equivalent",
        interestRate: "Interest Rate",
        received: "Received",
      },
    },
  },
  properties: {
    livingArea: "Living Area:",
    property: {
      property: "Property",
      type: "Property Type",
      constructionYear: "Construction Year:",
      rented: "Rented:",
      totalLivingArea: "Total living area:",
      about: "About",
      totalUnits: "Total Units:",
      bathroomOrBath: "Bedroom/ Bath:",
      tokenName: "Token",
      propertyName: "Property {{propertyName}}",
    },
  },
  transactions: {
    recentTransactions: "Recent Transactions",
    seeAll: "See all",
    searchTransaction: "Search transaction",
    month: "Month",
    yourTransactionHistoryIsEmpty: "Your transaction history is empty!",
    investAndDiveIntoTokensWorld: "Invest and dive into tokens world",
    tokens: "Tokens",
    editInvestment: "Edit investment",
    activeTransactions: "active transactions",
    cancelModalHeader: "Are you sure?",
    cancelModalText: "Do you really want to cancel your investment?",
    clickToBuyFirstToken:
      "Click on the button  at the bottom to buy your first tokens",
    paymentStatus: {
      [PAYMENT_STATUS.SUCCESS]: "Investment approved",
      [PAYMENT_STATUS.PENDING]: "Outstanding payment",
      [PAYMENT_STATUS.NOT_ENOUGH]: "Tokens sold out",
      [PAYMENT_STATUS.DENIED]: "Payment rejected",
    },
    transactionTable: {
      date: "Date",
      time: "Time",
      amount: "Amount",
      transactionType: "Transaction type",
      transactionStatus: "Status",
    },
    transactionTypes: {
      [TRANSACTION_TYPE.CANCELED_INVESTING]: "Canceled Investing",
      [TRANSACTION_TYPE.INVESTING]: "Investing",
      [TRANSACTION_TYPE.TOP_UP]: "Top Up",
      [TRANSACTION_TYPE.WITHDRAW]: "Withdraw",
    },
    transactionStatuses: {
      [TRANSACTION_STATUS.FAIL]: "Fail",
      [TRANSACTION_STATUS.PROCESSING]: "Processing",
      [TRANSACTION_STATUS.SUCCESS]: "Success",
    },
    transaction: {
      documents: "Documents",
      iban: "IBAN",
      accountHolder: "Account holder",
      bic: "BIC",
      referenceText: "Reference text",
      keyData: "Key Data",
      myInvestment: "My investment",
      paymentDetails: "Payment details",
      investmentAmount: "Investment amount",
      investedAmount: "Invested amount",
      payableAmount: "Payable amount",
      tokens: "Tokens",
      compaign: "Compaign",
      investedOn: "Invested on",
      transferAmountText: `
         We have accepted your investment. Please transfer the above amount to
        the issuers bank account within 14 days, stating the reference text, in
        order to complete the investment.
      `,
      weWillNotifyText: `
        As soon as we have received your payment, we will notify you by email.
        You can always see the current status of your investment in your
        dashboard.`,
    },
  },
  balanceCard: {
    availableBalance: "available balance",
    spend: "Spend",
    received: "Received",
  },
  pagination: {
    next: "{{divider}}",
    prev: "{{divider}}",
  },
  noConnection: {
    noInternetConnection: "No internet connection",
    pleaseCheckYourConnection: "Please check your connection",
    tryAgain: "Try again",
  },
  genderOptions: {
    [GENDER.MALE]: "Mr.",
    [GENDER.FEMALE]: "Mrs.",
    [GENDER.OTHER]: "Other",
  },
  settings: {
    logout: "Logout",
    accountSettings: "Account Settings",
    saveChanges: "Save changes",
    name: "Name",
    lastName: "Last Name",
    email: "Email",
    plz: "PLZ",
    tokenId: "Token ID",
    street: "Street",
    buildingNumber: "Building number",
    city: "City",
    country: "Country",
    currentPassword: "Current Password",
    confirmNewPassword: "Confirm New Password",
    newPassword: "New Password",
    twoFactorAuthentication: "Two-Factor Authentication",
    termsAndConditions: "Terms and Conditions",
    document: "DOCUMENT:",
    gender: "Gender",
    password: {
      minLength: "Min length 8 symbols",
      maxLength: "Max length 24 symbols",
      capitalLetter: "Value must include at least one capital symbol",
      lowerCase: "Value must include at least one letter in small case",
      number: "Value must include at least one number",
      passwordMustMatch: "Password must match.",
    },
  },
  wallet: {
    topUp: "Top Up",
    withdraw: "Withdraw",
    accountAndSendMoney: "Account and send money",
    iban: "IBAN",
    bic: "BIC",
    name: "Name",
    cardNumber: "Card number",
    recipient: "Recipient",
    amount: "Amount",
  },
  investment: {
    investmentData: "Investment data",
    investToToken: "Investment data for",
    investmentAmount: "Investment amount",
    costsAndAllowances: {},
    sharePrice: "Share price:",
    shares: "Shares:",
    total: "Total:",
    investment: "Investment:",
    knowledgeAndExperience: {
      experienceConstants: {
        [EXPERIENCE_VALUE.NO_EXP]: "None",
        [EXPERIENCE_VALUE.LESS_1K]: "less 1000 eur",
        [EXPERIENCE_VALUE.LESS_3K]: "less 3000 eur",
        [EXPERIENCE_VALUE._1K_3K]: "1000-3000 eur",
        [EXPERIENCE_VALUE._3K_5K]: "3000-5000 eur",
        [EXPERIENCE_VALUE._5K_10K]: "5000-10000 eur",
        [EXPERIENCE_VALUE._10K_25K]: "10000-25000 eur",
        [EXPERIENCE_VALUE._25K_100K]: "25000-100000 eur",
        [EXPERIENCE_VALUE.MORE_100K]: "more 100000 eur",
      },
      durationConstants: {
        [EXPERIENCE_DURATION.NO_EXP]: "None",
        [EXPERIENCE_DURATION.LESS_1_YEAR]: "less 1 year",
        [EXPERIENCE_DURATION.LESS_3_YEAR]: "less 3 years",
        [EXPERIENCE_DURATION._3_5_YEARS]: "3-5 years",
        [EXPERIENCE_DURATION.MORE_5_YEARS]: "more 5 years",
      },
      bondsConstants: {
        [BOUNDS.NO_EXP]: "None",
        [BOUNDS.LESS_5]: "Less 5",
        [BOUNDS._5_10]: "Less 10",
        [BOUNDS.MORE_10]: "More then 10",
      },
      investmentFunds: "investment funds",

      knowledgeAndExperience: "Knowledge and experience",
      iDontWantToGiveAnyInformation: "I don't want to give any information.",
      provideInformation1: `
        According to the Securities Trading Act (§ 63 Para. 5 WpHG), we are obliged to share your knowledge and
        Experiences regarding different product classes such as securities, investments and others
        Query capital market products.
      `,
      provideInformation2: `
        Using the answers, we can check for you whether the subscription to this investment is yours
        knowledge and experience from your previous investment behavior. Answering this
        However, asking is voluntary. If you do not provide any or incomplete information, 
        we can determine the appropriateness
        of the respective capital market product cannot be checked for you. Therefore, you should, in your own interest
        Provide information.
      `,
      experienceWithCapitalMarketProduct:
        "Experience with capital market products",
      experienceWithSecuritiesServices: "Experience with securities services",
      frequencyAndVolumeOfTransaction:
        "Frequency and volume of your transactions",
      product: "Product",
      knowledge: "Knowledge",
      experiences: "Experiences",
      bonds: "Bonds",
      moneyMarketFunds: "Money market funds",
      profitParticipationRights: "profit participation rights",
      subordinated: "Subordinated/part. loan",
      virtualCurrencies: "Virtual currencies",
      howMuchExperienceInvestingInSecuritiesAdvice:
        "How much experience do you have investing in securities through investment advice?",
      experienceViaInvestmentAdvice:
        "How much experience do you have investing in securities via an investment advice?",
      howMuchExperienceInvestingInSecuritiesAdviceFree: `How much experience do you have with the
        independent, advice-free investment in
        securities?`,
      howManyInvestmentPerYear: "How many investments do you make per year?",
      averageValueOfTransaction:
        "What is the average value of the transactions from the previous question?",
      knowledgeAndExperienceRiskFactor: `
        Appropriateness is assessed according to whether you as an investor have the necessary knowledge and experience
        to be able to adequately assess the risks associated with the type of financial instruments 
        or investment services.
        In the documents you sent back, 
        you provided incomplete or essentially no information about your 
        previous experience and knowledge in connection with securities transactions.
        As a result, we are unable to fulfill our duty to review the adequacy of the investment.
        We therefore urgently point out that an adequacy check is not possible 
        in the case of missing/incomplete information. 
        You must therefore decide for yourself whether the securities are appropriate for you. In particular,
        you should familiarize yourself with the risks and circumstances of the securities.
        A detailed description of the risks associated with the subscription can be found in the "Risk Factors"
        section of the key information document relevant to the respective offer. If you still want to 
        refrain from a drawing, you can exercise your 14-day right of withdrawal.
      `,

      appropriatenessCheckNotPossible: "Appropriateness check not possible",
      appropriatenessCheckNotPossibleDescription1: `
        Appropriateness is assessed according to whether you as an investor have the necessary knowledge and experience
        dispose of the risks related to the nature of the financial instruments or investment services
        to be able to assess appropriately.
      `,
      appropriatenessCheckNotPossibleDescription2: `
        In the documents you returned, you have incomplete or essentially no information about your
        previous experience and knowledge in connection with securities transactions. That's why we're not in
        to comply with our duty to review the adequacy of the facility.
      `,
      appropriatenessCheckNotPossibleDescription3: `
        We therefore urgently point out that an adequacy check in the event of missing/incomplete information
        not possible. You must therefore decide for yourself whether the Securities are appropriate for you.
         In particular, should
        Familiarize yourself with the risks and circumstances of the securities.
         A detailed description of the with the
        Subscription risks are set out in the “Risk Factors” section of the relevant offering
        prospectus.
      `,

      confirmationInappropriateness: "Confirmation inappropriateness",
      confirmationInappropriatenessDescription1: `
          The evaluation of the information you have provided on previous knowledge and experience
          Securities/investments/capital market products showed that the product offered here
          or the amount of your subscription does not correspond to the 
          investment profile created on the basis of your information.
      `,
      confirmationInappropriatenessDescription2: `
        According to the information you have given, you do not have enough knowledge and experience to work with the
        To be able to adequately assess the risks associated with the purchase of the product offered here, d. H.
        Your subscription does not correspond to your previous investment profile.
      `,
      confirmationInappropriatenessDescription3: `
        Please find out more in the sales prospectus given to you or alternatively
        the other documents on the design and risk structure of the product. Of course it is
        Your agent will be happy to talk to you again.
      `,
      confirmationInappropriatenessDescription4: `
        If you decide to draw the product anyway, we ask you to legally confirm this:
        I am informed that subscribing to the investment is not based on my investment profile
        Reason for the knowledge I have given and previous experience with securities, investments
        and other capital market products. I decide anyway
        for the above-mentioned subscription to the following capital investment with the above-mentioned amount.
      `,
    },
    wallet: {
      wallet: "Wallet",
      safekeepingOfTheDigitalSecurities:
        "Safekeeping of the Digital Securities",
      tanganyLtd: "Tangany Ltd",
      tanganyTokenInformation: `
        You need a wallet to securely store your GreenTech A token. A wallet is a digital one
        Locker, similar to a deposit account, which you can access at any time via our dashboard. That
        We provide you with a wallet free of charge via our partner, Tangany GmbH from Munich.
      `,
      termsAndConditions1: "I have read the terms and conditions of ",
      termsAndConditions2: "Tangany GmbH",
      termsAndConditions3: `and agree to them.
       I commission ReFuture
        to open a free wallet at Tangany GmbH for me.`,
    },
    summary: {
      summary: "Summary",
      summaryOfInvestmentOffer: "Summary of your investment offer",
      tokenizedNote: "Tokenized Note",

      confirmAndUnderstoodDocuments: `I hereby confirm that the following documents will be submitted in good 
      time before 
      submitting this subscription declaration either in
      have been sent, downloaded or printed out in electronic form and have read and
      have understood:`,
      riskAndBasicInformation: {
        firstPart: "I hereby confirm that I have received the",
        riskInformation: "Risk information",
        basicInformation: "Basic information sheet",
        and: "and",
      },
      withdrawalAndConsumerInformation: {
        firstPart: "I hereby confirm that I have received",
        bondConditions: "the bond conditions",
        and: "and",
        rightWithdraw:
          "the consumer information including information on the right of withdrawal.",
      },
      all:
        "I hereby take note of the consumer information and the General Terms and Conditions of Effecta GmbH\n" +
        "agree to this.",
      generalTermsAndConditions: {
        firstPart: "I hereby take note of",
        consumerInformation: "the consumer information",
        and: "and",
        termsAndConditions: "the General Terms and Conditions",
        effecta: "of Effecta GmbH agree to this.",
      },
      costsAndAllowances: {
        firstPart: "Hiermit bestätige und akzeptiere ich die",
        file: "Kosten und Zuwendungen.",
      },
    },
    paymentDetails: {
      paymentDetails: "Payment details",
      summaryOfYourInvestmentOffer: "Summary of your investment offer",
      thanksForTrust: "Thanks for your trust.",
      documents: "Documents",
      verification: "Verification",
      toThePostident: "To the postident",
      investToPay: "Invest to pay",
      confirm: "Confirm",
      reviewingInvestment: `
          We are now reviewing your investment.
          Within the next few days you will receive an email from us with a link to the payment details.
          You can easily view the status of your investment at any time under Investments in your dashboard.
        `,
      verifyBeforeInvest: `Before you invest with We want to verify your. You get to the
          yellow button to the Deutsche Post page, where you can verify your data via video chat`,
      needDocumentId: `You need the ID document that you specified in step 4 (ID data).`,
      progressDuration: `The process takes about 5 to 10 minutes - 
      you will receive an email as soon as you are successful with us
        are verified`,
      recommendPostidentToUse: `We recommend the postident app Deutsche post for legitimation to use.`,
    },
    overviewOfCostsAndGrants: {
      stepTitle: "Overview of costs and grants",
      issueCostForTextText: `
        This issue will incur costs for sales, marketing and legal structuring
        of the product. According to Section 63 (7) WpHG, we are legally obliged
        to reimburse you for these costs in the form of a display the table
        transparently.
      `,
      nominalCapitalAmount: "Nominal capital amount",
      monetaryBenefits: "Monetary Benefits",
      relationToTheIssueAmount: "({{value}}% in relation to the issue amount)",
      ofWhichForwardedToContractuallyBoundAgents:
        "Of which forwarded to contractually bound agents",
      natureOfFeesAndCharges: "Nature of Fees and Charges",
      oneTimeCosts: "One-time costs",
      percentOfSpecification: "%-Specification",
      runningCostsPerYear: "Running costs per year",
      mediationCosts: "Mediation costs",
      ofWhichGrants: "Of which grants",
      totalFinancialCost: "Total cost of the financial instrument",
      completely: "Completely",
      duration: "Duration",
      firstYearReturn: "Costs reduce returns in the first Year",
      followingYearReturns: "Costs reduce returns in the following years",
      transactionCost:
        "Cost of the Financial Instrument (transaction costs / custody costs)",
      confirmCostsAndAllowancesDocument: "I hereby confirm and accept the",
      costsAndAllowances: "costs and allowances.",
    },
    amount: "Amount",
    redeemVoucher: "Redeem voucher",
    chooseTheAmountToInvest: `Choose the amount you want to invest.
    The investment amount must be between €1,000 and €25,000 and be divisible by 100.`,
    selfDisclosure: "Self-disclosure",
    investmentTerms: `
      With this capital investment, the amount of the investment is limited and to certain
      subject to conditions. In this case, the Securities Prospectus Act stipulates that your
      obtain self-disclosure.
      
      An investment of more than 1,000 euros is only possible if, at the time of subscription, you have at least one
      of the following two statements can be confirmed:
    `,
    confirmTwiceAverageIncome: `I confirm that the volume of all investments in this token
      does not exceed twice my average monthly net income.`,
    confirmDisposableAssets: `I confirm that I have disposable assets in the form of bank balances and
                                  Have financial instruments of at least € 10.000,00.`,
  },
  personalDetails: {
    personalDetails: "Personal details",
    wouldYouLikeToChangePersonalDetails: `Have your personal details been changed or do you
     want to continue using the details you entered earlier?`,
    useMySavedData: "Use my saved personal data",
    iWantToChangeIt: "I want to change it",
  },
  kyc: {
    fieldIsRequired: "{{field}} is required",
    max5: "max 5 symbols",
    congrats: "Congrats!",
    start: "Start",
    confirmationIdentity: "Confirmation of identity",
    prepareDocuments:
      "To buy your first token provide us with more data. Prepare your documents and follow the KYC process",
    completedKYC:
      "You have completed KYC, we will send you an email when your face is identified",
    personalInformation: "Personal Information",
    name: "Name",
    lastName: "Last Name",
    firstName: "First Name",
    city: "City",
    countryOfBirth: "Country of birth",
    specifyProfessionOrOccupation: "Specify profession or occupation",
    street: "Street",
    apartmentNumber: "Apartment №",
    zipCode: "ZIP Code",
    nextBtn: "next",
    personalData: "Personal data",
    addressData: "Address data",
    highestDegree: "Highest degree",
    citizenship: "Citizenship",
    dateOfBirth: "Date of birth",
    placeOfBirth: "Place of birth",
    country: "Country",
    streetAndHouseNumber: "Street and house number",
    addressSupplement: "Address supplement",
    professionOrActivity: "Profession or activity",
    identificationData: "Identification data",
    location: "Location",
    PEPPolicy: `
      Are you a politically exposed person (PEP), an immediate family member
      of a politically exposed person, or known to be a close associate of a
      politically exposed person?
    `,
    typeId: "ID type",
    specify: "Specify",
    numberId: "ID number",
    issuingAuthority: "Issuing authority",
    IDIssuedOn: "ID issued on",
    IDValidUntil: "ID valid until",
    statusQuery: "Status query",
    beneficialOwners: "Beneficial Owners",
    customerInformationEffecta: "Customer information Effecta",
    forTheSafekeepingOfYoursGreenTechATokens:
      "For the safekeeping of your GreenTech A tokens",
    politicallyExposedPersonConfirm: `
      I certify that I am not a Politically Exposed Person (PEP), not an immediate
      Family member of a politically exposed person and not a politically exposed person
      known to be closest person.
    `,
    myAccountIsOwnInterest: `
      I assure you that I act on my own account and in my own economic interest.
      `,
    effecta: {
      firstPart: "I have read the",
      link: "Effecta customer information",
      secondPart: "and agree to it.",
    },
    statuses: {
      link: "To postident",
      success: {
        header: "Are you ready?",
        text: `Your profile and wallet are all set up! To continue with the investment process, 
        deposit funds. Check your email`,
      },
      successWithoutWallet: {
        header: "Bear with us",
        text: `Your identity is successfully verified! 
        We are setting up your investor profile and will notify you when it is ready`,
      },
      declined: {
        header: "Oh no!",
        text: "We found some issues in your KYC process, please follow the link below and try again",
      },
      coupon: {
        header: "Let’s continue",
        text: `To verify your face and dive into the tokens world, 
         you need to complete the KYC process at your postal office.`,
      },
      pending: {
        header: "Bear with us",
        text: `You will get an email when we finish checking your documents, usually, it took a couple of days.`,
      },
      incomplete: {
        header: "Something went wrong ...",
        text1: `KYC process hasn't been completed, `,
        text2: `please follow the link below and try again`,
      },
      expiredData: {
        header: "Your documents will be expired soon",
        text: ` Please update your personal data`,
      },
      entryScreen: {
        header: "To unlock the app features, make your first investment ",
      },
    },
  },
};
export default translation;
