import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import styles from "./Spinner.module.scss";
import useStore from "../../../hooks/useStore";

export const Spinner = observer(({ children }) => {
  const {
    app: { loading },
  } = useStore();

  const cls = loading ? `${styles.spinner} ${styles.loading}` : styles.spinner;

  useEffect(() => {
    // if (loading) {
    //   document.body.classList.add("lock");
    // } else {
    //   document.body.classList.remove("lock");
    // }
  }, [loading]);

  return (
    <>
      {/* <div className={cls}> */}
      {/*  <BlurTokenIcon /> */}
      {/* </div> */}
      {children}
    </>
  );
});
