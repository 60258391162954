/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./StepsButtons.module.scss";
import { Button } from "../../../components/UI/Button/Button";
import useStore from "../../../hooks/useStore";
import useSizes from "../../../hooks/useSizes";

export const StepsButtons = observer(
  ({ onNextClick, onPrevClick, disabledNext, showPrev = true }) => {
    const { t } = useTranslation();
    const { stepId } = useParams();
    const { isMobile } = useSizes();
    const {
      me: {
        redirectUrl,
        kycStatus,
        transactions: { list },
      },
    } = useStore();
    const btnName = useMemo(() => {
      let result = t("general.next");

      if (+stepId === 1) result = t("general.more");
      if (+stepId === 5) result = t("investment.paymentDetails.investToPay");

      if (+stepId === 6) {
        result =
          (redirectUrl || (redirectUrl === null && list.length === 0)) &&
          kycStatus !== "SUCCESS"
            ? t("investment.paymentDetails.toThePostident")
            : t("investment.paymentDetails.confirm");
      }

      return result;
    }, [stepId, redirectUrl]);

    return (
      <div
        className={styles.stepsButtons}
        style={{ justifyContent: "space-between" }}
      >
        {showPrev && (
          <Button
            isGray
            onClick={onPrevClick}
            maxWidth={isMobile ? "42%" : +stepId === 5 ? 300 : 250}
          >
            {t("general.return")}
          </Button>
        )}
        <Button
          maxWidth={isMobile ? "42%" : +stepId === 5 ? 300 : 250}
          onClick={onNextClick}
          disabled={disabledNext}
          style={{ fontSize: isMobile ? 13 : undefined }}
        >
          {btnName}
        </Button>
      </div>
    );
  }
);
