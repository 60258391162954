export const LOCAL_STORAGE_KEYS = {
  LOCALE: "i18nextLng",
  KYC_STATUS_SUCCESS: "KYC_STATUS_SUCCESS",
  REQUEST_ID: "REQUEST_ID",
  REQUEST_AMOUNT: "REQUEST_AMOUNT",
  TOKEN: "TOKEN",
  REGISTER_CODE: "REGISTER_CODE",
  RESTORE_CODE: "RESTORE_CODE",
  SELF_TOKEN: "SELF_TOKEN",
};

export const setLanguage = (lang) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALE, lang);
};

export const setKYCSuccessStatus = () => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.KYC_STATUS_SUCCESS, "true");
};

export const isExistsKYCStatusSuccess = () => {
  return !!localStorage.getItem(LOCAL_STORAGE_KEYS.KYC_STATUS_SUCCESS);
};

export const getRequestId = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.REQUEST_ID);
};

export const getRequestAmount = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.REQUEST_AMOUNT);
};

export const setActiveToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.SELF_TOKEN, JSON.stringify(token));
};

export const getActiveToken = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.SELF_TOKEN);
  return JSON.parse(token);
};

export const deleteActiveToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.SELF_TOKEN);
};
