import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StatusKYC } from "./StatusKYC";
import { ReactComponent as ExpiredDataKYC } from "../../icons/expered-data-kyc.svg";
import { Button } from "../UI/Button/Button";

export const ExpiredKYC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onBtnClick = () => {
    navigate("/settings?personal-info=true");
  };

  return (
    <StatusKYC
      text={t("kyc.statuses.expiredData.text")}
      header={t("kyc.statuses.expiredData.header")}
      icon={ExpiredDataKYC}
      button={<Button onClick={onBtnClick}>{t("general.update")}</Button>}
    />
  );
};
