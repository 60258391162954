import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyHistory } from "../../../icons/emptryHistory.svg";
import { NoData } from "../../NoData/NoData";
import { Page } from "../../../components/Page/Page";

export const EmptyTokens = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <div style={{ height: 40 }} />
      <NoData icon={<EmptyHistory />} title={t("tokens.noTokens")} text="" />
    </Page>
  );
};
