import { NavLink } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

export const Breadcrumb = ({ crumbs, className }) => {
  return (
    <nav
      aria-label="Breadcrumb"
      className={`${styles.breadcrumbs} ${className}`}
    >
      <ul>
        {crumbs?.map((dot) => {
          return (
            <li key={`${dot.to}_${dot.text}`}>
              <NavLink to={dot.to}>{dot.text}</NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
