import { useTranslation } from "react-i18next";
import styles from "./ActiveTransactions.module.scss";
import { Button } from "../../../components/UI/Button/Button";

export const CancelModal = ({ onClose, onSave }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.cancelModal}>
      <span
        className={styles.cancelModal__close}
        onClick={onClose}
        role="presentation"
      />
      <div className={styles.cancelModal__content}>
        <h3 className={styles.cancelModal__header}>
          {t("transactions.cancelModalHeader")}
        </h3>
        <p className={styles.cancelModal__text}>
          {t("transactions.cancelModalText")}
        </p>
        <Button onClick={onSave}>{t("general.delete")}</Button>
      </div>
    </div>
  );
};
