import cn from "classnames";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStore";
import { PAYMENT_STATUS } from "../../store/consts";
import { ReactComponent as CardIcon } from "../../icons/card-icon.svg";
import { ReactComponent as DenyIcon } from "../../icons/payment-deny-icon.svg";
import { ReactComponent as Success } from "../../icons/tokens-success-payment.svg";
import { ReactComponent as SoldOut } from "../../icons/tokens-sold-out.svg";
import { Page } from "../../components/Page/Page";
import styles from "./TransactionHistory.module.scss";
import { Breadcrumb } from "../../components/UI/Breadcrumb/Breadcrumb";

import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Documents } from "../../components/Documents/Documents";
import useSizes from "../../hooks/useSizes";

export const Transaction = observer(() => {
  const { t } = useTranslation();
  const {
    app: { markLoading },
    investment: { getTransaction, activeTransaction, getDocuments, documents },
  } = useStore();
  const { transactionId } = useParams();
  const { isMobile } = useSizes();
  const location = useLocation();
  const crumbs = [
    {
      to: "/tokens",
      text: t("mainNav.tokens"),
    },
    {
      to: location.pathname,
      text: t("mainNav.invest"),
    },
  ];

  const icons = {
    [PAYMENT_STATUS.DENIED]: <DenyIcon />,
    [PAYMENT_STATUS.NOT_ENOUGH]: <SoldOut />,
    [PAYMENT_STATUS.PENDING]: <CardIcon />,
    [PAYMENT_STATUS.SUCCESS]: <Success />,
  };

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await getTransaction({ transactionId });
      await getDocuments({ transactionId });
      markLoading(false);
    };
    getData();
  }, [transactionId]);

  return (
    <Page>
      <Breadcrumb crumbs={crumbs} />

      <h4
        className={cn(
          styles.transaction__header1,
          isMobile ? styles.margin24 : styles.margin40
        )}
      >
        {t("transactions.transaction.myInvestment")}
      </h4>
      <h5 className={cn(styles.transaction__header2, styles.margin24)}>
        {t("transactions.transaction.keyData")}
      </h5>
      <div className={cn(styles.transaction__keyData, styles.margin24)}>
        <div className={styles.block}>
          <div className={styles.header}>{activeTransaction.tokenTypeName}</div>
          <div className={styles.text}>
            {t("transactions.transaction.compaign")}
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.header}>{activeTransaction?.payedAmount}</div>
          <div className={styles.text}>
            {t("transactions.transaction.investedAmount")}
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.header}>
            {dayjs(activeTransaction.createdAt).format("DD.MM.YYYY")}
          </div>
          <div className={styles.text}>
            {t("transactions.transaction.investedOn")}
          </div>
        </div>
        <div className={styles.block}>
          <div
            className={cn({
              [`${styles.transaction__outstandingPayment}`]: true,
              [`${styles.deny}`]:
                activeTransaction?.paymentStatus === PAYMENT_STATUS.DENIED,
              [`${styles.notEnough}`]:
                activeTransaction?.paymentStatus === PAYMENT_STATUS.NOT_ENOUGH,
              [`${styles.pending}`]:
                activeTransaction?.paymentStatus === PAYMENT_STATUS.PENDING,
              [`${styles.success}`]:
                activeTransaction?.paymentStatus === PAYMENT_STATUS.SUCCESS,
            })}
          >
            <div className={styles.icon}>
              {icons?.[activeTransaction?.paymentStatus]}
            </div>
            {activeTransaction.paymentStatus &&
              t(
                `transactions.paymentStatus.${activeTransaction?.paymentStatus}`
              )}
          </div>
        </div>
      </div>
      <h5 className={cn(styles.transaction__header2, styles.margin40)}>
        {t("transactions.transaction.paymentDetails")}
      </h5>
      <div className={cn(styles.transaction__dataRow, styles.margin24)}>
        <div className={styles.title}>
          {t("transactions.transaction.investmentAmount")}
        </div>
        <div className={styles.value}>
          {activeTransaction?.investmentAmount}
        </div>
      </div>
      <div className={styles.transaction__dataRow}>
        <div className={styles.title}>
          {t("transactions.transaction.tokens")}
        </div>
        <div className={styles.value}>{activeTransaction?.amount}</div>
      </div>

      <div className={cn(styles.transaction__secondaryText, styles.margin24)}>
        {t("transactions.transaction.transferAmountText")}
      </div>

      <div className={cn(styles.transaction__row, styles.margin24)}>
        <TextInput
          showCopy
          label={t("transactions.transaction.accountHolder")}
          disabled
          defaultValue={activeTransaction?.accountHolder}
        />
        <TextInput
          showCopy
          label={t("transactions.transaction.iban")}
          disabled
          defaultValue={activeTransaction?.iban}
        />
      </div>
      <div className={styles.transaction__row}>
        <TextInput
          showCopy
          label={t("transactions.transaction.bic")}
          disabled
          defaultValue={activeTransaction?.bic}
        />
        <TextInput
          showCopy
          label={t("transactions.transaction.referenceText")}
          defaultValue={activeTransaction?.referenceText}
          disabled
        />
      </div>

      <div className={cn(styles.transaction__infoBlock, styles.margin24)}>
        {t("transactions.transaction.weWillNotifyText")}
      </div>

      <Documents
        className={styles.margin24}
        title={t("transactions.transaction.documents")}
        list={documents}
      />
    </Page>
  );
});
