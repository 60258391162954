import { Navigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";

export const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

  if (!user) {
    return <Navigate to="/sign-in" />;
  }

  return children;
};
