import { useTranslation } from "react-i18next";
import styles from "./StatusesKYC.module.scss";
import { Logo } from "../Logo/Logo";

export const EntryHomeScreen = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.entryHome}>
      <div className={styles.entryHome__logo}>
        <Logo />
      </div>

      <h4 className={styles.entryHome__header}>
        {t("home.toUnlockTheAppFeaturesMakeYourFirstInvestment")}
      </h4>
    </div>
  );
};
