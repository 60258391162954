import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import { Helpers } from "./Helpers";

import useStore from "../../hooks/useStore";
import { message } from "../../components/Message/Message";
import { Checkbox } from "../../components/UI/Checkbox/Checkbox";

let messageId = "";

export const SignUp = observer(() => {
  const { t } = useTranslation();
  const {
    me: { register, updateField, confirmNewPassword, newPassword, newEmail },
  } = useStore();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);

  const schema = useMemo(() => {
    return Yup.object().shape({
      password: Yup.string()
        .min(8, "Min length 8 symbols")
        .max(24, "Max length 24 symbols")
        .matches(
          /(.*[A-Z].*)/,
          "Value must include at least one capital symbol"
        )
        .matches(/(.*[0-9].*)/, "Value must include at least one number")
        .matches(
          /(.*[a-z].*)/,
          "Value must include at least one letter in small case"
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password")],
        t("login.errors.passwordMustMatch")
      ),
      email: Yup.string().email(t("login.errors.enterCorrectEmail")),
    });
  }, []);

  const onSubmit = async () => {
    setDisabled(true);
    const { password, email } = values;
    try {
      if (messageId) {
        toast.dismiss(messageId);
      }
      const success = await register({ email, password });
      if (success) {
        navigate("/confirm-register");
      }
    } catch {
      messageId = message({
        type: "error",
        title: t("general.error"),
        text: t("errors.emailInUse"),
      });
    } finally {
      setDisabled(false);
    }
  };

  const { values, setFieldValue, errors, handleBlur, handleSubmit, touched } =
    useFormik({
      onSubmit,
      validationSchema: schema,
      validateOnChange: true,
      initialValues: {
        confirmPassword: "",
        password: "",
        email: "",
      },
    });

  const onChangeField = (field) => {
    return function updateStorageField({ target: { value } }) {
      updateField(field, value);
    };
  };

  const onPrivacyChange = () => {
    setDisabled((prev) => !prev);
  };

  useEffect(() => {
    setFieldValue("password", newPassword);
  }, [newPassword]);

  useEffect(() => {
    setFieldValue("email", newEmail);
  }, [newEmail]);

  useEffect(() => {
    setFieldValue("confirmPassword", confirmNewPassword);
  }, [confirmNewPassword]);

  useEffect(() => {
    return () => {
      updateField("confirmNewPassword", "");
      updateField("newPassword", "");
      updateField("newEmail", "");
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.signInContainer}>
        <div className={styles.signIn}>
          <div className={styles.header}>{t("login.letsStart")}</div>
          <div className={styles.subHeader} />
          <form onSubmit={handleSubmit} autoComplete="new-password">
            <div className={styles.input}>
              <TextInput
                label={t("login.email")}
                environment="modal"
                defaultValue={values.email}
                error={touched.email && errors.email}
                name="email"
                onChange={onChangeField("newEmail")}
                onBlur={handleBlur}
              />
            </div>
            <div className={styles.input}>
              <TextInput
                label={t("login.password")}
                type="password"
                showEye
                environment="modal"
                defaultValue={values.password}
                name="password"
                onChange={onChangeField("newPassword")}
                onBlur={handleBlur}
              />
            </div>
            <Helpers value={newPassword} />
            <div className={styles.input}>
              <TextInput
                label={t("login.confirmPass")}
                type="password"
                showEye
                environment="modal"
                name="confirmPassword"
                onChange={onChangeField("confirmNewPassword")}
                defaultValue={values.confirmPassword}
                error={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
              />
            </div>
            <Checkbox
              className={styles.privacy}
              onChange={onPrivacyChange}
              mode="dark"
              label={
                <>
                  <span>{t("login.privacy.iAgreeToThe")}</span>{" "}
                  <span className={styles.privacy__link}>
                    <a
                      href="https://refuture-bucket.s3.eu-central-1.amazonaws.com/common/docs/nutzungsbedingungen.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t("login.privacy.termsOfService")}
                    </a>
                  </span>{" "}
                  <span>{t("login.privacy.and")}</span>{" "}
                  <span className={styles.privacy__link}>
                    <a
                      href="https://refuture-bucket.s3.eu-central-1.amazonaws.com/common/docs/datenschutzrichtlinien.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t("login.privacy.privacyPolicy")}
                    </a>
                    zu
                  </span>
                </>
              }
            />
            <Button
              type="submit"
              className="marginTop40"
              disabled={
                disabled || !newEmail || !newPassword || !confirmNewPassword
              }
            >
              {t("general.next")}
            </Button>
          </form>
          <div className={styles.center}>
            <span className={styles.footerText}>
              {t("login.areYouRegistered")}
            </span>{" "}
            <span className={styles.link}>
              <NavLink to="/sign-in">{t("login.loginHeader")}</NavLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
