import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyHistory } from "../../icons/emptryHistory.svg";
import { NoData } from "../../pages/NoData/NoData";

export const EmptyTransactionHistory = () => {
  const { t } = useTranslation();

  return (
    <NoData
      icon={<EmptyHistory />}
      text={t("")}
      title={t("transactions.yourTransactionHistoryIsEmpty")}
    />
  );
};
