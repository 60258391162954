import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en";
import translationDE from "./locales/de";

const locale = "de";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
    lng: locale,
    fallbackLng: locale,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
