import { useEffect, useState } from "react";
import cn from "classnames";
import { ReactComponent as CheckedIcon } from "../../../icons/checked.svg";
import { ReactComponent as DarkModeCheckedIcon } from "../../../icons/checkedDarkMode.svg";
import styles from "./Checkbox.module.scss";

export const Checkbox = ({
  label,
  initialState,
  onChange = () => {},
  mode,
  className,
  disabled,
}) => {
  const [checked, setChecked] = useState(initialState);

  const onChangeHandler = ({ target: { checked } }) => {
    setChecked(checked);
    onChange(checked);
  };

  const getClassNameCustomCheckbox = () => {
    return cn({
      [`${styles.customCheckbox}`]: true,
      [`${styles.darkMode}`]: mode === "dark",
    });
  };

  const getClassNameCheckbox = () => {
    return cn({
      [`${styles.checkbox}`]: true,
      [`${styles.labeled}`]: !!label,
      [`${styles.disabled}`]: !!disabled,
      [`${styles.checked}`]: !!checked,
      [`${styles.darkMode}`]: mode === "dark",
    });
  };

  useEffect(() => {
    setChecked(initialState);
  }, [initialState]);

  return (
    <>
      <label
        className={cn({
          [`${styles.label}`]: true,
          [`${className}`]: !!className,
        })}
      >
        <div className={getClassNameCustomCheckbox()}>
          <div className={getClassNameCheckbox()}>
            {mode === "dark" ? <DarkModeCheckedIcon /> : <CheckedIcon />}
          </div>
          {label && <div className={styles.label__text}>{label}</div>}
          <input
            type="checkbox"
            disabled={disabled}
            value={checked}
            className={styles.hiddenCheckbox}
            onChange={onChangeHandler}
          />
        </div>
      </label>
    </>
  );
};
