import { useTranslation } from "react-i18next";
import { StatusKYC } from "./StatusKYC";
import { ReactComponent as SuccessKYCIcon } from "../../icons/SuccessKYC.svg";
import { setKYCSuccessStatus } from "../../utils/localStorage";

export const SuccessKYC = ({ showStatus, setShowStatus }) => {
  const { t } = useTranslation();

  const onClickCloseIcon = () => {
    setKYCSuccessStatus();
    setShowStatus(false);
  };

  if (!showStatus) {
    return null;
  }

  return (
    <StatusKYC
      text={t("kyc.statuses.success.text")}
      header={t("kyc.statuses.success.header")}
      icon={SuccessKYCIcon}
      onCloseClick={onClickCloseIcon}
      showClose
    />
  );
};
