import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styles from "./InvestmentData.module.scss";
import { Steps } from "../../components/UI/Steps/Steps";
import useStore from "../../hooks/useStore";
import { Investigation } from "./pages/Investigation";
import { KnowledgeAndExperience } from "./pages/KnowledgeAndExperience";
import { RightBar } from "../../components/RightBar/RightBar";
import { Wallet } from "./pages/Wallet";
import { Summary } from "./pages/Summary";
import { RightBarTokens } from "../Tokens/RightBarTokens/RightBarTokens";
import { PaymentDetails } from "./pages/PaymentDetails";
import { CostsAndGrants } from "./pages/CostsAndGrants";
import { getRequestId } from "../../utils/localStorage";
import { Page } from "../../components/Page/Page";
import useSizes from "../../hooks/useSizes";

export const InvestmentData = observer(() => {
  const { t } = useTranslation();
  const {
    me: { isAuth },
    app: { markLoading },
    tokens: { selfTokens, getTokens, getSelfTokens },
    investment: {
      getTransaction,
      userExperience,
      steps: { updateField },
    },
  } = useStore();
  const { tokenId } = useParams();
  const [disabledNext, setDisabledNext] = useState(false);
  const transactionId = getRequestId();
  const { isLaptop } = useSizes();

  const getNextLink = (stepIndex) => {
    return `/tokens/${tokenId}/investment/steps/${stepIndex}`;
  };

  const steps = [
    {
      index: 1,
      title: t("investment.investmentData"),
      Component: <Investigation setDisabledNext={setDisabledNext} />,
    },
    {
      index: 2,
      title: t("investment.knowledgeAndExperience.knowledgeAndExperience"),
      Component: <KnowledgeAndExperience setDisabledNext={setDisabledNext} />,
    },
    {
      index: 3,
      title: t("investment.wallet.wallet"),
      Component: <Wallet setDisabledNext={setDisabledNext} />,
    },
    {
      index: 4,
      title: t("investment.overviewOfCostsAndGrants.stepTitle"),
      Component: <CostsAndGrants setDisabledNext={setDisabledNext} />,
    },
    {
      index: 5,
      title: t("investment.summary.summary"),
      Component: <Summary setDisabledNext={setDisabledNext} />,
    },
    {
      index: 6,
      title: t("investment.paymentDetails.paymentDetails"),
      Component: <PaymentDetails setDisabledNext={setDisabledNext} />,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await getSelfTokens();
      await getTransaction({ transactionId });
      markLoading(false);
    };
    getData();
  }, []);

  const isShownTokens = isLaptop && selfTokens?.length > 0;

  return (
    <>
      <Page>
        <div className={styles.wrapper}>
          {isShownTokens && (
            <div className={styles.mobileTokens}>
              <RightBarTokens tokens={selfTokens} withNavigate={false} />
            </div>
          )}

          <Steps
            steps={steps}
            getNextLink={getNextLink}
            disabledNext={disabledNext}
          />
        </div>
      </Page>

      {!isLaptop && (
        <RightBar>
          <RightBarTokens tokens={selfTokens} withNavigate={false} />
        </RightBar>
      )}
    </>
  );
});
