import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styles from "./Tokens.module.scss";
import { TokenImageByURI } from "../TokenImageByURI/TokenImageByURI";

export const SelfToken = observer(({ token, navigateTo, tokenRef }) => {
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const location = useLocation();
  return (
    <NavLink
      to={
        typeof navigateTo === "function" ? navigateTo(token) : location.pathname
      }
      className={`${styles.selfToken} ${
        token.id?.toString() === tokenId?.toString() && styles.selected
      }`}
      ref={token.id?.toString() === tokenId ? tokenRef : undefined}
    >
      <div className={styles.selfToken__block}>
        <div className={styles.selfToken__icon}>
          <TokenImageByURI tokenId={token?.id} />
        </div>
      </div>
      <div className={styles.selfToken__block}>
        {/* <div className={styles.selfToken__info}> */}
        {/*   <div className={styles.selfToken__label}> */}
        {/*     {t("tokens.token.availableTokens")} */}
        {/*     <span className={styles.selfToken__text}> */}
        {/*       {token.availableTokens} */}
        {/*     </span> */}
        {/*   </div> */}
        {/* </div> */}
        <div className={styles.selfToken__info}>
          <span className={styles.selfToken__label}>
            {t("tokens.token.price")}
            <span className={styles.selfToken__text}>
              {token.tokenPrice} {token.currency}
            </span>
          </span>
        </div>
      </div>
    </NavLink>
  );
});
