import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import useStore from "../../../hooks/useStore";
import styles from "../InvestmentData.module.scss";
import { Checkbox } from "../../../components/UI/Checkbox/Checkbox";
import { StepsButtons } from "./StepsButtons";
import { getRequestId } from "../../../utils/localStorage";
import { HiddenScroll } from "../../../components/HiddenScroll/HiddenScroll";
import formatMoney from "../../../utils/formatMoney";

const format = (string) => {
  return string ? formatMoney(+string.replace(/[^\d.]/g, "")) : "";
};

const formatPercent = (string) => {
  let percent = parseFloat(string);
  percent = `${percent.toFixed(2)}%`.replace(/\./g, ",");
  return percent;
};

export const CostsAndGrants = observer(({ setDisabledNext }) => {
  const { t } = useTranslation();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const firstColumnRef = useRef(null);

  const {
    app: { markLoading },
    investment: {
      costsAndAllowances,
      getCostsAndAllowances,
      getDocuments,
      documents,
      steps: { kostenChecked, updateField },
    },
  } = useStore();
  const investmentRequestId = getRequestId();

  const toPage5 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/5`);
  };

  const toPage3 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/3`);
  };

  const onChangeConfirm = (value) => {
    updateField("kostenChecked", value);
    // navigate(`/tokens/${tokenId}/investment/steps/3`);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        markLoading(true);
        await getCostsAndAllowances({ transactionId: investmentRequestId });
        await getDocuments({ transactionId: investmentRequestId });
      } catch (err) {
        //
      } finally {
        markLoading(false);
      }
    };
    getData();
  }, [investmentRequestId, getCostsAndAllowances]);

  useEffect(() => {
    setDisabledNext(!kostenChecked);
  }, [kostenChecked]);

  return (
    <div className={styles.costsAndGrants}>
      <h4 className={styles.header}>Kosten und Zuwendungen</h4>
      <p className={cn(styles.text, styles.margin16)}>
        {t("investment.overviewOfCostsAndGrants.issueCostForTextText")}
      </p>
      <HiddenScroll>
        <div className={styles.overflow__content}>
          <p className={cn(styles.costsAndGrants__label, styles.margin24)}>
            <span className={styles.title}>Laufzeit in Jahren</span>
            <span className={styles.value}>{costsAndAllowances?.duration}</span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              Agio/ Ausgabeaufschlag in Prozent
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.entryFee)}
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              prozentuale Kosten der Vermittlung auf das Nominalkapital
              (Einmalkosten)
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.mediationCostsPercent)}
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              prozentuale Zuwendung auf den Nominalbetrag (Einmalkosten)
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.grantPercent)}
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              prozentuale Kosten der Vermittlung auf den NAV (laufende Kosten)
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.costsOfBrokerage)}
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              prozentuale Zuwendungen auf den NAV (laufende Kosten)
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.grantsOnNAV)}
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              prozentuale Transaktions- und Depotkosten auf das Nominalkapital
              (Einmalkosten)
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.custodyOnTheNominal)}
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              prozentuale Transaktions- und Depotkosten auf den NAV (laufende
              Kosten)
            </span>
            <span className={styles.value}>
              {formatPercent(costsAndAllowances?.custodyOnTheNAV)}
            </span>
          </p>
          <div className={styles.divider} />
          <p className={cn(styles.costsAndGrants__label, styles.margin24)}>
            <span className={styles.title}>
              {t("investment.overviewOfCostsAndGrants.nominalCapitalAmount")}
            </span>
            <span className={styles.value}>
              {format(costsAndAllowances?.amount)} €
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>Ausgabebetrag</span>
            <span className={styles.value}>
              {format(costsAndAllowances?.amount)} €
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>
              monetäre Zuwendungen in Bezug auf Ausgabebetrag
            </span>
            <span className={styles.value}>
              {format(costsAndAllowances?.monetaryBenefitsAmount)} €
            </span>
          </p>
          <p className={styles.costsAndGrants__label}>
            <span className={styles.title}>davon an vgV</span>
            <span className={styles.value}>
              {format(costsAndAllowances?.davonAmount)} €
            </span>
          </p>
        </div>
      </HiddenScroll>
      <HiddenScroll>
        <div className={cn(styles.costsAndGrants__table, styles.margin24)}>
          <table>
            <thead>
              <tr>
                <th ref={firstColumnRef}>
                  {t(
                    "investment.overviewOfCostsAndGrants.natureOfFeesAndCharges"
                  )}
                </th>
                <th>{t("investment.overviewOfCostsAndGrants.oneTimeCosts")}</th>
                <th>
                  {t(
                    "investment.overviewOfCostsAndGrants.percentOfSpecification"
                  )}
                </th>
                <th>
                  {t("investment.overviewOfCostsAndGrants.runningCostsPerYear")}
                </th>
                <th>
                  {t(
                    "investment.overviewOfCostsAndGrants.percentOfSpecification"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.subHeader}>
                  {t("investment.overviewOfCostsAndGrants.mediationCosts")}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.mediationCosts?.oneTimeCost)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.mediationCosts?.oneTimeCostPercent
                  )}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.mediationCosts?.costsPerYear)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.mediationCosts?.costsPerYearPercent
                  )}
                </td>
              </tr>
              <tr>
                <td className={styles.subHeader}>
                  {" "}
                  {t("investment.overviewOfCostsAndGrants.ofWhichGrants")}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.grants?.oneTimeCost)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.grants?.oneTimeCostPercent
                  )}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.grants?.costsPerYear)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.grants?.costsPerYearPercent
                  )}
                </td>
              </tr>
              <tr>
                <td className={styles.subHeader}>
                  {t("investment.overviewOfCostsAndGrants.totalFinancialCost")}
                </td>
                <td className={styles.value}>
                  {format(
                    costsAndAllowances?.financialInstrumentCosts?.oneTimeCost
                  )}{" "}
                  €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.financialInstrumentCosts
                      ?.oneTimeCostPercent
                  )}
                </td>
                <td className={styles.value}>
                  {format(
                    costsAndAllowances?.financialInstrumentCosts?.costsPerYear
                  )}{" "}
                  €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.financialInstrumentCosts
                      ?.costsPerYearPercent
                  )}
                </td>
              </tr>
              <tr>
                <td className={styles.subHeader}>
                  {t("investment.overviewOfCostsAndGrants.transactionCost")}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.totalFICosts?.oneTimeCost)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.totalFICosts?.oneTimeCostPercent
                  )}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.totalFICosts?.costsPerYear)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.totalFICosts?.costsPerYearPercent
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </HiddenScroll>
      <HiddenScroll>
        <div className={cn(styles.costsAndGrants__table, styles.margin24)}>
          <table>
            <thead>
              <tr>
                <th>
                  {t(
                    "investment.overviewOfCostsAndGrants.natureOfFeesAndCharges"
                  )}
                </th>
                <th>{t("investment.overviewOfCostsAndGrants.completely")}</th>
                <th>
                  {t(
                    "investment.overviewOfCostsAndGrants.percentOfSpecification"
                  )}
                </th>
                <th>
                  {t("investment.overviewOfCostsAndGrants.runningCostsPerYear")}
                </th>
                <th>
                  {t(
                    "investment.overviewOfCostsAndGrants.percentOfSpecification"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.subHeader}>
                  {t("investment.overviewOfCostsAndGrants.duration")}
                </td>
                <td className={styles.value}>
                  {costsAndAllowances?.duration} {t("dates.years")}
                </td>
                <td className={styles.value} />
                <td className={styles.value} />
                <td className={styles.value} />
              </tr>
              <tr>
                <td className={styles.subHeader}>
                  {t("investment.overviewOfCostsAndGrants.totalCost")}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.totalCosts?.oneTimeCost)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.totalCosts?.oneTimeCostPercent
                  )}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.totalCosts?.costsPerYear)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.totalCosts?.costsPerYearPercent
                  )}
                </td>
              </tr>
              <tr>
                <td className={styles.subHeader}>
                  {" "}
                  {t("investment.overviewOfCostsAndGrants.firstYearReturn")}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.firstYearReturns?.total)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.firstYearReturns?.totalPercent
                  )}
                </td>
                <td className={styles.value} />
                <td className={styles.value} />
              </tr>
              <tr>
                <td className={styles.subHeader}>
                  {t(
                    "investment.overviewOfCostsAndGrants.followingYearReturns"
                  )}
                </td>
                <td className={styles.value}>
                  {format(costsAndAllowances?.followingYearsReturns?.total)} €
                </td>
                <td className={styles.value}>
                  {formatPercent(
                    costsAndAllowances?.followingYearsReturns?.totalPercent
                  )}
                </td>
                <td className={styles.value} />
                <td className={styles.value} />
              </tr>
            </tbody>
          </table>
        </div>
      </HiddenScroll>

      <Checkbox
        label={
          <>
            {t(
              "investment.overviewOfCostsAndGrants.confirmCostsAndAllowancesDocument"
            )}{" "}
            <a
              href={
                documents?.find((i) => i.name === "Kosten und Zuwendungen")?.url
              }
              className={styles.underline}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("investment.overviewOfCostsAndGrants.costsAndAllowances")}
            </a>
          </>
        }
        mode="dark"
        className={styles.margin24}
        onChange={onChangeConfirm}
        initialState={kostenChecked}
      />
      <StepsButtons
        onNextClick={toPage5}
        onPrevClick={toPage3}
        disabledNext={!kostenChecked}
      />
    </div>
  );
});
