import { useTranslation } from "react-i18next";
import { StatusKYC } from "./StatusKYC";
import { ReactComponent as PendingKYCIcon } from "../../icons/PendingKYC.svg";

export const PendingKYC = () => {
  const { t } = useTranslation();
  return (
    <StatusKYC
      text={t("kyc.statuses.pending.text")}
      header={t("kyc.statuses.pending.header")}
      icon={PendingKYCIcon}
    />
  );
};
