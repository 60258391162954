/* eslint-disable no-param-reassign,no-cond-assign */
export default function formatMoney(
  number,
  decPlaces,
  thouSeparator,
  decSeparator
) {
  decPlaces = Number.isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
  decSeparator = decSeparator === undefined ? "," : decSeparator;
  thouSeparator = thouSeparator === undefined ? "." : thouSeparator;
  const sign = number < 0 ? "-" : "";
  const i = `${parseInt(
    (number = Math.abs(+number || 0).toFixed(decPlaces)),
    10
  )}`;
  let j;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    sign +
    (j ? i.substr(0, j) + thouSeparator : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thouSeparator}`) +
    (decPlaces
      ? decSeparator +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
}
