import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import useStore from "../../hooks/useStore";

export const VerificationCode = observer(() => {
  const { t } = useTranslation();
  const schema = useMemo(() => {
    return Yup.object().shape({
      code: Yup.string(),
    });
  }, []);
  const {
    me: { confirmResetCode, resetEmail, restorePassword, updateField },
  } = useStore();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const result = await restore();
    if (result) {
      navigate("/change-password");
    } else {
      setErrors({ ...errors, code: t("login.errors.theCodeIsntCorrect") });
    }
  };

  const restore = async () => {
    return confirmResetCode({ code: values.code, email: resetEmail });
  };

  const getCodeAgain = () => {
    restorePassword({ email: resetEmail });
  };

  const {
    values,
    handleSubmit,
    touched,
    errors,
    handleBlur,
    setErrors,
    handleChange,
  } = useFormik({
    validationSchema: schema,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: "",
    },
  });

  const onCodeChange = (e) => {
    updateField("code", e.target.value);
    handleChange(e);
  };

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.signInContainer}>
        <div className={styles.signIn}>
          <div className={styles.header}>{t("login.resetPassword")}</div>
          <div className={styles.subHeader}>
            {t("login.enterFiveDigitsCode")}
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.input}>
              <TextInput
                label={t("login.verificationCode")}
                error={touched.code && errors.code}
                environment="modal"
                name="code"
                onBlur={handleBlur}
                onChange={onCodeChange}
              />
            </div>

            <div className={styles.center}>
              {t("login.didNotGotTheCode")}
              {""}
              <span
                role="presentation"
                onClick={getCodeAgain}
                className={styles.link}
              >
                {t("login.resend")}
              </span>
            </div>

            <Button type="submit" className={styles.footerButton}>
              {t("general.next")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
});
