import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStore";
import styles from "./PropertiesBundle.module.scss";
import { PropertyBundle } from "./PropertyBundle";

export const MobilePropertiesBundle = observer(() => {
  const { t } = useTranslation();

  const {
    tokens: { properties },
  } = useStore();

  return (
    <div className={styles.mobilePropertiesBundle}>
      <h4 className={styles.mobilePropertiesBundle__header}>
        {t("properties.property.property")}
      </h4>
      <div className={styles.mobilePropertiesBundle__list}>
        {properties?.map((p) => (
          <PropertyBundle property={p} key={p.id} />
        ))}
      </div>
    </div>
  );
});
