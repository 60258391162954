import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "../utils/localStorage";

const { CancelToken } = axios;
const { origin } = window.location;
const isDevelop = process.env.NODE_ENV === "development";
export const BASE_URL = isDevelop ? "" : origin;

const instance = axios.create({
  // baseURL: BASE_URL,
});

const cancel = {};

export const request = async ({
  url,
  params = {},
  body = {},
  method = "get",
  contentType = "application/json",
  shouldCancel = true,
  withHeaders = false,
  ...rest
}) => {
  try {
    if (cancel[url] !== undefined) {
      cancel[url]();
    }

    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    const response = await instance.request({
      url,
      cancelToken: shouldCancel
        ? new CancelToken((canceler) => {
            cancel[url] = canceler;
          })
        : undefined,
      data: body instanceof FormData ? body : JSON.stringify(body),
      method,
      params,
      headers: {
        "Content-Type": contentType,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      ...rest,
    });

    if (withHeaders) {
      return {
        data: response.data,
        headers: response.headers,
      };
    }

    return response.data;
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    }
    throw err;
  }
};
