import styles from "./StatusesKYC.module.scss";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

export const StatusKYC = ({
  link,
  icon: SvgIcon,
  header,
  text,
  showClose,
  onCloseClick,
  button,
  linkText,
}) => {
  return (
    <div className={styles.kycStatus}>
      {showClose && (
        <div
          className={styles.kycStatus__close}
          onClick={onCloseClick}
          role="presentation"
        >
          <CloseIcon />
        </div>
      )}
      <div className={styles.kycStatus__icon}>
        <SvgIcon />
      </div>
      <div className={styles.kycStatus__header}>{header}</div>
      <div className={styles.kycStatus__text}>
        {typeof text === "string" && text}
        {Array.isArray(text) && text.map((t) => <p key={t}>{t}</p>)}
      </div>
      {link && (
        <div className={styles.kycStatus__link}>
          <a href={link} target="_blank" rel="noreferrer">
            {linkText}
          </a>
        </div>
      )}
      {button && <div className={styles.kycStatus__button}>{button}</div>}
    </div>
  );
};
