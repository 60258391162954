import styles from "./HiddenScroll.module.scss";

export const HiddenScroll = ({ children, scrollRef }) => {
  return (
    <div className={styles.hiddenScrollWrapper}>
      <div className={styles.hiddenScroll} ref={scrollRef}>
        {children}
      </div>
    </div>
  );
};
