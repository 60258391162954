import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { Button } from "../UI/Button/Button";
import styles from "./ConfirmationOfIdentity.module.scss";

export const ConfirmationOfIdentity = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const { tokenId } = useParams();

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.notification}>
      <span
        role="presentation"
        className={styles.notification__icon}
        onClick={closeModal}
      >
        <CloseIcon />
      </span>
      <h4 className={styles.notification__header}>
        {t("kyc.confirmationIdentity")}
      </h4>
      <p className={styles.notification__text}>{t("kyc.prepareDocuments")}</p>

      <NavLink
        className={styles.notification__btn}
        to={`/tokens/${tokenId}/investment/kyc`}
      >
        <Button>{t("kyc.start")}</Button>
      </NavLink>
    </div>
  );
};
