import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styles from "./RightBarTokens.module.scss";
import { SelfToken } from "../../../components/Tokens/SelfToken";
import { HiddenScroll } from "../../../components/HiddenScroll/HiddenScroll";

export const RightBarTokens = observer(({ tokens, navigateTo }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const tokenRef = useRef(null);

  useEffect(() => {
    wrapperRef?.current?.scrollTo({
      left: tokenRef?.current?.offsetLeft - 20,
    });
  }, [tokens.length]);

  if (!tokens.length) {
    return null;
  }

  return (
    <>
      <h4 className={styles.barHeader}>{t("tokens.token.yourTokens")}</h4>
      <HiddenScroll scrollRef={wrapperRef}>
        <div className={styles.properties}>
          {tokens?.map((token) => {
            return (
              <SelfToken
                key={token.id}
                token={token}
                navigateTo={navigateTo}
                tokenRef={tokenRef}
              />
            );
          })}
        </div>
      </HiddenScroll>
    </>
  );
});
