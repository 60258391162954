import { request } from "./request";

export const getTransactionDocuments = ({ transactionId }) => {
  const url = `/api/transaction/${transactionId}/docs`;

  return request({
    url,
  });
};

export const getCostsAndAllowances = ({ transactionId }) => {
  const url = `/api/transaction/${transactionId}/docs/costs/desktop`;

  return request({
    url,
  });
};
export const confirmDocuments = ({ transactionId }) => {
  const url = `/api/transaction/${transactionId}/docs/confirm`;

  return request({
    url,
    method: "put",
  });
};

export const getAllTokensIcons = () => {
  const url = "/api/token-types/icons/all";

  return request({
    url,
  });
};
