import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import useStore from "../../hooks/useStore";

export const Verify = observer(() => {
  const { t } = useTranslation();
  const schema = useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t("login.errors.theCodeIsntCorrect")),
    });
  }, []);
  const {
    me: { confirmTfa, password, email, login },
  } = useStore();
  const navigate = useNavigate();

  const submitCode = async () => {
    const response = await confirmTfa({ key: values.code });

    if (response) {
      navigate("/");
    } else {
      setErrors({ ...errors, code: t("login.errors.theCodeIsntCorrect") });
    }
  };

  const {
    values,
    setErrors,
    handleSubmit,
    handleChange,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    validationSchema: schema,
    onSubmit: submitCode,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: "",
    },
  });

  const resendCode = async () => {
    await login({ username: email, password });
  };

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.signIn}>
        <div className={styles.header}>{t("login.verifyItsYou")}</div>
        <div className={styles.subHeader}>{t("login.confirmTheEmail")}</div>
        <form onSubmit={handleSubmit}>
          <div className={styles.input}>
            <TextInput
              label={t("login.verificationCode")}
              environment="modal"
              defaultValue={values.code}
              name="code"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.code && errors.code}
            />
          </div>
          <div className={styles.center}>
            {t("login.didNotGotTheCode")}
            <span
              className={styles.link}
              onClick={resendCode}
              role="presentation"
            >
              {t("login.resend")}
            </span>
          </div>

          <Button type="submit" className={styles.footerButton}>
            {t("general.done")}
          </Button>
        </form>
      </div>
    </div>
  );
});
