import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import cn from "classnames";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import useStore from "../../hooks/useStore";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";
import { message } from "../../components/Message/Message";

let messageId = "";

export const SignIn = observer(() => {
  const ref = useRef();
  const { t } = useTranslation();
  const schema = useMemo(() => {
    return Yup.object().shape({
      password: Yup.string().required(t("login.errors.passwordIsRequired")),
      email: Yup.string()
        .email(t("login.errors.enterCorrectEmail"))
        .required(t("login.errors.emailIsRequired")),
    });
  }, []);
  const {
    app: { loading, markLoading },
    me: { login, updateField, password, email },
  } = useStore();
  const navigate = useNavigate();

  const loginHandler = async () => {
    ref.current.focus();
    try {
      markLoading(true);

      if (messageId) {
        toast.dismiss(messageId);
      }

      const response = await login({
        password: values.password,
        username: values.email,
      });

      if (response?.id_token) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, response.id_token);
        navigate("/");
      }

      if (response?.tfaKey) {
        navigate("/verify");
      }
    } catch (err) {
      if (err?.response?.status === 400 || err?.response?.status === 401) {
        messageId = message({
          type: "error",
          title: t("general.error"),
          text: t("login.errors.wrongPasswordOrEmail"),
        });
      }
    } finally {
      markLoading(false);
    }
  };

  const {
    values,
    setErrors,
    handleSubmit,
    handleChange,
    touched,
    errors,
    handleBlur,
    setValues,
  } = useFormik({
    validationSchema: schema,
    onSubmit: loginHandler,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      password: "",
      email: "",
    },
  });

  const onChangeField = (field) => {
    return ({ target: { value } }) => {
      updateField(field, value);
      setErrors({});
      setValues({ ...values, [field]: value });
    };
  };

  return (
    <div className={styles.wrapper} ref={ref}>
      <Logo className={styles.logo} />
      <div className={styles.signInContainer}>
        <div className={styles.signIn}>
          <div className={styles.header}>{t("login.loginHeader")}</div>
          <div className={styles.subHeader} />
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className={styles.input}>
              <TextInput
                label={t("login.email")}
                environment="modal"
                defaultValue={values.email}
                name="email"
                onChange={onChangeField("email")}
                onBlur={handleBlur}
                error={touched.email && errors.email}
              />
            </div>
            <div className={styles.input}>
              <TextInput
                label={t("login.password")}
                type="password"
                showEye
                environment="modal"
                defaultValue={values.password}
                name="password"
                onChange={onChangeField("password")}
                error={touched.password && errors.password}
              />
            </div>
            <div className={cn(styles.link, styles.toEnd)}>
              <NavLink to="/restore-password">
                {t("login.forgotPassword")}
              </NavLink>
            </div>

            <Button type="submit" disabled={loading}>
              {t("login.signIn")}
            </Button>
          </form>

          <div className={styles.center}>
            <span className={styles.footerText}>
              {t("login.dontHaveAnAccount")}
            </span>{" "}
            <span className={styles.link}>
              <NavLink to="/sign-up">{t("login.signUp")}</NavLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
