import { useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { debounce } from "lodash";
import arraySupport from "dayjs/plugin/arraySupport";
import dayjs from "dayjs";
import styles from "./Transaction.module.scss";
import { Transaction } from "./Transaction";
import { EmptyRecentTransactions } from "./EmptyRecentTransactions";
import useStore from "../../hooks/useStore";
import { useFirstUpdate } from "../../hooks/useFirstUpdate";

dayjs.extend(arraySupport);

function formatDate(date) {
  const d = new Date();

  const days = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];
  const now = dayjs([d.getFullYear(), d.getMonth(), d.getDate()]);
  return now.isSame(dayjs(date))
    ? `Heute, ${dayjs(date).format(`D MMM`)}`
    : `${days[dayjs(date).day() - 1]} ${dayjs(date).format("D MMM")}`;
}

export const RecentTransactions = observer(
  ({
    renderHeader = () => {},
    getTransactions = () => {},
    showCount,
    showEmptyState = true,
  }) => {
    const {
      me: {
        transactions: { groupedTransactionsByDate, loading, pagination },
      },
    } = useStore();
    const firstRender = useFirstUpdate();
    const wrapperRef = useRef(null);

    const debouncedQuery = useCallback(
      debounce((pagination) => {
        getTransactions({
          pageNumber: pagination.pageNumber,
          month: pagination.month,
          query: pagination.query,
        });
      }, 400),
      []
    );

    useEffect(() => {
      getTransactions({
        pageNumber: pagination.pageNumber,
        month: pagination.month,
        query: pagination.query,
      });
    }, [pagination.pageNumber, pagination.month]);

    useEffect(() => {
      if (!firstRender) {
        debouncedQuery({
          pageNumber: pagination.pageNumber,
          month: pagination.month,
          query: pagination.query,
        });
      }
    }, [debouncedQuery, pagination.query]);

    if (!loading && groupedTransactionsByDate?.length === 0 && showEmptyState) {
      return <EmptyRecentTransactions />;
    }

    return (
      <div ref={wrapperRef}>
        <div className={styles.header}>{renderHeader()}</div>
        <div className={styles.transactionsByDate}>
          {groupedTransactionsByDate
            ?.slice(0, showCount)
            ?.map(([date, transactions]) => {
              return (
                <div
                  key={date}
                  className={styles.transactionsByDate__transactions}
                >
                  <div className={styles.transactionsByDate__date}>
                    {formatDate(date)}
                  </div>
                  <div className={styles.transactions}>
                    {transactions?.map((transaction) => {
                      return (
                        <Transaction
                          key={transaction.id}
                          transaction={transaction}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
);
