import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import cn from "classnames";
import styles from "./Settings.module.scss";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import useStore from "../../hooks/useStore";
// import { Documents } from "../../components/Documents/Documents";
import { Dropdown } from "../../components/UI/Dropdown/Dropdown";
import { Datepicker } from "../../components/UI/Datepicker/Datepicker";
import { Radio } from "../../components/UI/Radio/Radio";
import { useTypeIdOptions } from "../../hooks/useTypeIdOptions";
import { useSettingsSchema } from "./useSettingsSchema";
import { formatDate } from "../../utils/dates";
import { useCountriesOptions } from "../../hooks/useCountriesOptions";
import { message } from "../../components/Message/Message";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";
import { Page } from "../../components/Page/Page";
import useSizes from "../../hooks/useSizes";
import { GenderDropdown } from "../../components/GenderDropdown/GenderDropdown";

export const Settings = observer(() => {
  const { t } = useTranslation();
  const {
    app: { markLoading },
    investment: { getCountries, countries },
    me: {
      settings: { getSettings, getTerms, updateSettings },
    },
  } = useStore();
  const [disabledSave, setDisabledSave] = useState(false);
  const [searchParams] = useSearchParams();
  const personalInfoUpdate = searchParams.get("personal-info");
  const schema = useSettingsSchema();
  const navigate = useNavigate();
  const { isMobile } = useSizes();

  const onSubmit = async ({ newPassword, confirmNewPassword, ...values }) => {
    markLoading(true);
    setDisabledSave(true);
    const success = await updateSettings({
      ...values,
      password: newPassword,
    });
    setTimeout(() => {
      setDisabledSave(false);
    }, 3000);
    if (success) {
      message({
        type: "success",
        text: t("messanges.dataUploaded"),
        title: t("messanges.success"),
      });
    } else {
      message({
        type: "error",
        title: t("general.error"),
      });
    }

    markLoading(false);
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
    setTouched,
    touched,
  } = useFormik({
    onSubmit,
    validationSchema: schema,
    initialValues: {
      name: "",
      lastName: "",
      street: "",
      email: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      walletNumber: "",
      tfaActive: false,
      idType: [],
      citizenship: "",
      zipCode: "",
      country: "",
      salutation: [],
    },
  });
  const idTypeOptions = useTypeIdOptions({ list: values?.idType });
  const countriesOptions = useCountriesOptions({ countries });

  const activeCountry = useMemo(() => {
    const country = countries?.find((c) => values.country === c.shortName);
    return {
      title: country?.shortName,
      value: country?.id,
    };
  }, [values.country, countries.length]);

  const updateTypeId = (item) => {
    const newValues = values?.idType?.map((id) => {
      if (id.value === item.value) {
        return {
          ...id,
          selected: true,
        };
      }
      return {
        ...id,
        selected: false,
      };
    });
    setTouched({ ...touched, idType: true });
    setFieldValue("idType", newValues);
  };

  const logout = async () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    navigate("/sign-in");
  };

  const updateDate = (field) => {
    return function updateFieldValue(date) {
      setFieldValue(field, formatDate(date));
    };
  };

  const changeCountry = ({ country }) => {
    setFieldValue("country", country?.shortName);
  };

  useEffect(() => {
    getTerms();
    const getUserData = async () => {
      try {
        markLoading(true);
        await getCountries();

        const {
          name,
          lastName,
          street,
          email,
          walletNumber,
          city,
          country,
          // buildingNumber,
          appendix,
          tfaActive,
          idNumber,
          idType,
          idIssuedOn,
          issuingAuthority,
          idValidUntil,
          zipCode,
          salutation,
        } = await getSettings();
        await setValues((prev) => ({
          ...prev,
          name: name || "",
          lastName: lastName || "",
          street: street || "",
          city: city || "",
          country: country || "",
          buildingNumber: appendix || "",
          email: email || "",
          walletNumber: walletNumber || "",
          tfaActive: tfaActive || false,
          idNumber: idNumber || "",
          idType: idType || [],
          zipCode: zipCode || "",
          idIssuedOn: idIssuedOn ? formatDate(idIssuedOn) : null,
          idValidUntil: idValidUntil ? formatDate(idValidUntil) : null,
          issuingAuthority: issuingAuthority || "",
          salutation: salutation || [],
        }));
      } catch (err) {
        //
      } finally {
        markLoading(false);
      }
    };

    getUserData();
  }, []);

  return (
    <Page>
      <div className={styles.settings}>
        <div className={styles.settings__header}>
          <h4>{t("settings.accountSettings")}</h4>
          <Button className={styles.danger} onClick={logout}>
            <span className={styles.danger__text}>{t("settings.logout")}</span>
          </Button>
        </div>
        <div className={styles.settings__content}>
          <div className={styles.settings__personalInfo}>
            <div className={styles.settings__block}>
              <div className={cn(styles.settings__item, styles.settings__row)}>
                <GenderDropdown
                  disabled
                  defaultValue={values.salutation?.find((i) => i.selected)}
                />

                <TextInput
                  label={t("settings.name")}
                  defaultValue={values.name}
                  onChange={handleChange}
                  name="name"
                  disabled
                />
              </div>
              <div
                className={cn(styles.settings__item, styles.lastName_mobile)}
              >
                <TextInput
                  label={t("settings.lastName")}
                  defaultValue={values.lastName}
                  onChange={handleChange}
                  name="lastName"
                  disabled
                />
              </div>
              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.email")}
                  defaultValue={values.email}
                  onChange={handleChange}
                  type="email"
                  name="email"
                  error={touched.email && errors.email}
                  onBlur={handleBlur}
                  autoСomplete="off"
                  disabled
                />
              </div>

              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.street")}
                  defaultValue={values.street}
                  onChange={handleChange}
                  name="street"
                  disabled
                />
              </div>
              <div className={styles.settings__item}>
                <Dropdown
                  label={t("settings.country")}
                  options={countriesOptions}
                  onChange={changeCountry}
                  showResetOption={false}
                  defaultValue={activeCountry}
                  error={touched.country && errors.country}
                  disabled
                />
              </div>
              <div className={styles.settings__item}>
                {values.name && values.lastName ? (
                  <Radio
                    label={t("settings.twoFactorAuthentication")}
                    value={values.tfaActive}
                    onChange={(value) => setFieldValue("tfaActive", value)}
                  />
                ) : (
                  <div style={{ height: 25 }} />
                )}
              </div>
            </div>
            <div className={styles.settings__block}>
              <div className={cn(styles.settings__item, styles.lastName)}>
                <TextInput
                  label={t("settings.lastName")}
                  defaultValue={values.lastName}
                  onChange={handleChange}
                  name="lastName"
                  disabled
                />
              </div>
              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.city")}
                  defaultValue={values.city}
                  onChange={handleChange}
                  name="city"
                  disabled
                />
              </div>

              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.buildingNumber")}
                  defaultValue={values.buildingNumber}
                  onChange={handleChange}
                  name="buildingNumber"
                  disabled
                />
              </div>

              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.plz")}
                  defaultValue={values.zipCode}
                  onChange={handleChange}
                  name="zipCode"
                  disabled
                />
              </div>
            </div>
          </div>

          {personalInfoUpdate && (
            <>
              <h4 className={cn(styles.settings__header, "marginTop24")}>
                {t("kyc.identificationData")}
              </h4>
              <div className={cn(styles.settings__auth, styles.auth)}>
                <div className={styles.auth__block}>
                  <div className={cn(styles.auth__subBlock, styles.subBlock)}>
                    <div className={styles.subBlock__item}>
                      <Dropdown
                        label={t("kyc.typeId")}
                        options={idTypeOptions}
                        onChange={updateTypeId}
                        showResetOption={false}
                        defaultValue={values.idType?.find((i) => !!i.selected)}
                      />
                    </div>
                    <div className={styles.subBlock__item}>
                      <TextInput
                        label={t("kyc.numberId")}
                        onChange={handleChange}
                        defaultValue={values?.idNumber}
                        name="idNumber"
                        onBlur={handleBlur}
                        error={touched.idNumber && errors.idNumber}
                      />
                    </div>
                  </div>
                  <div
                    className={cn({
                      [`${styles.auth__subBlock}`]: !isMobile,
                      [`${styles.subBlock}`]: !isMobile,
                    })}
                  >
                    <div className={styles.subBlock__item}>
                      <Datepicker
                        label={t("kyc.IDIssuedOn")}
                        initialDate={values.idIssuedOn}
                        onChange={updateDate("idIssuedOn")}
                        error={touched.idIssuedOn && errors.idIssuedOn}
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>

                    <div className={styles.subBlock__item}>
                      <Datepicker
                        label={t("kyc.IDValidUntil")}
                        initialDate={values.idValidUntil}
                        onChange={updateDate("idValidUntil")}
                        error={touched.idValidUntil && errors.idValidUntil}
                        minDate={values.idIssuedOn || null}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.auth__block}>
                  <TextInput
                    label={t("kyc.issuingAuthority")}
                    onChange={handleChange}
                    defaultValue={values.issuingAuthority}
                    error={touched.issuingAuthority && errors.issuingAuthority}
                    onBlur={handleBlur}
                    name="issuingAuthority"
                  />
                </div>
              </div>
            </>
          )}

          <div className={styles.settings__password}>
            <div className={styles.settings__block}>
              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.currentPassword")}
                  onChange={handleChange}
                  name="currentPassword"
                  type="password"
                  defaultValue={values.currentPassword}
                  autoСomplete="new-password"
                  disabled={disabledSave || !values.name || !values.lastName}
                />
              </div>
              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.newPassword")}
                  onChange={handleChange}
                  name="newPassword"
                  type="password"
                  defaultValue={values.newPassword}
                  onBlur={handleBlur}
                  autoСomplete="new-password"
                  error={errors.newPassword}
                  disabled={disabledSave || !values.name || !values.lastName}
                />
              </div>
            </div>
            <div className={styles.settings__block}>
              <div className={styles.settings__item}>
                <TextInput
                  label={t("settings.confirmNewPassword")}
                  onChange={handleChange}
                  name="confirmNewPassword"
                  error={errors.confirmNewPassword}
                  type="password"
                  autoComplete="new-password"
                  onBlur={handleBlur}
                  defaultValue={values.confirmNewPassword}
                  disabled={disabledSave || !values.name || !values.lastName}
                />
              </div>
              <div className={styles.settings__item}>
                <Button
                  className={styles.settings__saveBtn}
                  onClick={handleSubmit}
                  disabled={disabledSave || !values.name || !values.lastName}
                >
                  {t("settings.saveChanges")}
                </Button>
              </div>
            </div>
          </div>
          {/* <Documents */}
          {/*   title={t("settings.termsAndConditions")} */}
          {/*   list={termsDocs} */}
          {/* /> */}
        </div>
      </div>
    </Page>
  );
});
