import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { MainNav } from "../MainNav/MainNav";
import styles from "./Layout.module.scss";
import useSizes from "../../hooks/useSizes";
import { ScrollBar } from "../ScrollBar/ScrollBar";

export const LayoutContext = React.createContext({ contentRef: null });

export const Layout = React.forwardRef(() => {
  const { isLargeLaptop, isMobile } = useSizes();
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef?.current && isMobile) {
      contentRef.current.style.width = "100%";
    }
  }, [isMobile]);

  return (
    <div className={styles.layout}>
      <MainNav />
      <LayoutContext.Provider value={{ contentRef }}>
        <div className={styles.content} ref={contentRef}>
          <ScrollBar>
            <Outlet />
          </ScrollBar>
        </div>
      </LayoutContext.Provider>
    </div>
  );
});
