import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useTypeIdOptions = ({ list }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return (
      list?.map((passport) => ({
        title: t(`dropdown.kyc.passportTypes.${passport.value}`),
        value: passport.value,
      })) || []
    );
  }, [list.length]);
};
