import { useTranslation } from "react-i18next";
import { StatusKYC } from "./StatusKYC";
import { ReactComponent as DeclinedKYCIcon } from "../../icons/DeclinedKYC.svg";
import useStore from "../../hooks/useStore";

export const DeclinedKYC = () => {
  const { t } = useTranslation();
  const {
    me: { redirectUrl },
  } = useStore();

  return (
    <StatusKYC
      text={t("kyc.statuses.declined.text")}
      header={t("kyc.statuses.declined.header")}
      icon={DeclinedKYCIcon}
      link={redirectUrl}
    />
  );
};
