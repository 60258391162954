import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { Layout } from "../Layout/Layout";
import { Home } from "../../pages/Home/Home";
import { TokenPage } from "../../pages/Tokens/TokenPage";
import { PropertiesPage } from "../../pages/PropertiesPage/PropertiesPage";
import { MyTokenInvestPage } from "../../pages/Investment/MyTokenInvestPage";
import { TransactionHistory } from "../../pages/Transactions/TransactionHistory";
import { Settings } from "../../pages/Settings/Settings";
import { NoConnection } from "../../pages/NoConnection/NoConnection";
import { InvestmentData } from "../../pages/InvestmentData/InvestmentData";
import { KYCPage } from "../../pages/InvestmentData/pages/KYCPage/KYCPage";
import { Transaction } from "../../pages/Transactions/Transaction";
import { MyTokens } from "../../pages/Tokens/MyTokens";
import { SignIn } from "../../pages/Auth/SignIn";
import { SignUp } from "../../pages/Auth/SignUp";
import { PasswordRestore } from "../../pages/Auth/PasswordRestore";
import { Verify } from "../../pages/Auth/Verify";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute";
import { ConfirmRegister } from "../../pages/Auth/ConfirmRegister";
import { ChangePassword } from "../../pages/Auth/ChangePassword";
import useStore from "../../hooks/useStore";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";
import { VerificationCode } from "../../pages/Auth/VerificationCode";

export const ProjectRoutes = observer(() => {
  const {
    me: { isAuth },
    app: { getAllIcons, icons, loading },
  } = useStore();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) && icons.length === 0) {
      const getData = async () => {
        await getAllIcons();
      };
      getData();
    }
  }, [icons.length, location.pathname]);

  useEffect(() => {
    const getData = async () => {
      await isAuth();
    };
    getData();
  }, []);

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
            path="/"
          >
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              element={
                <ProtectedRoute>
                  <MyTokens />
                </ProtectedRoute>
              }
              path="/tokens"
            />
            <Route
              element={
                <ProtectedRoute>
                  <TokenPage />
                </ProtectedRoute>
              }
              path="/home/:tokenId/investment/new"
            />
            <Route
              element={
                <ProtectedRoute>
                  <MyTokenInvestPage />
                </ProtectedRoute>
              }
              path="/tokens/:tokenId/investment"
            />
            <Route
              element={
                <ProtectedRoute>
                  <InvestmentData />
                </ProtectedRoute>
              }
              path="/tokens/:tokenId/investment/steps/:stepId"
            />
            <Route
              element={
                <ProtectedRoute>
                  <KYCPage />
                </ProtectedRoute>
              }
              path="/tokens/:tokenId/investment/kyc"
            />
            <Route
              element={
                <ProtectedRoute>
                  <PropertiesPage />
                </ProtectedRoute>
              }
              path="/tokens/:tokenId/properties/:propertyId"
            />
            <Route
              element={
                <ProtectedRoute>
                  <TransactionHistory />
                </ProtectedRoute>
              }
              path="/transactions"
            />
            <Route
              element={
                <ProtectedRoute>
                  <Transaction />
                </ProtectedRoute>
              }
              path="/tokens/:transactionId/view"
            />
            <Route
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
              path="/settings"
            />
            <Route
              element={
                <ProtectedRoute>
                  <NoConnection />
                </ProtectedRoute>
              }
              path="/no-connection"
            />
          </Route>
          <Route element={<SignIn />} path="/sign-in" />
          <Route element={<SignUp />} path="/sign-up" />
          <Route element={<PasswordRestore />} path="/restore-password" />
          <Route element={<Verify />} path="/verify" />
          <Route element={<VerificationCode />} path="/verification-code" />
          <Route element={<ConfirmRegister />} path="/confirm-register" />
          <Route element={<ChangePassword />} path="/change-password" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
});
