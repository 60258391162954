import { useEffect } from "react";
import styles from "./Modal.module.scss";

export const Modal = ({ isOpen, children }) => {
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }

    return () => {
      document.body.classList.remove("lock");
    };
  }, [isOpen]);

  return (
    <div className={`${styles.modalContainer} ${isOpen && styles.open}`}>
      <div
        className={`${styles.modal} ${isOpen && styles.open}`}
        onClick={onModalClick}
        role="presentation"
      >
        {children}
      </div>
    </div>
  );
};
