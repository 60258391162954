import { flow, types } from "mobx-state-tree";
import * as countriesApi from "../api/countries";
import * as documentsApi from "../api/documents";
import * as api from "../api/me";
import { LOCAL_STORAGE_KEYS } from "../utils/localStorage";
import { PAYMENT_STATUS } from "./consts";

export const EXPERIENCE = {
  NONE: "None",
  ONE_THREE: "1-3 years",
  THREE_FIVE: "3-5 years",
  MORE: "More",
};

const ExperienceRow = types
  .model({
    hasExperience: types.optional(types.boolean, false),
    experience: types.optional(
      types.enumeration(Object.values(EXPERIENCE)),
      EXPERIENCE.NONE
    ),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
  }));

const Option = types.model({
  value: types.maybeNull(types.string),
  selected: types.maybeNull(types.boolean),
});

const Effecta = types.model({
  name: types.maybeNull(types.string),
  needCheckBox: types.maybeNull(types.boolean),
  needFullReview: types.maybeNull(types.boolean),
  url: types.maybeNull(types.string),
});

const KYC = types
  .model({
    address: types.maybeNull(types.string),
    appendix: types.maybeNull(types.string),
    averageInvestment: types.maybeNull(types.string),
    birthDate: types.maybeNull(types.string),
    bonds: types.maybeNull(types.string),
    citizenship: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    educationLevel: types.optional(types.array(Option), []),
    employmentType: types.optional(types.array(Option), []),
    idIssuedOn: types.maybeNull(types.string),
    idNumber: types.maybeNull(types.string),
    idType: types.optional(types.array(Option), []),
    idValidUntil: types.maybeNull(types.string),
    independentInvestingExperience: types.maybeNull(types.string),
    investingExperience: types.maybeNull(types.string),
    investmentFunds: types.maybeNull(types.string),
    investmentsPerYear: types.maybeNull(types.string),
    issuingAuthority: types.maybeNull(types.string),
    moneyMarketFunds: types.maybeNull(types.string),
    placeOfBirth: types.maybeNull(types.string),
    profitRights: types.maybeNull(types.string),
    shares: types.maybeNull(types.string),
    subordinated: types.maybeNull(types.string),
    virtualCurrencies: types.maybeNull(types.string),
    wealthInvestingExperience: types.maybeNull(types.string),
    countryOfBorn: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    specialization: types.maybeNull(types.string),
    politicianSpecialization: types.maybeNull(types.string),
    isPolitician: types.maybeNull(types.boolean),
    zipCode: types.maybeNull(types.number),
    effecta: types.optional(Effecta, {}),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
  }));

const Country = types.model({
  id: types.optional(types.number, 0),
  flag: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  shortName: types.maybeNull(types.string),
});

const Transaction = types.model({
  amount: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  modifiedAt: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  subStatus: types.maybeNull(types.string),
  tokenTypeId: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  accountHolder: types.maybeNull(types.string),
  bic: types.maybeNull(types.string),
  iban: types.maybeNull(types.string),
  investmentAmount: types.maybeNull(types.string),
  payedAmount: types.maybeNull(types.string),
  referenceText: types.maybeNull(types.string),
  tokenTypeName: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(
    types.enumeration(Object.values(PAYMENT_STATUS))
  ),
  transactionName: types.maybeNull(types.number),
});

const Document = types.model({
  id: types.number,
  url: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  needFullReview: types.maybeNull(types.boolean),
  needCheckBox: types.maybeNull(types.boolean),
});

const ExperienceOption = types
  .model({
    value: types.string,
    selected: types.boolean,
    hasKnowledge: types.maybe(types.boolean),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
  }));

const FinancialInstrumentCost = types.model("FinancialInstrumentCost", {});

const Returns = types.model("FinancialInstrumentCost", {
  total: types.maybeNull(types.string),
  totalPercent: types.maybeNull(types.string),
});

const CostRow = types.model("FinancialInstrumentCost", {
  costsPerYear: types.maybeNull(types.string),
  costsPerYearPercent: types.maybeNull(types.string),
  oneTimeCost: types.maybeNull(types.string),
  oneTimeCostPercent: types.maybeNull(types.string),
});

const CostsAndAllowances = types
  .model("CostsAndAllowances", {
    amount: types.maybeNull(types.string),
    currencySign: types.maybeNull(types.string),
    duration: types.maybeNull(types.string),
    financialInstrumentCosts: types.optional(CostRow, {}),
    totalFICosts: types.optional(CostRow, {}),
    firstYearReturns: types.optional(Returns, {}),
    followingYearsReturns: types.optional(Returns, {}),
    forwarded: types.maybeNull(types.string),
    forwardedPercent: types.maybeNull(types.string),
    grants: types.optional(CostRow, {}),
    mediationCosts: types.optional(CostRow, {}),
    monetaryBenefits: types.maybeNull(types.string),
    monetaryBenefitsPercent: types.maybeNull(types.string),
    totalCosts: types.optional(CostRow, {}),
    costsOfBrokerage: types.maybeNull(types.string),
    grantsOnNAV: types.maybeNull(types.string),
    custodyOnTheNominal: types.maybeNull(types.string),
    custodyOnTheNAV: types.maybeNull(types.string),
    mediationCostsPercent: types.maybeNull(types.string),
    entryFee: types.maybeNull(types.string),
    grantPercent: types.maybeNull(types.string),
    davonAmount: types.maybeNull(types.string),
    monetaryBenefitsAmount: types.maybeNull(types.string),
  })
  .actions((self) => ({}));

const UserExperience = types
  .model("UserExperience", {
    averageInvestment: types.optional(types.array(ExperienceOption), []),
    bonds: types.optional(types.array(ExperienceOption), []),
    independentInvestingExperience: types.optional(
      types.array(ExperienceOption),
      []
    ),
    investingExperience: types.optional(types.array(ExperienceOption), []),
    investmentFunds: types.optional(types.array(ExperienceOption), []),
    investmentsPerYear: types.optional(types.array(ExperienceOption), []),
    moneyMarketFunds: types.optional(types.array(ExperienceOption), []),
    profitRights: types.optional(types.array(ExperienceOption), []),
    shares: types.optional(types.array(ExperienceOption), []),
    subordinated: types.optional(types.array(ExperienceOption), []),
    virtualCurrencies: types.optional(types.array(ExperienceOption), []),
    noInfo: types.maybeNull(types.optional(types.boolean, false)),
    wealthInvestingExperience: types.optional(
      types.array(ExperienceOption),
      []
    ),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
    updateHasKnowledge(rowName, newValues) {
      self[rowName] = newValues;
    },
    updateRow(rowName, field, value) {
      self[rowName] = self[rowName].map((item) => {
        if (item.value === value) {
          return {
            ...item,
            [field]: true,
          };
        }

        return {
          ...item,
          selected: false,
        };
      });
    },
  }));

const Steps = types
  .model("Steps", {
    activeExperience: types.optional(UserExperience, {}),
    confirmTwiceAverage: types.optional(types.boolean, false),
    confirmDisposable: types.optional(types.boolean, false),
    tangany: types.optional(types.boolean, false),
    kostenChecked: types.optional(types.boolean, false),
    noInfo: types.optional(types.boolean, false),
    confirmBasic: types.optional(types.boolean, false),
    bondConditions: types.optional(types.boolean, false),
    consumerInformation: types.optional(types.boolean, false),
    costsAndAllowances: types.optional(types.boolean, false),
    isDisabledKnowledgeNext: types.optional(types.boolean, true),
    isPressedCashLink: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
  }));

export const Investment = types
  .model("Investment", {
    activeStep: types.optional(types.number, 0),
    bonds: types.optional(ExperienceRow, {}),
    moneyMarketFunds: types.optional(ExperienceRow, {}),
    profitParticipationRights: types.optional(ExperienceRow, {}),
    subordinated: types.optional(ExperienceRow, {}),
    virtualCurrencies: types.optional(ExperienceRow, {}),
    kyc: types.optional(KYC, {}),
    countries: types.optional(types.array(Country), []),
    activeTransaction: types.optional(Transaction, {}),
    documents: types.optional(types.array(Document), []),
    userExperience: types.optional(UserExperience, {}),
    costsAndAllowances: types.optional(CostsAndAllowances, {}),
    steps: types.optional(Steps, {}),
  })
  .actions((self) => ({
    updateField(field, value) {
      if (field === "amount") {
        localStorage.setItem(LOCAL_STORAGE_KEYS.REQUEST_AMOUNT, value);
      } else {
        self[field] = value;
      }
    },
    getCountries: flow(function* getCountries() {
      self.countries = yield countriesApi.getCountries();
    }),
    getKYC: flow(function* getKYC() {
      const response = yield api.getKYC();
      self.kyc = response;
      return response;
    }),
    saveKYC: flow(function* saveKYC({ tokenId, kyc, amount }) {
      const response = yield api.saveKYC({
        data: kyc,
        tokenId,
        tokenAmount: amount,
      });
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.REQUEST_ID,
        response.investmentRequestId
      );

      return true;
    }),
    getUserExperience: flow(function* getUserExperience() {
      const response = yield api.getUserExperience();
      if (self.steps.activeExperience.averageInvestment.length === 0) {
        self.steps.activeExperience = response;
      }
      self.userExperience = response;
    }),
    updateUserExperience: flow(function* updateUserExperience() {
      const response = yield api.updateUserExperience(
        self.steps.activeExperience
      );
      return response;
    }),

    getCostsAndAllowances: flow(function* getCostsAndAllowances({
      transactionId,
    }) {
      const response = yield documentsApi.getCostsAndAllowances({
        transactionId,
      });
      self.costsAndAllowances = response;
    }),
    confirmDocuments: flow(function* confirmDocuments({ transactionId }) {
      const response = yield documentsApi.confirmDocuments({
        transactionId,
      });
      return response?.kycStatus?.redirectUrl;
    }),
    getTransaction: flow(function* getTransaction({ transactionId }) {
      try {
        const response = yield api.getTransaction({ transactionId });
        self.activeTransaction = response;
      } catch (err) {
        //
      }
    }),
    getDocuments: flow(function* getTransaction({ transactionId }) {
      try {
        const response = yield documentsApi.getTransactionDocuments({
          transactionId,
        });
        self.documents = response?.map((document, index) => ({
          ...document,
          id: index + 1,
        }));
      } catch (err) {
        //
      }
    }),
  }));
