import dayjs from "dayjs";
import styles from "./Transaction.module.scss";
import { TransactionTextType } from "./TransactionTextType";
import { TransactionTextStatus } from "./TransactionTextStatus";
import { TransactionTextAmount } from "./TransactionTextAmount";
import { ACTIVE_TRANSACTION_FORMAT } from "../../pages/Tokens/ActiveTransactions/ActiveTransactions";

export const Transaction = ({ transaction }) => {
  return (
    <div className={styles.transaction}>
      <div className={styles.transaction__section}>
        <div className={styles.type}>
          {transaction.productName}
          {/* <TransactionTextType type={transaction.transactionType} /> */}
        </div>
        <div className={styles.status}>
          <TransactionTextStatus status={transaction.status} />
        </div>
      </div>
      <div className={styles.transaction__section}>
        <div className={styles.amount}>
          <TransactionTextAmount
            amount={transaction.amount}
            currency={transaction.currency}
            status={transaction.status}
          />
        </div>
        <div className={styles.date}>
          {dayjs.unix(transaction.unix).format(ACTIVE_TRANSACTION_FORMAT)}
        </div>
      </div>
    </div>
  );
};
