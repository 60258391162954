import { flow, types } from "mobx-state-tree";
import dayjs from "dayjs";
import * as api from "../api/me";
import {
  KYS_STATUS,
  SORT_ORDERS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  WALLET_STATUS,
} from "./consts";
import { LOCAL_STORAGE_KEYS } from "../utils/localStorage";

const Balance = types.model("Balance", {
  total: types.maybeNull(types.number),
  recive: types.maybeNull(types.number),
  spend: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
});

const PrivacyItem = types.model("PDFItem", {
  name: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  needFullReview: types.maybeNull(types.boolean),
  needCheckBox: types.maybeNull(types.boolean),
});

const TermsItem = types.model("PDFItemTerms", {
  id: types.number,
  name: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  needFullReview: types.maybeNull(types.boolean),
  needCheckBox: types.maybeNull(types.boolean),
});

const Settings = types
  .model("Settings", {
    name: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    buildingNumber: types.maybeNull(types.string),
    zipCode: types.maybeNull(types.string),
    walletNumber: types.maybeNull(types.string),
    tfaActive: types.maybeNull(types.boolean),
    appendix: types.maybeNull(types.string),
    termsDocs: types.optional(types.array(TermsItem), []),
  })
  .actions((self) => ({
    getSettings: flow(function* getSettings() {
      return yield api.getSettings();
    }),
    getTerms: flow(function* getTerms() {
      const res = yield api.getTerms();
      self.termsDocs = res?.map((item, idx) => {
        return {
          ...item,
          id: idx + 1,
        };
      });
    }),
    updateSettings: flow(function* updateSettings({
      name,
      lastName,
      email,
      country,
      city,
      street,
      buildingNumber,
      zipCode,
      walletNumber,
      currentPassword,
      password,
      tfaActive,
      idNumber,
      idType,
      idIssuedOn,
      issuingAuthority,
      idValidUntil,
      appendix,
    }) {
      try {
        yield api.updateSettings({
          name,
          lastName,
          email,
          country,
          city,
          street,
          buildingNumber,
          zipCode,
          walletNumber,
          tfaActive,
          idNumber,
          idType,
          idIssuedOn,
          issuingAuthority,
          idValidUntil,
          appendix,
        });
        if (currentPassword?.trim() !== "" && password?.trim() !== "") {
          yield api.changePassword({
            currentPassword,
            newPassword: password,
          });
        }

        return true;
      } catch (err) {
        return false;
      }
    }),
  }));

const Transaction = types.model("Balance", {
  id: types.maybeNull(types.string),
  status: types.enumeration(
    "TRANSACTION_STATUS",
    Object.values(TRANSACTION_STATUS)
  ),
  transactionType: types.enumeration(
    "TRANSACTION_TYPE",
    Object.values(TRANSACTION_TYPE)
  ),
  unix: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  tokenId: types.maybeNull(types.number),
  productName: types.maybeNull(types.string),
});

export const sortType = types.optional(
  types.enumeration("SortType", Object.values(SORT_ORDERS)),
  SORT_ORDERS.NONE
);

const TransactionPagination = types.model("TransactionPagination", {
  date: sortType,
  status: sortType,
  amount: sortType,
  transactionType: sortType,
  month: types.maybeNull(types.number),
  query: types.maybeNull(types.string),
  pageNumber: types.optional(types.number, 0),
  pageSize: types.optional(types.number, 10),
  total: types.optional(types.number, 0),
});

const Transactions = types
  .model("Transactions", {
    list: types.optional(types.array(Transaction), []),
    pagination: types.optional(TransactionPagination, {}),
    privacyList: types.optional(types.array(PrivacyItem), []),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    getPrivacy: flow(function* getPrivacy({ transactionId }) {
      const response = yield api.getPrivacy({ transactionId });
      self.privacyList = response;
    }),
    deleteInvestment: flow(function* deleteInvestment(investmentId) {
      const response = yield api.deleteInvestment({
        investmentId,
      });
      self.list = self.list.filter((i) => i.id !== investmentId);
      return self.list;
    }),
    getTransactions: flow(function* getTransactions({
      date,
      status,
      amount,
      transactionType,
      month,
      query,
      pageNumber,
      pageSize,
    } = {}) {
      try {
        self.loading = true;
        const { data, headers } = yield api.getTransactions({
          date,
          status,
          amount,
          transactionType,
          month,
          query,
          pageNumber,
          pageSize,
        });
        self.list = data;
        self.pagination.total = Math.ceil(
          +headers["x-total-count"] / self.pagination.pageSize
        );
      } catch (err) {
        // todo handle error
        console.log({ err });
      } finally {
        self.loading = false;
      }
    }),
    updatePagination(key, value) {
      self.pagination[key] = value;
    },
  }))
  .views((self) => ({
    get tableTransactions() {
      return self.list.map(({ unix, ...transaction }) => {
        const date = new Date(unix * 1000);
        return {
          ...transaction,
          date: dayjs(date).format("DD.MM.YYYY"),
          time: dayjs(date).format("HH:mm:ss"),
        };
      });
    },
    get groupedTransactionsByDate() {
      const sorted = [...self.list]
        .map(({ unix, ...transaction }) => {
          const date = new Date(unix * 1000);

          return {
            ...transaction,
            unix,
            date: dayjs(date),
            time: dayjs(date),
          };
        })
        .sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1));
      return Object.entries(
        sorted.reduce((acc, transaction) => {
          const date = dayjs(transaction.date).format("YYYY-MM-DD");
          if (acc[date]) {
            acc[date].push(transaction);
          } else {
            acc[date] = [transaction];
          }
          return acc;
        }, {})
      );
    },
    get activeTransactions() {
      return self.list.filter(
        (i) =>
          i.status === TRANSACTION_STATUS.PROCESSING ||
          i.status === TRANSACTION_STATUS.WAITING
      );
    },
  }));

export const Me = types
  .model("Me", {
    kycStatus: types.maybeNull(
      types.enumeration("KYS_STATUS", Object.values(KYS_STATUS))
    ),
    walletStatus: types.maybeNull(
      types.enumeration("WALLET_STATUS", Object.values(WALLET_STATUS))
    ),
    transactions: types.optional(Transactions, {}),
    balance: types.optional(Balance, {}),
    settings: types.optional(Settings, {}),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    redirectUrl: types.maybeNull(types.string),
    email: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    newPassword: types.optional(types.string, ""),
    newEmail: types.optional(types.string, ""),
    confirmResetPassword: types.optional(types.string, ""),
    resetEmail: types.optional(types.string, ""),
    code: types.optional(types.string, ""),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
    isAuth: flow(function* isAuth() {
      try {
        const response = yield api.isAuth();
        self.kycStatus = response.kycStatus;
        self.walletStatus = response.walletStatus;
        self.redirectUrl = response.redirectUrl;
      } catch (err) {
        // todo handle error
      }
    }),
    login: flow(function* login({ username, password }) {
      return yield api.login({ username, password });
    }),
    confirmTfa: flow(function* confirmTfa({ key }) {
      try {
        const response = yield api.confirmTfa({ key });
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, response.id_token);

        return true;
      } catch (err) {
        // todo handle error
        return false;
      }
    }),
    register: flow(function* register({ password, email }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const code = yield api.register({ password, email });
        localStorage.setItem(LOCAL_STORAGE_KEYS.REGISTER_CODE, code);
        return true;
      } catch (err) {
        // todo handle error
        throw err;
      }
    }),
    confirmRegister: flow(function* confirmRegister({ key }) {
      const response = yield api.confirmRegister({ key });

      return response;
    }),
    restorePassword: flow(function* restorePassword({ email }) {
      yield api.resetPassword({ email });
    }),
    confirmResetCode: flow(function* confirmResetCode({ code, email }) {
      try {
        const response = yield api.confirmResetCode({ code, email });
        return true;
      } catch (err) {
        return false;
      }
    }),
    confirmRestore: flow(function* confirmRestore({ key, newPassword }) {
      try {
        const response = yield api.confirmResetPassword({ key, newPassword });
        return response;
      } catch (err) {
        // todo handle error
        return null;
      }
    }),
    getBalance: flow(function* getBalance() {
      try {
        self.balance = yield api.getBalance();
      } catch (err) {
        // todo handle error
      }
    }),
  }))
  .views((self) => ({
    get fullName() {
      return [self.firstName, self.lastName].join(" ").trim();
    },
  }));
