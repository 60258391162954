import { types } from "mobx-state-tree";
import { TokensModel } from "./tokens";
import { Me } from "./me";
import { App } from "./app";
import { Investment } from "./investment";

const RootStore = types.model("RootModel", {
  tokens: types.optional(TokensModel, {}),
  me: types.optional(Me, {}),
  app: types.optional(App, {}),
  investment: types.optional(Investment, {}),
});

export default RootStore;
