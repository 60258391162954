import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import styles from "../InvestmentData.module.scss";
import { Checkbox } from "../../../components/UI/Checkbox/Checkbox";
import { StepsButtons } from "./StepsButtons";
import useStore from "../../../hooks/useStore";
import { getRequestId } from "../../../utils/localStorage";

export const Wallet = observer(({ setDisabledNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const {
    app: { markLoading },
    investment: {
      steps: { tangany, updateField },
      documents,
      getDocuments,
    },
  } = useStore();
  const investmentRequestId = getRequestId();

  const onChangeTangany = (value) => {
    updateField("tangany", value);
  };

  const toPage4 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/4`);
  };

  const toPage2 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/2`);
  };

  useEffect(() => {
    setDisabledNext(!tangany);
  }, [tangany]);

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await getDocuments({ transactionId: investmentRequestId });
      markLoading(false);
    };

    getData();
  }, []);
  return (
    <div>
      <h4 className={styles.header}>
        {t("investment.wallet.safekeepingOfTheDigitalSecurities")}
      </h4>
      <div className={styles.whiteText}>
        {t("investment.wallet.tanganyLtd")}
      </div>
      <div className={cn(styles.margin16, styles.text)}>
        {t("investment.wallet.tanganyTokenInformation")}
      </div>
      <div className={styles.margin16}>
        <Checkbox
          mode="dark"
          label={
            <span>
              <span>{t("investment.wallet.termsAndConditions1")}</span>
              <a
                href={documents?.find((i) => i.name.includes("Tangany"))?.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t("investment.wallet.termsAndConditions2")}
              </a>{" "}
              <span>{t("investment.wallet.termsAndConditions3")}</span>
            </span>
          }
          initialState={tangany}
          onChange={onChangeTangany}
        />
      </div>
      <StepsButtons
        onNextClick={toPage4}
        onPrevClick={toPage2}
        disabledNext={!tangany}
      />
    </div>
  );
});
