import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStore";
import { EmptyTokens } from "./NoTokens/NoTokens";
import { Page } from "../../components/Page/Page";
import styles from "./Tokens.module.scss";
import { TokensList } from "./TokensList/TokensList";
import { TokenInformation } from "./TokenInformation/TokenInformation";
import { MobileActiveTransactions } from "./ActiveTransactions/MobileActiveTransactions";
import { Tokens } from "../../components/Tokens/Tokens";
import { RightBar } from "../../components/RightBar/RightBar";
import { ActiveTransactions } from "./ActiveTransactions/ActiveTransactions";
import useSizes from "../../hooks/useSizes";
import { deleteActiveToken, getActiveToken } from "../../utils/localStorage";
import { InfoBlock } from "../../components/InfoBlock/InfoBlock";

export const MyTokens = observer(() => {
  const {
    app: { markLoading, loading },
    tokens: { selfTokens, getSelfTokens, selfToken, setActiveToken },
    me: {
      transactions: { getTransactions, activeTransactions },
    },
  } = useStore();
  const { t } = useTranslation();
  const { isLaptop } = useSizes();

  const tokenTransactions = useMemo(() => {
    return activeTransactions?.filter((i) => +i.tokenId === +selfToken.id);
  }, [activeTransactions.length, selfToken.id, activeTransactions]);

  const getLink = (token) => {
    return `/tokens/${token.id}/investment`;
  };

  const onTokenClick = (token) => {
    setActiveToken(token.toJSON());
  };

  useEffect(() => {
    if (selfTokens.length > 0) {
      // need when page is reloads...
      const activeToken = getActiveToken();
      setActiveToken(activeToken || selfTokens[0].toJSON());
    }
  }, [selfToken.id, selfTokens.length]);

  useEffect(() => {
    const getData = async () => {
      try {
        markLoading(true);
        await getSelfTokens();
        await getTransactions();
      } catch (err) {
        //
      } finally {
        markLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => () => deleteActiveToken(), []);

  if (loading) {
    return (
      <>
        <Page />
        {!isLaptop && (
          <RightBar>
            <ActiveTransactions transactions={tokenTransactions} />
          </RightBar>
        )}
      </>
    );
  }

  if (!loading && !selfTokens.length) {
    return <EmptyTokens />;
  }

  return (
    <>
      <Page>
        <h4 className={styles.heading}>{t("tokens.analytics")}</h4>
        <TokensList tokens={selfTokens} onTokenClick={onTokenClick} />
        <TokenInformation activeToken={selfToken} />
        {isLaptop && (
          <div className={styles.mobileActiveTransactions}>
            <MobileActiveTransactions transactions={tokenTransactions} />
          </div>
        )}
        <Tokens
          renderHeader={() => (
            <div className={cn(styles.heading, "marginTop40")} />
          )}
          tokens={selfTokens.filter((token) => token.id === +selfToken?.id)}
          getLink={getLink}
        />

        <InfoBlock
          title={t("risk.riskNotice")}
          content={t("risk.riskDescription")}
          className={styles.margin24}
        />
      </Page>
      {!isLaptop && (
        <RightBar>
          <ActiveTransactions transactions={tokenTransactions} />
        </RightBar>
      )}
    </>
  );
});
