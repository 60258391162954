import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStore";
import styles from "./PropertiesBundle.module.scss";
import { PropertyBundle } from "./PropertyBundle";

export const PropertiesBundle = observer(({ className }) => {
  const { t } = useTranslation();
  const {
    tokens: { properties },
  } = useStore();

  if (!properties.length) {
    return null;
  }

  return (
    <div className={className}>
      <h4 className={styles.barHeader}>{t("properties.property.property")}</h4>
      <div className={styles.properties}>
        {properties?.map((property) => {
          return <PropertyBundle key={property.id} property={property} />;
        })}
      </div>
    </div>
  );
});
