export const KYS_STATUS = {
  SUCCESS: "SUCCESS",
  DECLINED: "DECLINED",
  COUPON_REQUESTED: "COUPON_REQUESTED",
  REVIEW_PENDING: "REVIEW_PENDING",
  INCOMPLETE: "INCOMPLETE",
  DOCS_EXPIRED: "DOCS_EXPIRED",
};

export const TRANSACTION_STATUS = {
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAIL: "DECLINED",
};

export const WALLET_STATUS = {
  CREATED: "CREATED",
  NOT_CREATED: "NOT_CREATED",
};

export const TRANSACTION_TYPE = {
  TOP_UP: "TOP_UP",
  CANCELED_INVESTING: "CANCELED_INVESTING",
  WITHDRAW: "WITHDRAW",
  INVESTING: "INVESTING",
};

export const SORT_ORDERS = {
  NONE: "none",
  ASC: "asc",
  DESC: "desc",
};

export const KIND_OF_ACTIVITY = {
  STUDENT_IN: "STUDENT_IN",
  EMPLOYEE: "ANGESTELLTE_R",
  SELF_EMPLOYED: "SEBSTSTANDIG",
  UNEMPLOYED: "ARBEITSLOS",
  OTHER: "OTHER",
  KEINE: "KEINE",
};

export const STUDENT_LEVEL = {
  KEINE: "KEINE",
  REAL_SCHOOL_QUALIFICATION: "REALSCHULABSCHLUSS",
  HIGH_SCHOOL_DIPLOMA: "ABITUR_FACHABITUR",
  TEACHING_VOCATIONAL_TRAINING: "LEHRE_BERFSAUSBILDUNG",
  COMPLETED: "ABGESCHLOSSENES",
};

export const PASSPORTS = {
  ID_PASS: "ID_PASS",
  PASSPORT: "REISPASS",
};

export const EXPERIENCE_DURATION = {
  NO_EXP: "NO_EXP",
  LESS_1_YEAR: "LESS_1_YEAR",
  LESS_3_YEAR: "LESS_3_YEAR",
  LESS_10_YEAR: "LESS_10_YEAR",
  _3_5_YEARS: "_3_5_YEARS",
  MORE_5_YEARS: "MORE_5_YEARS",
  MORE_10_YEARS: "MORE_10_YEARS",
};
export const BOUNDS = {
  NO_EXP: "NO_EXP",
  LESS_5: "LESS_5",
  _5_10: "_5_10",
  MORE_10: "MORE_10",
};

export const EXPERIENCE_VALUE = {
  NO_EXP: "NO_EXP",
  LESS_1K: "LESS_1K",
  LESS_3K: "LESS_3K",
  _1K_3K: "_1K_3K",
  _3K_5K: "_3K_5K",
  _5K_10K: "_5K_10K",
  MORE_10K: "MORE_10K",
  _10K_25K: "_10K_25K",
  _25K_100K: "_25K_100K",
  MORE_100K: "MORE_100K",
};

export const PAYMENT_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  DENIED: "DENIED",
  NOT_ENOUGH: "NOT_ENOUGH",
};

export const KNOWLEDGE_STATUS = {
  NO_INFO: "NO_INFO",
  NOT_ENOUGH: "NOT_ENOUGH",
  ENOUGH: "ENOUGH",
};

export const GENDER = {
  MALE: "MR",
  FEMALE: "MS",
  OTHER: "D",
};
