import cn from "classnames";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "../UI/ProgressBar/ProgressBar";
import styles from "./Rate.module.scss";

export const Rate = ({
  headerText,
  funded,
  profitValue,
  rate,
  available,
  duration,
}) => {
  const { t } = useTranslation();
  const founded = parseInt(funded || "0", 10);
  const foundedMy = parseInt(profitValue || "0", 10);
  const investmentMy = (founded / 100) * foundedMy;

  return (
    <div className={styles.rate}>
      <h4 className={styles.rate__header}>{headerText}</h4>
      <div className={styles.rate__item}>
        {/* <div> */}
        {/*   <span className={styles.funded}>{funded} </span> */}
        {/*   <span className={cn(styles.text, styles.marginRight)}> */}
        {/*     {t("tokens.token.funded")} */}
        {/*   </span> */}
        {/*   {profitValue && <span className={styles.profit}>{profitValue} </span>} */}
        {/*   {profitValue && ( */}
        {/*     <span className={styles.profitText}>{t("tokens.token.yours")}</span> */}
        {/*   )} */}
        {/* </div> */}
        {/* <div> */}
        {/*   <span className={styles.amount}>{available} </span> */}
        {/*   <span className={styles.amountText}> */}
        {/*     {t("tokens.token.amountAvailable")} */}
        {/*   </span> */}
        {/* </div> */}
      </div>
      {/* <div className={styles.progressBar}> */}
      {/*   <ProgressBar profit={investmentMy} defaultValue={founded} /> */}
      {/* </div> */}

      <div className={styles.rate__item}>
        <div>
          <span className={styles.text}>{t("tokens.token.interestRate")}</span>
          <span className={styles.value}>
            {rate} (entspricht aktuell 3,50 % Rendite)
          </span>
        </div>
        <div>
          <span className={styles.text}>{t("tokens.token.duration")}</span>
          <span className={styles.value}>{duration}</span>
        </div>
      </div>
    </div>
  );
};
