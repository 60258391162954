import { request } from "./request";

export const isAuth = () => {
  const url = "/api/login";
  return request({ url });
};

export const login = (body) => {
  const url = "/api/desktop/login";
  return request({ url, method: "post", body });
};

export const confirmTfa = ({ key }) => {
  const url = `/api/desktop/login/tfa?key=${key}`;
  return request({ url });
};

export const resetPassword = ({ email }) => {
  const url = `/api/account/reset-password/init`;
  return request({ url, method: "post", body: { email } });
};

export const confirmResetCode = async ({ code, email }) => {
  const url = `/api/account/reset-password/code`;
  return request({ url, method: "post", body: { username: email, code } });
};

export const confirmResetPassword = ({ newPassword, key }) => {
  const url = `/api/account/reset-password/finish`;
  return request({
    url,
    method: "post",
    body: { newPassword, key },
  });
};

export const register = ({ email, password }) => {
  const url = `/api/register`;
  return request({ url, method: "post", body: { email, password } });
};

export const confirmRegister = ({ key }) => {
  const url = `/api/activate?key=${key}`;
  return request({
    url,
  });
};

export const getBalance = () => {
  const url = "/api/balance";
  return request({ url });
};

export const getTransactions = (filters) => {
  const url = "/api/transactions/history";
  return request({ url, method: "post", body: filters, withHeaders: true });
};

export const getTransaction = ({ transactionId }) => {
  const url = `/api/transaction/${transactionId}/details`;
  return request({
    url,
  });
};

export const getSettings = () => {
  const url = "/api/refuture-user/settings";
  return request({ url });
};

export const getTerms = () => {
  const url = "/api/terms-and-conditions/desktop";
  return request({ url });
};

export const updateSettings = (body) => {
  const url = "/api/refuture-user/settings";
  return request({ url, method: "post", body });
};

export const changePassword = (body) => {
  const url = "/api/account/change-password";
  return request({ url, method: "post", body });
};

export const getPrivacy = ({ transactionId }) => {
  const url = `/api/transaction/${transactionId}/docs`;
  return request({ url });
};

export const deleteInvestment = ({ investmentId }) => {
  const url = `/api/investment/${investmentId}`;
  return request({ url, method: "delete" });
};

export const getKYC = () => {
  const url = "/api/private-user-details/current";
  return request({
    url,
  });
};

export const saveKYC = ({ data, tokenId, tokenAmount }) => {
  const url = `/api/token/${tokenId}/investment?tokenAmount=${tokenAmount}`;
  return request({
    url,
    method: "post",
    body: data,
  });
};

export const getUserExperience = () => {
  const url = "/api/user-experience/current";
  return request({
    url,
  });
};

export const updateUserExperience = (body) => {
  const url = "/api/user-experience/current";

  return request({
    url,
    method: "put",
    body,
  });
};
