import { useTranslation } from "react-i18next";
import { StatusKYC } from "./StatusKYC";
import { ReactComponent as CouponKYCIcon } from "../../icons/CouponKYC.svg";

export const CouponKYC = () => {
  const { t } = useTranslation();
  return (
    <StatusKYC
      text={t("kyc.statuses.coupon.text")}
      header={t("kyc.statuses.coupon.header")}
      icon={CouponKYCIcon}
    />
  );
};
