import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./TokenInformation.module.scss";
import { TokenInfoCard } from "./TokenInfoCard";
import { ReactComponent as AvailableIcon } from "../../../icons/available.svg";
import { ReactComponent as EquivalentIcon } from "../../../icons/Equivalent.svg";
import { ReactComponent as RateIcon } from "../../../icons/interest_rate.svg";
import { ReactComponent as EuroIcon } from "../../../icons/euro.svg";
import { TokenImageByURI } from "../../../components/TokenImageByURI/TokenImageByURI";
import useStore from "../../../hooks/useStore";

export const TokenInformation = observer(({ activeToken }) => {
  const { t } = useTranslation();
  const {
    tokens: { selfToken },
  } = useStore();

  return (
    <div className={styles.info}>
      {/* <TokenInfoCard> */}
      {/*   <AvailableIcon /> */}
      {/*   <div className={styles.infoCard_heading}> */}
      {/*     {t("tokens.token.infoCard.available")} */}
      {/*   </div> */}
      {/*   <div className={styles.infoCard_text}> */}
      {/*     <div className={styles.icon}> */}
      {/*       <TokenImageByURI tokenId={selfToken?.id} /> */}
      {/*     </div> */}
      {/*     {activeToken?.availableTokens} */}
      {/*   </div> */}
      {/* </TokenInfoCard> */}
      <TokenInfoCard>
        <EquivalentIcon />
        <div className={styles.infoCard_heading}>
          {t("tokens.token.infoCard.equivalent")}
        </div>
        <div className={styles.infoCard_text}>
          {activeToken?.amount} {activeToken?.currency}
        </div>
      </TokenInfoCard>
      <TokenInfoCard>
        <RateIcon />
        <div className={styles.infoCard_heading}>
          {t("tokens.token.infoCard.interestRate")}
        </div>
        <div className={styles.infoCard_text}>{activeToken?.interestRate}</div>
      </TokenInfoCard>
      {/* <TokenInfoCard> */}
      {/*   <EuroIcon /> */}
      {/*   <div className={styles.infoCard_heading}> */}
      {/*     {t("tokens.token.infoCard.received")} */}
      {/*   </div> */}
      {/*   <div className={styles.infoCard_text}> */}
      {/*     {activeToken?.received} {activeToken?.currency} */}
      {/*   </div> */}
      {/* </TokenInfoCard> */}
    </div>
  );
});
