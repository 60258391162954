import styles from "./NoData.module.scss";

export const NoData = ({ text, icon, title, renderButton = () => {} }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.noData}>
        {icon}
        <h2>{title}</h2>
        <p>{text}</p>
        {renderButton()}
      </div>
    </div>
  );
};
