import { flow, types } from "mobx-state-tree";
import { getAllTokensIcons } from "../api/documents";
import { request } from "../api/request";

const Icon = types.model("Icon", {
  id: types.number,
  png: types.string,
  svg: types.string,
});

const CashedIcons = types.model("CashedIcons");

export const App = types
  .model("App", {
    loading: types.optional(types.boolean, false),
    icons: types.optional(types.array(Icon), []),
    cachedIcons: types.optional(CashedIcons, {}),
  })
  .actions((self) => ({
    markLoading(flag) {
      self.loading = flag;
    },
    getAllIcons: flow(function* getAllIcons() {
      self.icons = yield getAllTokensIcons();
    }),
    getIcon: flow(function* getIcon(url, iconId) {
      if (self.cachedIcons[iconId]) {
        return self.cachedIcons[iconId];
      }

      const u = `/api/file/icons/${iconId}.svg`;

      const icon = yield request({ url: u });
      self.cachedIcons[iconId] = icon;
      return icon;
    }),
  }));
