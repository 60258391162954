import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const useSettingsSchema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    newPassword: Yup.string()
      .min(8, t("settings.password.minLength"))
      .max(24, t("settings.password.maxLength"))
      .matches(/(.*[A-Z].*)/, t("settings.password.capitalLetter"))
      .matches(/(.*[0-9].*)/, t("settings.password.number"))
      .matches(/(.*[a-z].*)/, t("settings.password.lowerCase")),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref("newPassword")],
      t("settings.password.passwordMustMatch")
    ),
    email: Yup.string().email(t("login.errors.enterCorrectEmail")),
    idIssuedOn: Yup.string()
      .required(t("kyc.fieldIsRequired", { field: t("kyc.IDIssuedOn") }))
      .test("date test", t("general.wrongDate"), (value) => {
        return new Date().getTime() >= new Date(value).getTime();
      }),
    idValidUntil: Yup.string()
      .required(t("kyc.fieldIsRequired", { field: t("kyc.IDValidUntil") }))
      .test("date test", t("general.wrongDate"), (value) => {
        const dateNow = dayjs(dayjs().format("YYYY-MM-DD"));
        const selected = dayjs(value);
        return dateNow.diff(selected) < 10000;
      }),
  });
};
