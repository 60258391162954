import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styles from "./PropertiesBundle.module.scss";
import { ReactComponent as LocationOnIcon } from "../../icons/location-on.svg";

export const PropertyBundle = observer(({ property }) => {
  const { tokenId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <NavLink
        to={`/tokens/${tokenId}/properties/${property.id}`}
        className={styles.property}
      >
        <div className={styles.property__image}>
          <img src={property.imageUrl} alt="building" />
        </div>
        <div className={styles.property__description}>
          <h5>{property.name}</h5>
          {/* <p> */}
          {/*   <span className={styles.text_secondary}> */}
          {/*     {t("properties.livingArea")} */}
          {/*   </span>{" "} */}
          {/*   {property.livingArea} */}
          {/* </p> */}
          <p>{property.location}</p>
          <p className={styles.property__location}>
            <LocationOnIcon style={{ marginLeft: -3 }} />
            {property.addressGeocoded}
          </p>
        </div>
      </NavLink>
    </>
  );
});
