import { useEffect, useRef } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import { ReactComponent as CheckIcon } from "../../../icons/white-check.svg";
import styles from "./Steps.module.scss";
import { HiddenScroll } from "../../HiddenScroll/HiddenScroll";

export const Steps = ({
  steps = [],
  onlyNext = true,
  getNextLink = () => {},
  disabledNext,
  onNextClick,
}) => {
  const { stepId } = useParams();
  const location = useLocation();

  const scrollRef = useRef(null);
  const activeStepRef = useRef(null);
  const isActiveStep = (index) => {
    return stepId === index.toString();
  };

  const isCompleted = (index) => {
    return stepId > index.toString();
  };

  const isDisabledNextBtn = (index) => {
    if (disabledNext && +stepId + 1 === index) {
      return true;
    }
    return onlyNext ? +stepId + 1 < index : false;
  };

  useEffect(() => {
    scrollRef?.current?.scrollTo({
      left: activeStepRef?.current?.offsetLeft,
    });
  }, []);

  return (
    <div className={styles.steps}>
      <div className={styles.steps__wrapper}>
        <HiddenScroll scrollRef={scrollRef}>
          <div className={styles.steps__list}>
            {steps?.map((item) => {
              return (
                <div className={styles.item} key={item.index}>
                  <NavLink
                    onClick={onNextClick}
                    to={
                      isDisabledNextBtn(item.index)
                        ? location.pathname
                        : getNextLink(item.index)
                    }
                    className={cn({
                      [`${styles.step}`]: true,
                      [`${styles.completed}`]: isCompleted(item.index),
                      [`${styles.disabled}`]: isDisabledNextBtn(item.index),
                    })}
                    ref={isActiveStep(item.index) ? activeStepRef : undefined}
                  >
                    <div className={styles.step__wrapper}>
                      <div
                        className={cn({
                          [`${styles.step__circle}`]: true,
                          [`${styles.circle}`]: true,
                          [`${styles.disabled}`]: isDisabledNextBtn(item.index),
                          [`${styles.active}`]: isActiveStep(item.index),
                          [`${styles.completed}`]: isCompleted(item.index),
                        })}
                      >
                        <div className={styles.step__header}>
                          {isCompleted(item.index) ? <CheckIcon /> : item.index}
                        </div>
                      </div>
                      <div
                        className={cn({
                          [`${styles.step__text}`]: true,
                          [`${styles.active}`]: isActiveStep(item.index),
                        })}
                      >
                        {item.title}
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </HiddenScroll>
      </div>

      <div className={styles.activeStep}>{steps?.[stepId - 1]?.Component}</div>
    </div>
  );
};
