import { useEffect, useState } from "react";

const useSizes = () => {
  const [sizes, setSizes] = useState({
    isTablet: undefined,
    isLaptop: undefined,
    isLargeLaptop: undefined,
    isMobile: undefined,
  });

  useEffect(() => {
    let resizeTimeout;
    const handleResize = () => {
      const width = window.innerWidth;
      const sizes = {
        isMobile: width < 641,
        isLaptop: width <= 1134,
        isLargeLaptop: width > 1133,
      };

      setSizes(sizes);
    };

    const onDocumentResize = () => {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(() => {
          resizeTimeout = null;
          handleResize();
        }, 66);
      }
    };
    window.addEventListener("resize", onDocumentResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", onDocumentResize);
    };
  }, []);

  return sizes;
};

export default useSizes;
