import { useEffect, useRef } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import styles from "./TokensList.module.scss";
import { TokenImageByURI } from "../../../components/TokenImageByURI/TokenImageByURI";
import useStore from "../../../hooks/useStore";
import { HiddenScroll } from "../../../components/HiddenScroll/HiddenScroll";

export const TokensList = observer(({ tokens, onTokenClick }) => {
  const {
    tokens: { selfToken },
  } = useStore();
  const listRef = useRef(null);
  const itemRef = useRef(null);

  useEffect(() => {
    listRef?.current?.scrollTo({
      left: itemRef?.current?.offsetLeft - 20,
    });
  }, [tokens.length]);

  return (
    <HiddenScroll scrollRef={listRef}>
      <div className={styles.list}>
        {tokens?.map((token) => {
          return (
            <div
              key={token.id}
              className={cn({
                [`${styles.list__item}`]: true,
                [`${styles.item}`]: true,
                [`${styles.selected}`]: +selfToken?.id === +token.id,
              })}
              onClick={onTokenClick.bind(null, token)}
              role="presentation"
              ref={+selfToken?.id === +token?.id ? itemRef : undefined}
            >
              <div className={styles.icon}>
                <TokenImageByURI tokenId={token?.id} />
              </div>
            </div>
          );
        })}
      </div>
    </HiddenScroll>
  );
});
