import React, { useRef } from "react";
import styles from "./RightBar.module.scss";

export const RightBar = ({ children }) => {
  const rightBarRef = useRef(null);

  return (
    <div className={styles.rightBar} id="right-bar" ref={rightBarRef}>
      {children}
    </div>
  );
};
