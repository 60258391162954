import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./Tokens.module.scss";
import { Breadcrumb } from "../../components/UI/Breadcrumb/Breadcrumb";
import { RightBar } from "../../components/RightBar/RightBar";
import { PropertiesBundle } from "../../components/PropertiesBundle/PropertiesBundle";
import useStore from "../../hooks/useStore";
import { MobilePropertiesBundle } from "../../components/PropertiesBundle/MobilePropertiesBundle";
import useSizes from "../../hooks/useSizes";
import { Balance } from "../../components/Tokens/Balance";
import { Rate } from "../../components/Tokens/Rate";
import { Calculate } from "../../components/Tokens/Calculate";
import { ConfirmationOfIdentity } from "../../components/ConfirmationOfIdentity/ConfirmationOfIdentity";
import { Modal } from "../../components/UI/Modal/Modal";
import { Button } from "../../components/UI/Button/Button";
import { PersonalDetails } from "../../components/PersonalDetails/PersonalDetails";
import { TokenImageByURI } from "../../components/TokenImageByURI/TokenImageByURI";
import { Page } from "../../components/Page/Page";
import { InfoBlock } from "../../components/InfoBlock/InfoBlock";
import { message } from "../../components/Message/Message";
import { RightBarTokens } from "./RightBarTokens/RightBarTokens";

let messageId = "";

export const TokenPage = observer(() => {
  const { tokenId } = useParams();
  const { t } = useTranslation();
  const { isLaptop } = useSizes();
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [personalDetails, setPersonalDetails] = useState(false);

  const {
    app: { markLoading },
    me: { kycStatus, redirectUrl },
    tokens: {
      token,
      amount,
      updateField,
      updateTokenAmount,
      getProperties,
      selfTokens,
    },
  } = useStore();

  const openConfirmation = () => {
    if (messageId) {
      toast.dismiss(messageId);
    }

    if (disabled) {
      messageId = message({
        type: "error",
        title: t("general.error"),
        text: t("tokens.token.errorMinAmount"),
      });
      return;
    }

    if (!redirectUrl && !kycStatus) {
      setIsOpenConfirmation(true);
    } else {
      setPersonalDetails(true);
    }
  };

  const onAmountError = (hasError) => {
    setDisabled(hasError);
  };

  const crumbs = useMemo(() => {
    return [
      {
        to: "/",
        text: t("mainNav.home"),
      },
      {
        to: `/home/${tokenId}/investment/new`,
        text: token?.name,
      },
    ];
  }, [token.name]);

  useEffect(() => () => updateField("amount", ""), []);

  useEffect(() => {
    return setDisabled(!parseInt(amount, 10));
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        markLoading(true);
        await updateTokenAmount({ tokenId, amount: amount || 0 });
        await getProperties({ tokenId });
      } catch (err) {
        //
      } finally {
        markLoading(false);
      }
    };
    getData();
  }, [tokenId]);

  const getTokenUrl = (token) => {
    return `/tokens/${token.id}/investment`;
  };

  return (
    <>
      <Page>
        <div className={styles.token}>
          <Breadcrumb crumbs={crumbs} />
          {isLaptop && <MobilePropertiesBundle />}

          <Rate
            headerText={t("tokens.token.propertyHighlights")}
            funded={token?.funded}
            available={token?.availableTokens}
            duration={token?.duration}
            rate={token?.interestRate}
          />

          <Balance
            total={token?.totalTokens}
            expectedYield={token?.expectedYield}
            currency={token?.currency}
            rentPerToken={token?.rentPerToken}
            tokenPrice={token?.tokenPrice}
            investment={token?.investment}
            investmentMy={token?.yourInvestment}
            icon={<TokenImageByURI tokenId={token?.id} />}
            minimumInvestment={token?.minimumInvestment}
            maximumInvestment={token?.maximumInvestment}
            nettoIstMiete={token?.nettoIstMiete}
            investmentForm={token?.investmentForm}
            formula={token?.formula}
          />

          <InfoBlock
            title={t("risk.riskNotice")}
            content={t("risk.riskDescription")}
            className={styles.margin24}
          />

          <Calculate
            icon={token?.icon}
            onError={onAmountError}
            availableTokens={token?.availableTokens}
          />

          <div className={styles.footer}>
            <div className={styles.buttons}>
              <Button width={340} height={50} onClick={openConfirmation}>
                {t("general.invest")}
              </Button>
            </div>
          </div>
          <PersonalDetails
            isOpen={personalDetails}
            setIsOpen={setPersonalDetails}
            tokenId={tokenId}
          />
          <Modal isOpen={isOpenConfirmation}>
            <ConfirmationOfIdentity setIsOpen={setIsOpenConfirmation} />
          </Modal>
        </div>
      </Page>
      {!isLaptop && (
        <RightBar>
          <RightBarTokens tokens={selfTokens} navigateTo={getTokenUrl} />
          <PropertiesBundle />
        </RightBar>
      )}
    </>
  );
});
