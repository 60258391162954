import ReactPaginate from "react-paginate";
import { observer } from "mobx-react-lite";

const Previous = ({ currentPage }) => {
  return currentPage !== 0 ? "<" : null;
};

const Next = ({ pageCount, currentPage }) => {
  return currentPage + 1 === pageCount ? null : ">";
};

export const Pagination = observer(
  ({ pageCount = 100, onPaginate = () => {}, initialPage }) => {
    const handlePageClick = (pageIndex) => {
      onPaginate(pageIndex);
    };

    return (
      <ReactPaginate
        className="pagination"
        breakLabel="..."
        nextLabel={<Next pageCount={pageCount} currentPage={initialPage} />}
        onPageChange={handlePageClick}
        initialPage={initialPage}
        pageCount={pageCount}
        previousLabel={<Previous currentPage={initialPage} />}
        renderOnZeroPageCount={null}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
      />
    );
  }
);
