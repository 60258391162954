import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import useStore from "../../hooks/useStore";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";

export const ConfirmRegister = observer(() => {
  const { t } = useTranslation();
  const schema = useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t("login.errors.theCodeIsntCorrect")),
    });
  }, []);
  const {
    me: { confirmRegister, register, newPassword, newEmail },
  } = useStore();
  const navigate = useNavigate();
  const key = localStorage.getItem(LOCAL_STORAGE_KEYS.REGISTER_CODE);

  const submitCode = async () => {
    try {
      const response = await confirmRegister({ key: values.code });
      navigate("/sign-in");
    } catch (err) {
      if (err?.response?.status === 400 || err?.response?.status === 401) {
        setErrors({ ...errors, code: t("login.errors.theCodeIsntCorrect") });
      }
    }
  };

  const {
    values,
    setErrors,
    handleSubmit,
    handleChange,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    validationSchema: schema,
    onSubmit: submitCode,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: "",
    },
  });

  const resendCode = async () => {
    await register({ email: newEmail, password: newPassword });
  };

  useEffect(
    () => () => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.REGISTER_CODE);
    },
    []
  );

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.signInContainer}>
        <div className={styles.signIn}>
          <div className={styles.header}>{t("login.verifyItsYou")}</div>
          <div className={styles.subHeader}>{t("login.confirmTheEmail")}</div>
          <form onSubmit={handleSubmit}>
            <div className={styles.input}>
              <TextInput
                label={t("login.verificationCode")}
                environment="modal"
                defaultValue={values.code}
                name="code"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.code && errors.code}
              />
            </div>
            {key && (
              <div className={styles.center}>
                {t("login.didNotGotTheCode")}
                <span
                  className={styles.link}
                  onClick={resendCode}
                  role="presentation"
                >
                  {t("login.resend")}
                </span>
              </div>
            )}

            <Button type="submit" className={styles.footerButton}>
              {t("general.done")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
});
