import { useContext } from "react";
import styles from "./Page.module.scss";
import { LayoutContext } from "../Layout/Layout";
import useSizes from "../../hooks/useSizes";

export const Page = ({ children }) => {
  const element = document.getElementById("right-bar");
  const content = useContext(LayoutContext).contentRef?.current;
  const { isLaptop, isLargeLaptop } = useSizes();

  if (!element && content && !isLaptop) {
    content.style.width = "calc(100% - 250px)";
  } else if (!element && content && isLaptop) {
    content.style.removeProperty("width");
  }

  return (
    <div className={styles.page}>
      <div className={styles.scrollOffset}>{children}</div>
    </div>
  );
};
