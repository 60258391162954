import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "../UI/Modal/Modal";
import styles from "./PersonalDetails.module.scss";
import { Button } from "../UI/Button/Button";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import useStore from "../../hooks/useStore";
import { useResetKYC } from "../../hooks/useResetKYC";

export const PersonalDetails = observer(({ isOpen, setIsOpen, tokenId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    app: { markLoading },
    investment: { saveKYC },
    tokens: { amount },
  } = useStore();

  const closeModal = () => {
    setIsOpen(false);
  };

  const onClickUseSavedData = async () => {
    markLoading(true);
    const success = await saveKYC({ tokenId, amount });
    markLoading(false);
    if (success) {
      closeModal();
      navigate(`/tokens/${tokenId}/investment/steps/1`);
    }
  };

  const onClickWantToChange = () => {
    closeModal();
    navigate(`/tokens/${tokenId}/investment/kyc`);
  };

  useResetKYC();

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.personalDetails}>
        <span
          className={styles.personalDetails__close}
          onClick={closeModal}
          role="presentation"
        >
          <CloseIcon />
        </span>
        <div className={styles.personalDetails__content}>
          <h4>{t("personalDetails.personalDetails")}</h4>
          <p>{t("personalDetails.wouldYouLikeToChangePersonalDetails")}</p>
          <div className={styles.personalDetails__buttons}>
            <Button onClick={onClickUseSavedData}>
              {t("personalDetails.useMySavedData")}
            </Button>
            <Button onClick={onClickWantToChange} outlined>
              {t("personalDetails.iWantToChangeIt")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});
