import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styles from "./Home.module.scss";
import { RightBar } from "../../components/RightBar/RightBar";
import { Tokens } from "../../components/Tokens/Tokens";
import { RecentTransactions } from "../../components/Transactions/RecentTransactions";
import useStore from "../../hooks/useStore";
import { DeclinedKYC } from "../../components/StatusesKYC/DeclinedKYC";
import { SuccessKYC } from "../../components/StatusesKYC/SuccessKYC";
import { CouponKYC } from "../../components/StatusesKYC/CouponKYC";
import { PendingKYC } from "../../components/StatusesKYC/PendingKYC";
import { IncompleteKYC } from "../../components/StatusesKYC/IncompleteKYC";
import { KYS_STATUS, WALLET_STATUS } from "../../store/consts";
import { PersonalDetails } from "../../components/PersonalDetails/PersonalDetails";
import { EntryHomeScreen } from "../../components/StatusesKYC/EntryHomeScreen";
import { Page } from "../../components/Page/Page";
import useSizes from "../../hooks/useSizes";
import { isExistsKYCStatusSuccess } from "../../utils/localStorage";
import { ExpiredKYC } from "../../components/StatusesKYC/ExpiredKYC";
import { SuccessWithoutWallet } from "../../components/StatusesKYC/SuccessWithoutWallet";
import { InfoBlock } from "../../components/InfoBlock/InfoBlock";

export const Home = observer(() => {
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState([]);
  const [updateKYCIsOpen, setUpdateKYCIsOpen] = useState(false);
  const [activeTokenId, setActiveTokenId] = useState(null);
  const { isLaptop } = useSizes();
  const status = isExistsKYCStatusSuccess();
  const [showStatus, setShowStatus] = useState(!status);

  const {
    me: {
      kycStatus,
      redirectUrl,
      walletStatus,
      transactions: { getTransactions, list },
      isAuth,
    },
    tokens: { tokens, getTokens, selfTokens, getSelfTokens },
    app: { markLoading, loading },
  } = useStore();

  const getTokenLink = (token) => {
    const existsToken = selfTokens?.find(({ id }) => id === token.id);
    if (existsToken) {
      return `/tokens/${existsToken?.id}/investment`;
    }
    return `/home/${token.id}/investment/new`;
  };

  const showKycStatus = () => {
    if (status && kycStatus === KYS_STATUS.SUCCESS) {
      return false;
    }

    if (!status && kycStatus === KYS_STATUS.SUCCESS) {
      return true;
    }

    return !(kycStatus === null && list.length > 0);
  };

  const KYCComponent = useCallback(() => {
    if (loading) {
      return null;
    }

    if (kycStatus === KYS_STATUS.DOCS_EXPIRED) {
      return <ExpiredKYC />;
    }

    if (kycStatus === KYS_STATUS.DECLINED) {
      return <DeclinedKYC />;
    }

    if (
      kycStatus === KYS_STATUS.SUCCESS &&
      walletStatus === WALLET_STATUS.CREATED
    ) {
      return (
        <SuccessKYC showStatus={showStatus} setShowStatus={setShowStatus} />
      );
    }

    if (
      kycStatus === KYS_STATUS.SUCCESS &&
      walletStatus === WALLET_STATUS.NOT_CREATED
    ) {
      return <SuccessWithoutWallet />;
    }

    if (kycStatus === KYS_STATUS.COUPON_REQUESTED) {
      return <CouponKYC />;
    }

    if (kycStatus === KYS_STATUS.INCOMPLETE) {
      return <IncompleteKYC />;
    }

    if (kycStatus === KYS_STATUS.REVIEW_PENDING) {
      return <PendingKYC />;
    }

    if (kycStatus === null && list.length === 0) {
      return <EntryHomeScreen />;
    }

    return null;
  }, [showStatus, kycStatus, list.length, loading]);

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await isAuth();
      await getTokens();
      await getSelfTokens();
      markLoading(false);
    };
    getData();
  }, []);

  return (
    <>
      <Page>
        <div className={styles.home}>
          {showKycStatus() && (
            <div className={styles.kycStatus}>
              <KYCComponent graphData={graphData} />
            </div>
          )}

          <div className={styles.tokensWrapper}>
            <Tokens
              getLink={getTokenLink}
              tokens={tokens}
              renderHeader={() => {
                return (
                  <>
                    <h3 style={{ marginBottom: 20 }}>{t("tokens.tokens")}</h3>
                  </>
                );
              }}
            />
          </div>

          <InfoBlock
            title={t("risk.riskNotice")}
            content={t("risk.riskDescription")}
            className={styles.margin24}
          />

          <PersonalDetails
            isOpen={updateKYCIsOpen}
            setIsOpen={setUpdateKYCIsOpen}
            tokenId={activeTokenId}
          />
        </div>

        {isLaptop && (
          <div className={styles.laptopRecentTransactions}>
            <RecentTransactions
              tokens={tokens}
              showCount={5}
              getTransactions={getTransactions}
              renderHeader={() => {
                return (
                  <>
                    <h3>{t("transactions.recentTransactions")}</h3>
                  </>
                );
              }}
            />
          </div>
        )}
      </Page>

      {!isLaptop && (
        <RightBar>
          <RecentTransactions
            tokens={tokens}
            showCount={5}
            getTransactions={getTransactions}
            renderHeader={() => {
              return (
                <>
                  <h3>{t("transactions.recentTransactions")}</h3>
                </>
              );
            }}
          />
        </RightBar>
      )}
    </>
  );
});
