import { request } from "./request";

export const getTokensGraph = async () => {
  const url = "/api/tokens/graph/desktop";
  return request({
    url,
  });
};

export const getTokens = async () => {
  const url = "/api/tokens";
  return request({
    url,
    shouldCancel: true,
  });
};

export const getSelfTokens = async () => {
  const url = "/api/tokens/my";
  return request({
    url,
  });
};

export const getToken = async ({ tokenId, amount }) => {
  const url = `/api/desktop/token/${tokenId}`;
  return request({
    url,
    params: {
      amount,
    },
  });
};

export const getAccumulatedTokenValue = async ({ tokenId, amount }) => {
  const url = `/api/token-type/${tokenId}/investments/my`;
  return request({
    url,
  });
};

export const updateTokenAmount = async ({ tokenId, amount = 0 }) => {
  const url = `/api/token-types/${tokenId}?tokenAmount=${+amount}`;
  return request({
    url,
  });
};
export const updateMyTokenAmount = async ({ tokenId, amount = 0 }) => {
  const url = `/api/token-types/${tokenId}/my?tokenAmount=${+amount}`;
  return request({
    url,
  });
};

export const getSelfToken = async ({ tokenId, amount }) => {
  const url = `/api/desktop/token/my/${tokenId}`;
  return request({
    url,
    params: {
      amount,
    },
  });
};

export const getProperties = async ({ tokenId }) => {
  const url = `/api/token/${tokenId}/properties`;
  return request({
    url,
  });
};

export const getProperty = async ({ propertyId }) => {
  const url = `/api/property/${propertyId}`;
  return request({
    url,
  });
};

export const getTransactionById = async ({ transactionId }) => {
  const url = `/api/transactions/${transactionId}/details`;
  return request({
    url,
  });
};
