import { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styles from "./ActiveTransactions.module.scss";
import { ReactComponent as DeleteIcon } from "../../../icons/delete.svg";
import { ReactComponent as EyeIcon } from "../../../icons/eye.svg";
import { Modal } from "../../../components/UI/Modal/Modal";
import { CancelModal } from "./CancelModal";
import useStore from "../../../hooks/useStore";
import { getActiveToken, setActiveToken } from "../../../utils/localStorage";

export const Actions = observer(({ transaction, isOpen, onClickHandler }) => {
  const { t } = useTranslation();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const {
    app: { markLoading },
    tokens: { getSelfTokens, selfTokens },
    me: {
      transactions: { deleteInvestment },
    },
  } = useStore();

  const onDeleteClick = () => {
    setIsOpenDeleteModal(true);
  };

  const onDeleteHandler = async () => {
    markLoading(true);
    const activeToken = getActiveToken();
    const list = await deleteInvestment(transaction.id);
    await getSelfTokens();
    if (
      list.filter((transaction) => transaction.tokenId === activeToken.id)
        .length === 0
    ) {
      setActiveToken(selfTokens[0].toJSON());
    }
    setIsOpenDeleteModal(false);
    markLoading(false);
  };

  const onOpenClick = () => {
    navigate(`/tokens/${transaction.id}/view`);
  };

  const closeModal = () => {
    setIsOpenDeleteModal(false);
  };

  return (
    <>
      <Modal isOpen={isOpenDeleteModal}>
        <CancelModal onClose={closeModal} onSave={onDeleteHandler} />
      </Modal>

      <div
        className={cn({
          [`${styles.transaction__actions}`]: true,
          [styles.open]: isOpen,
        })}
        data-action="action"
        onClick={onClickHandler.bind(null, transaction?.id)}
        role="presentation"
      >
        <span className={styles.dots} />

        {isOpen && (
          <div className={styles.menu}>
            <div
              className={styles.menu__item}
              onClick={onOpenClick}
              role="presentation"
            >
              <div className={cn(styles.icon)}>
                <EyeIcon />
              </div>
              <div className={cn(styles.text)}>{t("general.open")}</div>
            </div>
            <div
              className={styles.menu__item}
              onClick={onDeleteClick}
              role="presentation"
            >
              <div className={cn(styles.menu__icon, styles.icon)}>
                <DeleteIcon />
              </div>
              <div className={cn(styles.menu__text, styles.text)}>
                {t("general.delete")}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});
