import { useState } from "react";
import cn from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styles from "./ActiveTransactions.module.scss";
import { TokenImageByURI } from "../../../components/TokenImageByURI/TokenImageByURI";
import { TRANSACTION_STATUS } from "../../../store/consts";
import { ACTIVE_TRANSACTION_FORMAT } from "./ActiveTransactions";
import { Actions } from "./Actions";

export const MobileActiveTransactions = observer(({ transactions }) => {
  const [openAction, setOpenAction] = useState(null);
  const { t } = useTranslation();

  const openActions = (transactionId) => {
    setOpenAction((prev) => (prev === transactionId ? null : transactionId));
  };

  return (
    <>
      {transactions?.length > 0 && (
        <h3 className={styles.activeTransactions__header}>
          {t("transactions.activeTransactions")}
        </h3>
      )}
      <div className={cn(styles.activeTransactions__list, styles.list)}>
        {transactions?.map((transaction) => {
          return (
            <div
              key={transaction.id}
              className={cn(styles.list__item, styles.item)}
            >
              <div className={styles.item__info}>
                <div className={styles.item__icon}>
                  <TokenImageByURI tokenId={transaction?.tokenId} />
                </div>
                <div className={styles.item__content}>
                  <div className={styles.item__block}>
                    <div className={styles.item__type}>
                      {/* {t( */}
                      {/*   `transactions.transactionTypes.${transaction.transactionType}` */}
                      {/* )} */}
                      {transaction.productName}
                    </div>
                    <div
                      className={styles.item__amount}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {transaction.amount} {transaction.currency}
                    </div>
                  </div>
                  <div className={styles.item__block}>
                    <div
                      className={cn({
                        [styles.transaction__status]: true,
                        [styles.transaction__processing]:
                          transaction.status === TRANSACTION_STATUS.PROCESSING,
                        [styles.transaction__error]:
                          transaction.status === TRANSACTION_STATUS.FAIL,
                        [styles.transaction__waiting]:
                          transaction.status === TRANSACTION_STATUS.WAITING,
                      })}
                    >
                      {t(
                        `transactions.transactionStatuses.${transaction.status}`
                      )}
                    </div>
                    <div className={styles.transaction__time}>
                      {dayjs
                        .unix(transaction.unix)
                        .format(ACTIVE_TRANSACTION_FORMAT)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.item__actions}>
                <Actions
                  transaction={transaction}
                  isOpen={openAction === transaction.id}
                  onClickHandler={openActions}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
});
