import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "../InvestmentData.module.scss";
import { InfoBlock } from "../../../components/InfoBlock/InfoBlock";
import { Documents } from "../../../components/Documents/Documents";
import { ReactComponent as SandClockIcon } from "../../../icons/sand-clock-icon.svg";
import { ReactComponent as CellPhoneIcon } from "../../../icons/cil-mobile-icon.svg";
import { ReactComponent as IdIcon } from "../../../icons/id-card-icon.svg";
import { ReactComponent as PostidentIcon } from "../../../icons/postident-icon.svg";
import { StepsButtons } from "./StepsButtons";
import useStore from "../../../hooks/useStore";
import { getRequestId } from "../../../utils/localStorage";

export const PaymentDetails = observer(() => {
  const { t } = useTranslation();
  const {
    app: { markLoading },
    investment: {
      documents,
      confirmDocuments,
      getDocuments,
      activeTransaction,
    },
    me: { redirectUrl, kycStatus },
  } = useStore();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const investmentRequestId = getRequestId();

  const submit = () => {
    confirmDocuments({
      transactionId: investmentRequestId,
    }).then((redirectUrl) => {
      navigate(`/`);

      if (redirectUrl && kycStatus !== "SUCCESS") {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.target = "_blank";
        a.href = redirectUrl;
        a.click();
        document.body.removeChild(a);
      }
    });
  };

  const toPage5 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/5`);
  };

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await getDocuments({ transactionId: investmentRequestId });
      markLoading(false);
    };

    getData();
  }, []);

  const isPostIndentSuccess = kycStatus === "SUCCESS" && !redirectUrl;

  return (
    <div>
      <h4 className={styles.header}>
        {t("investment.paymentDetails.summaryOfYourInvestmentOffer")}
      </h4>

      <div className={cn(styles.dataRow, styles.margin24)}>
        <div className={styles.title}>{t("investment.investmentAmount")}</div>
        <div className={styles.value}>
          {activeTransaction?.investmentAmount}
        </div>
      </div>

      <div className={styles.dataRow}>
        <div className={styles.title}>
          {t("transactions.transaction.tokens")}
        </div>
        <div className={styles.value}>{activeTransaction?.amount}</div>
      </div>

      <InfoBlock
        title={t("investment.paymentDetails.thanksForTrust")}
        content={t("investment.paymentDetails.reviewingInvestment")}
        className={styles.margin24}
      />

      <div className={styles.margin24}>
        <Documents
          title={t("investment.paymentDetails.documents")}
          list={documents}
        />
      </div>
      {!isPostIndentSuccess && (
        <>
          <h4 className={styles.header}>
            {t("investment.paymentDetails.verification")}
          </h4>
          <div className={cn(styles.text, styles.margin24)}>
            {t("investment.paymentDetails.verifyBeforeInvest")}
          </div>
          <div className={cn(styles.iconText, styles.margin24)}>
            <div className={styles.iconText__icon}>
              <IdIcon />
            </div>
            <div className={styles.iconText__text}>
              {t("investment.paymentDetails.needDocumentId")}
            </div>
          </div>
          <div className={styles.iconText}>
            <div className={styles.iconText__icon}>
              <SandClockIcon />
            </div>
            <div className={styles.iconText__text}>
              {t("investment.paymentDetails.progressDuration")}
            </div>
          </div>
          <div className={styles.iconText}>
            <div className={styles.iconText__icon}>
              <CellPhoneIcon />
            </div>
            <div className={styles.iconText__text}>
              {t("investment.paymentDetails.recommendPostidentToUse")}
            </div>
          </div>
          <div className={styles.margin24}>
            <PostidentIcon />
          </div>
        </>
      )}
      <StepsButtons onNextClick={submit} onPrevClick={toPage5} />
    </div>
  );
});
