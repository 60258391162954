import { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useStore from "../../../hooks/useStore";
import styles from "../InvestmentData.module.scss";
import { Checkbox } from "../../../components/UI/Checkbox/Checkbox";
import { StepsButtons } from "./StepsButtons";
import { getRequestId } from "../../../utils/localStorage";

export const Summary = observer(({ setDisabledNext }) => {
  const { t } = useTranslation();
  const {
    app: { markLoading },
    investment: {
      getDocuments,
      documents,
      activeTransaction,
      steps: {
        confirmBasic,
        bondConditions,
        consumerInformation,
        costsAndAllowances,
        isPressedCashLink,
        updateField,
      },
    },
    tokens: { tokens },
  } = useStore();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const investmentRequestId = getRequestId();

  const toPage6 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/6`);
  };
  const toPage4 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/4`);
  };

  const tokenName = useMemo(
    () => tokens?.find((token) => token.id === +tokenId)?.name,
    [tokenId, tokens.length]
  );

  useEffect(() => {
    setDisabledNext(
      !bondConditions ||
        !consumerInformation ||
        !confirmBasic ||
        !costsAndAllowances ||
        !isPressedCashLink
    );
  }, [
    confirmBasic,
    bondConditions,
    consumerInformation,
    costsAndAllowances,
    isPressedCashLink,
  ]);

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await getDocuments({ transactionId: investmentRequestId });
      markLoading(false);
    };

    getData();
  }, []);

  return (
    <div>
      <h4 className={styles.header}>
        {t("investment.summary.summaryOfInvestmentOffer")}
      </h4>

      <p className={cn(styles.tokenName, styles.margin24)}>{tokenName}</p>
      <p className={styles.subText}>{t("investment.summary.tokenizedNote")}</p>

      <div className={styles.dataRow}>
        <div className={styles.title}>{t("investment.investmentAmount")}</div>
        <div className={styles.value}>
          {activeTransaction?.investmentAmount}
        </div>
      </div>
      <div className={styles.dataRow}>
        <div className={styles.title}>
          {t("transactions.transaction.tokens")}
        </div>
        <div className={styles.value}>{activeTransaction?.amount}</div>
      </div>

      <div className={cn(styles.text, styles.margin24)}>
        {t("investment.summary.confirmAndUnderstoodDocuments")}
      </div>
      <div className={styles.margin24}>
        <Checkbox
          mode="dark"
          initialState={confirmBasic}
          onChange={(value) => updateField("confirmBasic", value)}
          label={
            <>
              {t("investment.summary.riskAndBasicInformation.firstPart")}{" "}
              <a
                href={
                  documents?.find((i) => i.name === "Basisinformationsblatt")
                    ?.url
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t(
                  "investment.summary.riskAndBasicInformation.basicInformation"
                )}
              </a>{" "}
              {t("investment.summary.riskAndBasicInformation.and")}{" "}
              <a
                href={documents?.find((i) => i.name === "Risikohinweise")?.url}
                className={styles.underline}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(
                  "investment.summary.riskAndBasicInformation.riskInformation"
                )}
              </a>
              {t("investment.summary.riskAndBasicInformation.toHaveReceived")}
            </>
          }
        />
      </div>
      <div className={styles.margin24}>
        <Checkbox
          mode="dark"
          initialState={bondConditions}
          onChange={(value) => updateField("bondConditions", value)}
          label={
            <>
              {t(
                "investment.summary.withdrawalAndConsumerInformation.firstPart"
              )}{" "}
              <a
                href={
                  documents?.find((i) => i.name === "Genussscheinbedingungen")
                    ?.url
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t(
                  "investment.summary.withdrawalAndConsumerInformation.bondConditions"
                )}
              </a>{" "}
              <span>
                {t("investment.summary.withdrawalAndConsumerInformation.and")}{" "}
              </span>
              <a
                href={
                  documents?.find(
                    (i) =>
                      i.name ===
                      "Verbraucherinformationen inklusive Hinweise zum Widerrufsrecht"
                  )?.url
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t(
                  "investment.summary.withdrawalAndConsumerInformation.rightWithdraw"
                )}
              </a>
            </>
          }
        />
      </div>
      <div className={styles.margin24}>
        <Checkbox
          mode="dark"
          initialState={costsAndAllowances}
          onChange={(value) => updateField("costsAndAllowances", value)}
          label={
            <>
              {t("investment.summary.costsAndAllowances.firstPart")}{" "}
              <a
                href={
                  documents?.find((i) => i.name === "Kosten und Zuwendungen")
                    ?.url
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t("investment.summary.costsAndAllowances.file")}
              </a>
            </>
          }
        />
      </div>
      <div className={styles.margin24}>
        <Checkbox
          mode="dark"
          initialState={consumerInformation}
          onChange={(value) => updateField("consumerInformation", value)}
          label={
            <>
              {t("investment.summary.generalTermsAndConditions.firstPart")}{" "}
              <a
                href={
                  documents?.find(
                    (i) => i.name === "Effecta Verbrauchinformationen"
                  )?.url
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t(
                  "investment.summary.generalTermsAndConditions.consumerInformation"
                )}
              </a>{" "}
              <span>
                {t("investment.summary.generalTermsAndConditions.and")}{" "}
              </span>
              <a
                href={documents?.find((i) => i.name === "AGB Effecta")?.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.underline}
              >
                {t(
                  "investment.summary.generalTermsAndConditions.termsAndConditions"
                )}
              </a>{" "}
              <span>
                {t("investment.summary.generalTermsAndConditions.the")}
              </span>{" "}
              <span>
                <a
                  href={
                    documents?.find(
                      (i) => i.name === "Effecta Kundeninformationen"
                    )?.url
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.underline}
                >
                  {t("investment.summary.generalTermsAndConditions.effecta")}
                </a>{" "}
              </span>{" "}
              <span>
                {t("investment.summary.generalTermsAndConditions.lastPart")}
              </span>{" "}
            </>
          }
        />
        <div className={styles.margin24}>
          <Checkbox
            mode="dark"
            initialState={isPressedCashLink}
            onChange={(value) => updateField("isPressedCashLink", value)}
            label={
              <>
                {t("investment.summary.cashlink.firstPart")}
                <a
                  href={
                    documents?.find((i) => i.name === "Allgemeine Cashlink")
                      ?.url
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.underline}
                >
                  {t("investment.summary.cashlink.file")}
                </a>{" "}
                {t("investment.summary.cashlink.secondPart")}
              </>
            }
          />
        </div>
      </div>
      <StepsButtons
        onNextClick={toPage6}
        onPrevClick={toPage4}
        disabledNext={
          !bondConditions ||
          !consumerInformation ||
          !confirmBasic ||
          !costsAndAllowances ||
          !isPressedCashLink
        }
      />
    </div>
  );
});
