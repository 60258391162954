import cn from "classnames";
import styles from "./Radio.module.scss";

export const Radio = ({ value, label, onChange = () => {} }) => {
  const onChangeHandler = () => {
    onChange(!value);
  };

  return (
    <div className={styles.radio}>
      <div
        className={cn({
          [`${styles.radio__checkbox}`]: true,
          [`${styles.active}`]: !!value,
        })}
        onClick={onChangeHandler}
        role="presentation"
      >
        <input type="checkbox" checked={value} readOnly />
        <div
          className={cn({
            [`${styles.radio__ball}`]: true,
            [`${styles.active}`]: !!value,
          })}
        />
      </div>
      <div
        className={styles.radio__label}
        onClick={onChangeHandler}
        role="presentation"
      >
        {label}
      </div>
    </div>
  );
};
