import cn from "classnames";
import styles from "./Button.module.scss";

export const Button = ({
  children,
  className,
  width,
  style,
  isSubmit,
  disabled,
  maxWidth,
  isGray,
  outlined = false,
  ...rest
}) => {
  const getCls = () => {
    return cn({
      [`${styles.button}`]: true,
      [`${className}`]: !!className,
      [`${styles.gray}`]: isGray,
      [`${styles.outlined}`]: outlined,
    });
  };

  return (
    <button
      type={isSubmit ? "submit" : "button"}
      style={{ ...style, width, maxWidth }}
      disabled={disabled}
      className={getCls()}
      {...rest}
    >
      {children}
    </button>
  );
};
