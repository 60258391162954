import { useEffect } from "react";
import { getSnapshot } from "mobx-state-tree";

import useStore from "./useStore";

export const useResetKYC = () => {
  const {
    investment: {
      userExperience,
      steps: { updateField },
    },
  } = useStore();

  useEffect(
    () => () => {
      updateField("confirmDisposable", false);
      updateField("confirmTwiceAverage", false);
      updateField("tangany", false);
      updateField("kostenChecked", false);
      updateField("noInfo", false);
      updateField("confirmBasic", false);
      updateField("bondConditions", false);
      updateField("consumerInformation", false);
      updateField("costsAndAllowances", false);
      updateField("activeExperience", getSnapshot(userExperience));
      updateField("isPressedCashLink", false);
    },
    []
  );
};
