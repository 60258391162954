import { observer } from "mobx-react-lite";
import styles from "./TokenImageByURI.module.scss";
import useStore from "../../hooks/useStore";
import "external-svg-loader";

export const TokenImageByURI = observer(({ tokenId }) => {
  const {
    app: { icons },
  } = useStore();
  const uri = icons.find((icon) => icon.id === tokenId)?.svg;

  return (
    <div className={styles.image}>
      <svg data-src={uri} width="100%" height="100%" fill="currentColor" />
    </div>
  );
});
