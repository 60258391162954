import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { StatusKYC } from "./StatusKYC";
import { ReactComponent as IncompleteKYCIcon } from "../../icons/IncompleteKYC.svg";
import useStore from "../../hooks/useStore";

export const IncompleteKYC = observer(() => {
  const { t } = useTranslation();
  const {
    me: { redirectUrl },
  } = useStore();

  return (
    <StatusKYC
      text={[
        t("kyc.statuses.incomplete.text1"),
        t("kyc.statuses.incomplete.text2"),
      ]}
      header={t("kyc.statuses.incomplete.header")}
      icon={IncompleteKYCIcon}
      link={redirectUrl}
      linkText={t("kyc.statuses.link")}
    />
  );
});
