import { observer } from "mobx-react-lite";
import styles from "./Tokens.module.scss";
import { Token } from "./Token";

export const Tokens = observer(
  ({ renderHeader, getLink, onTokenClick = () => {}, tokens }) => {
    if (!tokens?.length) {
      return null;
    }
    return (
      <>
        <div className={styles.header}>{renderHeader()}</div>
        <div className={styles.tokens}>
          {tokens?.map((token) => {
            return (
              <Token
                key={token.id}
                token={token}
                getLink={getLink}
                onTokenClick={onTokenClick}
              />
            );
          })}
        </div>
      </>
    );
  }
);
