import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./Documents.module.scss";
import { ReactComponent as DocumentIcon } from "../../icons/document-icon.svg";

const Document = observer(({ item, activeItemId, onDotsClick }) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  return (
    <div className={styles.term}>
      <div className={styles.term__icon}>
        <DocumentIcon />
      </div>
      <div>
        {/* <p className={styles.term__header}>{t("settings.document")}</p> */}
        <p className={styles.term__name}>{item?.name}</p>
      </div>

      <span
        className={styles.term__dots}
        onClick={onDotsClick.bind(null, item)}
        ref={ref}
        data-type="modal"
        role="presentation"
      >
        <span />
        <span />
        <span />

        <div
          className={cn({
            [`${styles.actions}`]: true,
            [`${styles.open}`]: activeItemId === item.id,
          })}
        >
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {t("general.open")}
          </a>
        </div>
      </span>
    </div>
  );
});

export const Documents = observer(({ list, title, className }) => {
  const [activeItemId, setActiveItemId] = useState(undefined);

  const onDotsClick = (item) => {
    setActiveItemId(item.id);
  };

  useEffect(() => {
    const event = ({ target }) => {
      if (!target.closest("[data-type='modal']") || target.tagName === "A") {
        setActiveItemId(undefined);
      }
    };
    document.addEventListener("click", event);

    return () => {
      document.removeEventListener("click", event);
    };
  }, []);

  return (
    <div
      className={cn({
        [`${styles.documents}`]: true,
        [`${className}`]: !!className,
      })}
    >
      <div className={styles.documents__header}>{title}</div>
      <div className={styles.documents__list}>
        {list?.map((item) => (
          <Document
            item={item}
            key={item.id}
            onDotsClick={onDotsClick}
            activeItemId={activeItemId}
          />
        ))}
      </div>
    </div>
  );
});
