import React from "react";
import cn from "classnames";
import styles from "./Logo.module.scss";

export const Logo = ({ className }) => {
  return (
    <div className={cn(styles.logo, className)}>
      <div className={styles.logo__icon}>
        <img src={`${process.env.PUBLIC_URL}/blueToken.svg`} alt="logo" />
      </div>
      <span className={styles.logo__text}>refuture</span>
    </div>
  );
};
