import * as Yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

Yup.addMethod(Yup.array, "arrayHasSelected", function (errorMessage) {
  return this.test(`test-selected-in-array`, errorMessage, function () {
    const { path, createError, originalValue } = this;
    return (
      originalValue.find((i) => i.selected) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "onlyNumbers", function (errorMessage) {
  return this.test(`only-number`, errorMessage, function () {
    const { path, createError, originalValue } = this;
    return (
      /^\d+$/.test(originalValue) ||
      createError({ path, message: errorMessage })
    );
  });
});

export const useKYCSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const optionType = (field) =>
      Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string(),
            selected: Yup.boolean(),
          })
        )
        .arrayHasSelected(t("kyc.fieldIsRequired", { field }));

    return Yup.object().shape({
      idNumber: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.numberId") })
      ),
      birthDate: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.dateOfBirth") })
      ),
      issuingAuthority: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.issuingAuthority") })
      ),
      zipCode: Yup.string()
        .required(t("kyc.fieldIsRequired", { field: t("kyc.zipCode") }))
        .max(5, t("kyc.max5"))
        .onlyNumbers(t("errors.onlyNumbersCharacters")),
      countryOfBorn: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.countryOfBirth") })
      ),
      lastName: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.lastName") })
      ),
      isPolitician: Yup.boolean(),
      politicianSpecialization: Yup.string().when("isPolitician", {
        is: true,
        then: Yup.string().required(
          t("kyc.fieldIsRequired", { field: t("kyc.specify") })
        ),
      }),
      name: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.firstName") })
      ),
      appendix: Yup.string(),
      address: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.streetAndHouseNumber") })
      ),
      idType: optionType(t("kyc.typeId")),
      educationLevel: optionType(t("kyc.professionOrActivity")),
      employmentType: optionType(t("kyc.highestDegree")),
      salutation: optionType(t("kyc.gender")),
      citizenship: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.citizenship") })
      ),
      country: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.country") })
      ),
      idIssuedOn: Yup.string()
        .required(t("kyc.fieldIsRequired", { field: t("kyc.IDIssuedOn") }))
        .test("date test", t("general.wrongDate"), (value) => {
          return new Date().getTime() >= new Date(value).getTime();
        }),
      idValidUntil: Yup.string()
        .required(t("kyc.fieldIsRequired", { field: t("kyc.IDValidUntil") }))
        .test("date test", t("general.wrongDate"), (value) => {
          const dateNow = dayjs(dayjs().format("YYYY-MM-DD"));
          const selected = dayjs(value);
          return dateNow.diff(selected) < 10000;
        }),
      placeOfBirth: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.placeOfBirth") })
      ),
      city: Yup.string().required(
        t("kyc.fieldIsRequired", { field: t("kyc.city") })
      ),
    });
  }, []);
};
