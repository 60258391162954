/* eslint-disable no-underscore-dangle */
import {
  BOUNDS,
  EXPERIENCE_DURATION,
  EXPERIENCE_VALUE,
  GENDER,
  KIND_OF_ACTIVITY,
  PASSPORTS,
  PAYMENT_STATUS,
  STUDENT_LEVEL,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from "../../store/consts";

const translation = {
  general: {
    invest: "Investieren",
    open: "Öffnen",
    sell: "Verkaufen",
    delete: "Löschen",
    edit: "Bearbeiten",
    saveChanges: "Änderungen speichern",
    next: "Fortfahren",
    ok: "Ok",
    copiedToClipboard: "In die Zwischenablage kopiert",
    abort: "Abbrechen",
    yes: "Ja",
    no: "Nein",
    save: "Speichern",
    billing: "Abbrechen",
    update: "Aktualisieren",
    changeDetails: "Angaben ändern",
    return: "Zurück",
    download: "Laden",
    done: "Bestätigen",
    more: "Weiter",
    error: "Fehler!",
    wrongDate: "Falsches Datum",
  },
  risk: {
    riskNotice: "RISIKOHINWEIS:",
    riskDescription: `Der Erwerb unserer Wertpapiere ist mit erheblichen Risiken 
      verbunden und kann zum vollständigen Verlust des eingesetzten Kapitals führen.`,
  },
  messanges: {
    success: "Erfolgreich!",
    dataUploaded: "Daten hochgeladen.",
  },
  login: {
    login: "Anmeldung",
    loginHeader: "Anmelden",
    pleaseSignInToYourAccount: "Bitte anmelden um fortzufahren",
    email: "E-Mail",
    password: "Passwort",
    forgotPassword: "Passwort vergessen?",
    signIn: "Einloggen",
    dontHaveAnAccount: "Sie haben noch keinen Account?",
    areYouRegistered: "Sie sind bereits registriert?",
    signUp: "Registrieren",
    verifyItsYou: "Bestätigen Sie, dass Sie es sind",
    confirmTheEmail:
      "Wir haben Ihnen eine E-Mail mit einem Bestätigungscode gesendet. Bitte geben Sie hier den Code ein.",
    verificationCode: "Bitte tragen Sie den Code ein",
    didNotGotTheCode: "Code nicht erhalten?",
    resend: "Code erneut senden",
    letsStart: "Registrieren",
    pleaseEnterYourCredentials:
      "Bitte geben Sie Ihre Zugangsdaten ein, um fortzufahren",
    confirmPass: "Passwort bestätigen",
    resetPassword: "Passwort zurücksetzen",
    enterYourEmail:
      "Geben Sie Ihre E-Mail an. Im Anschluss senden wir Ihnen einen 5-stelligen Authentifizierungscode",
    enterFiveDigitsCode:
      "Bestätigen Sie Ihre E-Mail mit dem Authentifizierungscode",
    createNewPassword: "Neues Passwort erstellen",
    comeUpWithNewIdiaForYourPassword:
      "Überlegen Sie sich eine neue Idee für Ihr Passwort",
    errors: {
      minLength: "mind. {{count}} Zeichen",
      capitalLetter: "Großbuchstabe",
      lowerCase: "Kleinbuchstabe",
      number: " eine Zahl",
      theCodeIsntCorrect: "Der Code ist nicht korrekt",
      wrongPassword: "Passwort ist nicht korrekt. Versuchen Sie es nochmal!",
      enterCorrectEmail: "Ungültige E-Mail-Adresse",
      passwordMustMatch: "Passwort muss übereinstimmen.",
      wrongPasswordOrEmail: "Die E-Mail Adresse oder das Passwort ist falsch",
      userDoesNotExists:
        "Die von Ihnen angegebene E-Mail existiert nicht in unserem System",
      emailIsRequired: "E-mail erforderlich",
      passwordIsRequired: "Passwort erforderlich",
    },
    privacy: {
      iAgreeToThe: "Ich stimme den",
      termsOfService: "Nutzungsbedingungen",
      and: "und den",
      privacyPolicy: "Datenschutzrichtlinien ",
    },
  },
  dates: {
    monthShort: "mths",
    years: "Jahre",
    month: {
      0: "Januar",
      1: "Februar",
      2: "März",
      3: "April",
      4: "Mai",
      5: "Juni",
      6: "Juli",
      7: "August",
      8: "September",
      9: "Oktober",
      10: "November",
      11: "Dezember",
    },
  },
  dropdown: {
    kyc: {
      studentLevel: {
        [STUDENT_LEVEL.KEINE]: "keine Angabe",
        [STUDENT_LEVEL.HIGH_SCHOOL_DIPLOMA]: "Abitur/Fachabitur",
        [STUDENT_LEVEL.TEACHING_VOCATIONAL_TRAINING]: "Lehre-/Berufsausbildung",
        [STUDENT_LEVEL.REAL_SCHOOL_QUALIFICATION]: "Realschulabschluss",
        [STUDENT_LEVEL.COMPLETED]: "Abgeschlossenes Hochschulstudium",
      },
      employmentType: {
        [KIND_OF_ACTIVITY.KEINE]: "keine Angabe",
        [KIND_OF_ACTIVITY.STUDENT_IN]: "Student/in",
        [KIND_OF_ACTIVITY.UNEMPLOYED]: "Arbeitslos",
        [KIND_OF_ACTIVITY.SELF_EMPLOYED]: "Selbständig",
        [KIND_OF_ACTIVITY.EMPLOYEE]: "Angestellte/r",
        [KIND_OF_ACTIVITY.OTHER]: "Sonstige",
      },
      passportTypes: {
        [PASSPORTS.ID_PASS]: "Personalausweis",
        [PASSPORTS.PASSPORT]: "Reisepass",
      },
    },
    noOptions: "Keine optionen",
    select: "Auswählen...",
  },
  mainNav: {
    home: "Home",
    wallet: "Wallet",
    tokens: "Meine Tokens",
    transactions: "Transaktionen",
    contracts: "Verträge",
    settings: "Einstellungen",
    invest: "Investition",
  },
  home: {
    welcome: "Hello, {{name}}",
    hereYouCanManageYourInvestments:
      "Hier können Sie Ihre Investitionen verwalten",
    search: "Suche etwas...",
    toUnlockTheAppFeaturesMakeYourFirstInvestment: "Investieren mit Zukunft",
  },
  tokens: {
    tokens: "Tokens",
    seeAll: "Alles sehen",
    myTokens: "Meine Token",
    analytics: "Übersicht",
    noTokens: "Sie haben keine Tokens",
    noTokensText: `Sie sich über unsere Angebote auf unserer Website oder direkt hier
       in der App und fügen Sie Ihrem Portfolio Tokens exklusiver Token Highlights hinzu.`,
    token: {
      location: "Standort:",
      tokenStatistics: "Verfügbare Tokens:",
      availableTokens: "Gesamtanzahl:",
      soldOut: "Ausverkauft",
      price: "Preis pro Token:",
      properties: "Properties:",
      propertyHighlights: "Token Highlights",
      percentageFounded: "gegründet",
      amountAvailable: "verfügbar",
      interestRate: "Zinssatz:",
      duration: "Dauer:",
      balance: "Investmentdaten",
      expectedYield: "Erwartete Rendite:",
      rentPerToken: "Rendite pro Token:",
      yourInvestment: "Ihr Investment:",
      calculateProfit: "Tokenanzahl auswählen",
      amount: "Hier die Anzahl Ihrer Tokens auswählen",
      tokenPrice: "Preis pro Token:",
      totalTokens: "Gesamte Tokenanzahl:",
      userTokens: "Tokens:",
      invest: "Investieren",
      yourTokens: "Ihre Tokens",
      myTokens: "Meine Tokens",
      myInvestment: "Meine Investition",
      yours: "Ihr Anteil",
      amountError: `Die sich aus der Anzahl der Token und dessen Mindestpreis ergebende
         Investmentsumme muss zwischen {{minimumInvestment}} € und {{maximumInvestment}} € liegen.`,
      funded: "finanziert",
      sellingTokensIsNotUnavailableNow:
        "Der Verkauf von Tokens ist derzeit nicht verfügbar",
      productType: "Produkttyp: ",
      infoCard: {
        available: "Verfügbar",
        equivalent: "Äquivalent",
        interestRate: "Rendite",
        received: "Empfangen",
      },
      errorMinAmount:
        "Bitte geben Sie eine Anzahl an Tokens ein, dessen Wert der Mindestanlagesumme gleich ist oder überschreitet",
    },
  },
  properties: {
    livingArea: "Wohnbereich:",
    property: {
      property: "Token Highlights",
      type: "Art der Token Highlights",
      constructionYear: "Baujahr:",
      rented: "Gemietet:",
      totalLivingArea: "Gesamtfläche:",
      about: "Über",
      totalUnits: "Einheiten:",
      bathroomOrBath: "Badezimmer:",
      tokenName: "Token",
      propertyName: "Token Highlights {{propertyName}}",
    },
  },
  transactions: {
    activeTransactionsEmptyHeader: "Ihr Transaktionsverlauf ist leer!",
    activeTransactionsEmptyText:
      " Sie können alle zuküngftig getätigten Transaktionen hier einsehen.",
    recentTransactions: "Kürzliche Transaktionen",
    seeAll: "Alles sehen",
    searchTransaction: "Transaktion suchen",
    month: "alle",
    yourTransactionHistoryIsEmpty: "Ihr Transaktionsverlauf ist leer!",
    investAndDiveIntoTokensWorld:
      "Sie können alle zukūnftig getätigten Transaktionen hier einsehen.",
    tokens: "Tokens",
    editInvestment: "Investment bearbeiten",
    activeTransactions: "Aktive Transaktionen",
    cancelModalHeader: "Sind Sie sicher?",
    cancelModalText: "Wollen Sie den Investment-Prozess wirklich abbrechen?",
    clickToBuyFirstToken:
      "Klicken Sie auf die Schaltfläche unten, um Ihre ersten Token zu kaufen",
    paymentStatus: {
      [PAYMENT_STATUS.SUCCESS]: "Investition genehmigt",
      [PAYMENT_STATUS.PENDING]: "Zahlung ausstehend",
      [PAYMENT_STATUS.NOT_ENOUGH]: "Tokens ausverkauft",
      [PAYMENT_STATUS.DENIED]: "Zahlung abgelehnt",
    },
    transactionTable: {
      date: "Datum",
      time: "Zeit",
      amount: "Menge",
      transactionType: "Investitionsprodukt",
      transactionStatus: "Status",
    },
    transactionTypes: {
      [TRANSACTION_TYPE.CANCELED_INVESTING]: "Investmentprozess abgebrochen",
      [TRANSACTION_TYPE.INVESTING]: "Investiert",
      [TRANSACTION_TYPE.TOP_UP]: "Aufstocken",
      [TRANSACTION_TYPE.WITHDRAW]: "Zurückziehen",
    },
    transactionStatuses: {
      [TRANSACTION_STATUS.FAIL]: "Abgelehnt",
      [TRANSACTION_STATUS.PROCESSING]: "in Bearbeitung",
      [TRANSACTION_STATUS.SUCCESS]: "Erfolgreich",
    },
    transaction: {
      documents: "Dokumente",
      iban: "IBAN",
      accountHolder: "Kontoinhaber",
      bic: "BIC",
      referenceText: "Referenztext",
      keyData: "Investitionsdaten",
      myInvestment: "Meine Investition",
      paymentDetails: "Zahlungsdetails",
      investmentAmount: "Anlagebetrag",
      investedAmount: "Investierter Betrag",
      payableAmount: "Zu zahlender Betrag",
      tokens: "Tokens",
      compaign: "Produkt",
      investedOn: "Investiert weiter",
      transferAmountText: `
        Wir haben Ihre Investition angenommen.
        Überweisen Sie bitte den oben stehenden Betrag unter
        Angabe des Referenztextes innerhalb von 14 Tagen auf das Bankkonto des Emittenten. 
      `,
      weWillNotifyText: `
        Sobald Ihre Einzahlung bei uns eingetroffen ist, werden wir Sie per E-Mail benachrichtigen.
        Den aktuellen Status Ihrer Investition können Sie auch immer in Ihrem Dashboard einsehen.
      `,
    },
  },
  balanceCard: {
    availableBalance: "Verfügbares Guthaben",
    spend: "Spend",
    received: "Erhalten",
  },
  pagination: {
    next: "{{divider}}",
    prev: "{{divider}}",
  },
  noConnection: {
    noInternetConnection: "Keine Internetverbindung",
    pleaseCheckYourConnection: "Bitte überprüfen Sie Ihre Internetvebindung",
    tryAgain: "Erneut versuchen",
  },
  genderOptions: {
    [GENDER.MALE]: "Herr",
    [GENDER.FEMALE]: "Frau",
    [GENDER.OTHER]: "Divers",
  },
  settings: {
    logout: "Ausloggen",
    accountSettings: "Account Einstellungen",
    saveChanges: "Änderungen speichern",
    name: "Name",
    lastName: "Nachname",
    plz: "PLZ",
    email: "E-Mail",
    tokenId: "Token ID",
    street: "Str. und Hausnummer",
    buildingNumber: "Adresszusatz",
    city: "Stadt",
    country: "Land",
    currentPassword: "Altes Passwort",
    twoFactorAuthentication: "Zwei-Faktor-Authentifizierung",
    confirmNewPassword: "Neues Passwort bestätigen",
    newPassword: "Neues Passwort",
    termsAndConditions: "Bedingungen und Service",
    document: "DOKUMENTIEREN:",
    gender: "Anrede",
    password: {
      minLength: "Mindestlänge 8 Symbole",
      maxLength: "Maximal 24 Symbole",
      capitalLetter: "Passwort muss mindestens ein Großbuchstaben enthalten",
      lowerCase: "Passwort muss mindestens einen Kleinbuchstaben enthalten",
      number: "Passwort muss mindestens eine Zahl enthalten",
      passwordMustMatch: "Passwort muss übereinstimmen",
    },
  },
  wallet: {
    topUp: "Aufstockung",
    withdraw: "Zurückziehen",
    accountAndSendMoney: "Konto erstellen und Geld senden",
    iban: "IBAN",
    bic: "BIC",
    name: "Name",
    cardNumber: "Kartennummer",
    recipient: "Begünstigter",
    amount: "Menge",
  },
  investment: {
    investmentData: "Investitionsdaten",
    investToToken: "Investitiosdaten zum",
    investmentAmount: "Investitionsbetrag",
    shares: "Anteile:",
    sharePrice: "Aktienkurs:",
    total: "Gesamt:",
    investment: "Anlage:",
    knowledgeAndExperience: {
      experienceConstants: {
        [EXPERIENCE_VALUE.NO_EXP]: "Keine",
        [EXPERIENCE_VALUE.LESS_1K]: "Bis 1.000,00 Euro",
        [EXPERIENCE_VALUE.LESS_3K]: "Bis 3.000,00 Euro",
        [EXPERIENCE_VALUE._1K_3K]: "1.000,00-3.000,00 Euro",
        [EXPERIENCE_VALUE._3K_5K]: "3.000,00-5.000,00 Euro",
        [EXPERIENCE_VALUE._5K_10K]: "5.000,00-10.000,00 Euro",
        [EXPERIENCE_VALUE._10K_25K]: "10.000,00-25.000,00 Euro",
        [EXPERIENCE_VALUE._25K_100K]: "25.000,00-100.000,00 Euro",
        [EXPERIENCE_VALUE.MORE_10K]: "Mehr als 10.000,00 Euro",
        [EXPERIENCE_VALUE.MORE_100K]: "Mehr als 100.000,00 Euro",
      },
      durationConstants: {
        [EXPERIENCE_DURATION.NO_EXP]: "Keine Angabe",
        [EXPERIENCE_DURATION.LESS_1_YEAR]: "Bis 1 Jahr",
        [EXPERIENCE_DURATION.LESS_3_YEAR]: "Bis 3 Jahre",
        [EXPERIENCE_DURATION.LESS_10_YEAR]: "Bis 10 Jahre",
        [EXPERIENCE_DURATION._3_5_YEARS]: "3-5 Jahre",
        [EXPERIENCE_DURATION.MORE_5_YEARS]: "Mehr als 5 Jahre",
        [EXPERIENCE_DURATION.MORE_10_YEARS]: "Mehr als 10 Jahre",
      },
      bondsConstants: {
        [BOUNDS.NO_EXP]: "Keine",
        [BOUNDS.LESS_5]: "Bis 5",
        [BOUNDS._5_10]: "Bis 10",
        [BOUNDS.MORE_10]: "Mehr als 10",
      },
      investmentFunds: "Investmentfonds",
      knowledgeAndExperience: "Kenntnisse und Erfahrungen",
      iDontWantToGiveAnyInformation: "Ich möchte keine Angaben machen.",
      provideInformation1: `
        Gemäß Wertpapierhandelsgesetz (§ 63 Abs. 5 WpHG) sind wir verpflichtet, Kenntnisse und
        Erfahrungen bezüglich verschiedener Produktklassen wie Wertpapieren, Vermögensanlagen und sonstigen
        Kapitalmarktprodukten abzufragen.
      `,
      provideInformation2: `
        Mittels der Antworten können wir für Sie prüfen,
        ob die Zeichnung dieser Kapitalanlage für Sie als Investor geeignet ist. 
        Die Beantwortung der Fragen ist freiwillig.
        Machen Sie keine oder unvollständige Angaben, können wir die Angemessenheit
        des jeweiligen Kapitalmarktproduktes nicht für Sie prüfen. Daher sollten Sie aus eigenem Interesse
        Angaben machen.
        `,
      experienceWithCapitalMarketProduct:
        "Erfahrungen mit Kapitalmarktprodukten",
      experienceWithSecuritiesServices:
        "Erfahrungen mit Wertpapierdienstleistung",
      frequencyAndVolumeOfTransaction:
        "Häufigkeit und Volumen Ihrer Transaktionen",
      product: "Produkt",
      knowledge: "Kenntnisse",
      experiences: "Erfahrungen",
      bonds: "Anleihen",
      moneyMarketFunds: "Geldmarktfonds",
      profitParticipationRights: "Genussrechte",
      subordinated: "Nachrang-/Part. Darlehen",
      virtualCurrencies: "Virtuelle Währungen",
      howMuchExperienceInvestingInSecuritiesAdvice:
        "Wie viel Erfahrung haben Sie mit der Investition in Wertpapiere über eine Anlageberatung?",
      experienceViaInvestmentAdvice:
        "Wie viel Erfahrung haben Sie mit der Investition in Wertpapiere über eine Vermögensverwaltung?",
      howMuchExperienceInvestingInSecuritiesAdviceFree: ` Wie viel Erfahrung haben Sie mit der
        eigenständigen, beratungsfreien Investition in
        Wertpapiere?`,
      howManyInvestmentPerYear: `Geben Sie die Anzahl der Wertpapiertransaktionen an, 
        die Sie durchschnittlich im Jahr mit einer Wertpapierdienstleistung tätigen.`,
      averageValueOfTransaction: `
          Geben Sie den Gegenwert der Wertpapiertransaktionen an, 
          die Sie durchschnittlich im Jahr mit einer Wertpapierdienstleistung tätigen.
        `,
      knowledgeAndExperienceRiskFactor: `
        Appropriateness is assessed according to whether you as an investor have the necessary knowledge and experience
        to be able to adequately assess the risks associated with the type of financial instruments 
        or investment services.
        In the documents you sent back, 
        you provided incomplete or essentially no information about your 
        previous experience and knowledge in connection with securities transactions.
        As a result, we are unable to fulfill our duty to review the adequacy of the investment.
        We therefore urgently point out that an adequacy check is not possible 
        in the case of missing/incomplete information. 
        You must therefore decide for yourself whether the securities are appropriate for you. In particular,
        you should familiarize yourself with the risks and circumstances of the securities.
        A detailed description of the risks associated with the subscription can be found in the "Risk Factors"
        section of the key information document relevant to the respective offer. If you still want to 
        refrain from a drawing, you can exercise your 14-day right of withdrawal.
      `,

      appropriatenessCheckNotPossible: "Prüfung Angemessenheit nicht möglich",
      appropriatenessCheckNotPossibleDescription1: `
        Die Angemessenheit beurteilt sich danach, ob Sie als Anleger/-in über die erforderlichen Kenntnisse
        und Erfahrungen
        verfügen, um die Risiken im Zusammenhang mit der Art der Finanzinstrumente oder Wertpapierdienstleistungen
        angemessen beurteilen zu können.
      `,
      appropriatenessCheckNotPossibleDescription2: `
        In den von Ihnen zurückgesendeten Unterlagen haben Sie unvollständige oder im Wesentlichen keine Angaben zu 
        Ihren
        bisherigen Erfahrungen und Kenntnissen im Zusammenhang mit Wertpapiergeschäften gemacht. 
        Dadurch sind wir nicht in
        der Lage unserer Pflicht der Prüfung der Angemessenheit der Anlage nachzukommen.
      `,
      appropriatenessCheckNotPossibleDescription3: `
        Wir weisen Sie daher eindringlich darauf hin, dass eine Angemessenheitsprüfung bei 
        fehlenden/unvollständigen Angaben
        nicht möglich ist. Sie müssen daher selbst entscheiden, ob die Wertpapiere für Sie angemessen sind. 
        Insbesondere sollten
        Sie sich mit den Risiken und Gegebenheiten der Wertpapiere vertraut machen. Eine ausführliche 
        Darstellung der mit der
        Zeichnung verbundenen Risiken befindet sich im Abschnitt „Risikofaktoren” des für das 
        jeweilige Angebot maßgeblichen Prospektes.
      `,

      confirmationInappropriateness: "Bestätigung Unangemessenheit",
      confirmationInappropriatenessDescription1: `
        Die Auswertung der von Ihnen gemachten Angaben zu bisherigen Kenntnissen und Erfahrungen bei
        Wertpapieren, Vermögensanlagen, Kapitalmarktprodukten ergab, dass das hier angebotene Produkt
        bzw. die Höhe Ihrer Zeichnung nicht dem auf Basis Ihrer Angaben erstellten Anlageprofil entspricht.
      `,
      confirmationInappropriatenessDescription2: `
        Gemäß Ihrer Angaben verfügen Sie über zu wenig Kenntnisse und Erfahrungen, um die mit dem
        Erwerb des hier angebotenen Produktes verbundenen Risiken ausreichend beurteilen zu können, d. h.
        Ihre Zeichnung entspricht nicht Ihrem bisherigen Anlageprofil.
      `,
      confirmationInappropriatenessDescription3: `
        Bitte informieren Sie sich noch einmal in dem Ihnen übergebenen Verkaufsprospekt bzw. ersatzweise
        den sonstigen Unterlagen über die Ausgestaltung und Risikostruktur des Produktes. Natürlich steht
        Ihnen Ihr gerne nochmals für ein Gespräch zu Verfügung. 
      `,
      confirmationInappropriatenessDescription4: `
        Wenn Sie sich entscheiden, das Produkt dennoch zu zeichnen, bitten wir Sie, dies rechtskräftig zu bestätigen:
        Ich bin darüber informiert, dass die Zeichnung der Kapitalanlage nicht meinem Anlageprofil auf
        Grund der von mir angegebenen Kenntnisse und bisherigen Erfahrungen bei Wertpapieren, Vermögensanlagen
        und sonstigen Kapitalmarktprodukten entspricht. Ich entscheide mich dennoch
        für oben genannte Zeichnung folgender Kapitalanlage mit oben genannten Betrag.
      `,
    },
    wallet: {
      wallet: "Wallet",
      safekeepingOfTheDigitalSecurities: "Verwahrung der digitalen Wertpapiere",
      tanganyLtd: "Tangany GmbH",
      tanganyTokenInformation: `
        Für die sichere Verwahrung Ihrer REFuture Tokens benötigen Sie ein Wallet. 
        Ein Wallet ist ein digitales Schließfach, ähnlich einem Depotkonto,
        auf das Sie jederzeit über unser Dashboard zugreifen können.
        Das Wallet stellen wir Ihnen kostenlos über unseren Partner, der Tangany GmbH aus München, zur Verfügung.
      `,
      termsAndConditions1: "Ich habe die AGB der ",
      termsAndConditions2: "Tangany GmbH",
      termsAndConditions3: `gelesen und stimme diesen auch zu. Ich beauftrage REFuture
          ein kostenloses Wallet bei der Tangany GmbH für mich zu eröffnen.`,
    },

    summary: {
      summary: "Zusammenfassung",
      tokenizedNote: "Tokenisierte Schuldverschreibung",
      summaryOfInvestmentOffer: "Zusammenfassung",
      confirmAndUnderstoodDocuments: `Hiermit bestätige ich, folgende Unterlagen rechtzeitig 
        vor Abgabe dieser Zeichnungserklärung entweder in
        elektronischer Form zugesandt bekommen, heruntergeladen oder ausgedruckt habe und diese gelesen und
        verstanden habe:`,
      riskAndBasicInformation: {
        firstPart: "Hiermit bestätige ich, das ",
        riskInformation: "Risikohinweise",
        toHaveReceived: " erhalten zu haben.",
        basicInformation: "Basisinformationsblatt",
        and: "und die",
      },
      withdrawalAndConsumerInformation: {
        firstPart: "Hiermit bestätige ich, die",
        bondConditions: "Genussscheinbedingungen",
        and: "sowie die",
        rightWithdraw:
          "Verbraucherinformationen inklusive Hinweise zum Widerrufsrecht",
      },
      all:
        "I hereby take note of the consumer information and the General Terms and Conditions of Effecta GmbH\n" +
        "agree to this.",
      generalTermsAndConditions: {
        firstPart: "Hiermit nehme ich die",
        consumerInformation: "Effecta Verbraucherinformationen,",
        and: "die",
        termsAndConditions: "AGB Effecta ",
        the: "sowie die",
        effecta: "Effecta Kundeninformationen",
        lastPart: "zur Kenntnis und stimme diesen zu.",
      },
      costsAndAllowances: {
        firstPart: "Hiermit bestätige und akzeptiere ich die",
        file: "Kosten und Zuwendungen.",
      },
      cashlink: {
        firstPart: "Hiermit nehme ich die ",
        file: "Allgemeine Nutzungsbedingungen & Datenschutzerklärung der Cashlink Technologies GmbH",
        secondPart: "zur Kenntnis und stimme diesen zu.",
      },
    },
    paymentDetails: {
      paymentDetails: "Zahlungsdetails",
      summaryOfYourInvestmentOffer:
        "Zusammenfassung Ihres Investitionsangebotes",
      thanksForTrust: "Vielen Dank für Ihr Vertrauen.",
      documents: "Dokumente",
      verification: "Verifikation",
      toThePostident: "Zur Postident",
      investToPay: "Zahlungspflichtig investieren",
      confirm: "Bestätigen",
      reviewingInvestment: `
        Wir prüfen nun Ihre Investition.
        Innerhalb der nächsten Tage erhalten Sie eine E-Mail mit einem Link zu den Zahlungsdetails.
        Den Status der Investition können Sie unter Transaktionen in Ihrem Dashboard einsehen.
      `,
      verifyBeforeInvest: `Bevor Sie bei uns investieren,
       müssen Sie sich einmalig über Postident verifizieren. Dort wird Ihre Identität über einen Video Chat bestätigt.`,
      needDocumentId: `Sie benötigen das Ausweisdokument, welches Sie in Schritt 4 (Ausweisdaten) angegeben haben`,
      progressDuration: `Der Vorgang dauert etwa 5 Minuten. Sie erhalten eine Mail,
       sobald Sie erfolgreich bei uns verifiziert sind`,
      recommendPostidentToUse: `Wir nutzen die postident App der deutschen Post zur Legitimation`,
    },
    overviewOfCostsAndGrants: {
      stepTitle: "Übersicht der Kosten und Zuwendungen",
      issueCostForTextText: `
        Im Rahmen dieser Emission entstehen Kosten für den Vertrieb, Marketing und die rechtliche Strukturierung
        des Produktes. Wir sind nach § 63 Abs. 7 WpHG gesetzlich verpflichtet, Ihnen diese Kosten in Form einer
        Tabelle transparent darzustellen.
      `,
      nominalCapitalAmount: "Nominalkapitalbetrag",
      monetaryBenefits: "Monetäre Zuwendungen",
      relationToTheIssueAmount: "({{value}} in Bezug auf den Ausgabebetrag)",
      ofWhichForwardedToContractuallyBoundAgents:
        "Davon weitergeleitet an vertraglich gebundenen Vermittler",
      natureOfFeesAndCharges: "Art der Gebühren und Kosten",
      oneTimeCosts: "Einmalkosten",
      percentOfSpecification: "%-Angabe",
      runningCostsPerYear: "Laufende Kosten pro Jahr",
      mediationCosts: "Kosten der Vermittlung",
      ofWhichGrants: "Davon Zuwendungen",
      totalFinancialCost:
        "Kosten des Finanzinstrumentes (Transaktionskosten / Depotkosten)",
      completely: "Komplett",
      duration: "Laufzeit",
      firstYearReturn: "Kosten reduzieren die Rendite im ersten Jahr",
      followingYearReturns:
        "Kosten reduzieren die Rendite in den folgenden Jahren",
      transactionCost: "Gesamtkosten des Finanzinstrumentes",
      totalCost: "Gesamtkosten",
      confirmCostsAndAllowancesDocument:
        "Hiermit bestätige und akzeptiere ich die",
      costsAndAllowances: "Kosten und Zuwendungen.",
    },
    amount: "Betrag",
    chooseTheAmountToInvest: `Wählen Sie den Betrag aus, den Sie investieren möchten.`,
    selfDisclosure: "Selbstauskunft",
    investmentTerms1: `
      Bei dieser Kapitalanlage ist die Höhe der Investitionssumme beschränkt und an bestimmte
    `,
    investmentTerms2: `
      Voraussetzungen gebunden. Das Wertpapierprospektgesetz schreibt in diesem Fall vor, Ihre
      Selbstauskunft einzuholen.
    `,
    investmentTerms3: `
      Eine Investition von  {{value}} € (inklusive frühere Investitionen in diesem Token)
      ist nur dann möglich, wenn Sie zum Zeitpunkt
      der Zeichnung mindestens eine der beiden folgenden Aussagen bestätigen können:
    `,
    investmentTerms4: `
      Eine Investition von {{value}} €
      (inklusive früherer Investitionen in diesen Token) ist nur dann
      möglich, wenn Sie zum Zeitpunkt der Zeichnung die folgende Aussage
      bestätigen können:
    `,
    confirmTwiceAverageIncome: `Ich bestätige, dass das Volumen aller Investitionen in diesen
     Token das Zweifache meines durchschnittlichen monatlichen Netto-Einkommens nicht übersteigt.`,
    confirmDisposableAssets: `Ich bestätige, dass ich über ein frei verfügbares Vermögen in Form von Bankguthaben und
      Finanzinstrumenten von mindestens 100.000,00 € verfüge.`,
  },
  personalDetails: {
    personalDetails: "Persönliche Informationen",
    wouldYouLikeToChangePersonalDetails: `Haben sich Ihre persönlichen Daten geändert oder möchten 
      Sie die zuvor eingegebenen Daten weiterhin verwenden?`,
    useMySavedData: "Fortfahren",
    iWantToChangeIt: "Daten ändern",
  },
  errors: {
    somethingWentWrong: "Etwas ist schief gelaufen...",
    onlyNumbersCharacters: "Erfordert numerische Zeichen",
    wrongCredentials: "Falsches Passwort oder E-Mail",
    emailInUse:
      "Die von Ihnen eingegebene E-Mail Adresse ist bereits registriert",
  },
  kyc: {
    fieldIsRequired: "{{field}} erforderlich",
    congrats: "Herzlichen Glückwunsch!",
    start: "Start",
    confirmationIdentity: "Identität bestätigen",
    prepareDocuments: `Geben Sie alle relevanten Daten an, um Ihr erstes Investment tätigen zu können. 
      Bereiten Sie Ihre Dokumente vor und befolgen Sie den KYC-Prozess`,
    completedKYC:
      "Sie haben KYC abgeschlossen, wir senden Ihnen eine E-Mail, wenn Ihr Gesicht identifiziert wurde",
    personalInformation: "Persönliche Informationen",
    name: "Vorname",
    lastName: "Nachname",
    firstName: "Vorname",
    city: "Ort",
    countryOfBirth: "Geburtsland",
    specifyProfessionOrOccupation: "Spezifizieren Beruf bzw. Tätigkeit",
    street: "Stadt",
    apartmentNumber: "Hausnummer",
    zipCode: "Postleitzahl",
    max5: "max 5 Zeichen",
    nextBtn: "Weiter",
    personalData: "Pärsonalishe Daten",
    addressData: "Adressdaten",
    highestDegree: "Höchster Bildungsabschluss",
    citizenship: "Staatsangehörigkeit",
    dateOfBirth: "Geburtsdatum",
    gender: "Andere",
    placeOfBirth: "Geburtsort",
    country: "Land",
    streetAndHouseNumber: "Str. und Hausnummer",
    addressSupplement: "Adresszusatz",
    professionOrActivity: "Beruf bzw. Tätigkeit",
    identificationData: "Ausweisdaten",
    location: "Ort",
    PEPPolicy: `
      Sind Sie eine politisch exponierte Person (PEP), 
      ein unmittelbares Familiemitglied einer politisch exponierten 
      Person oder bekanntermaßen eine nahestehende Person einer politisch exponierten Person?
    `,
    PEPExplanation:
      "(Politisch exponierte Person nach § 1 Abs 12 GwG ist jede Person," +
      " die ein hochrangiges wichtiges öffentliches Amt auf internationaler," +
      " europäischer oder nationaler Ebene ausübt oder ausgeübt hat oder" +
      " ein öffentliches Amt unterhalb der nationalen Ebene, dessen politische" +
      " Bedeutung vergleichbar ist, ausübt oder ausgeübt hat.)",
    typeId: "Ausweisart",
    specify: "Spezifizieren",
    numberId: "Ausweisnummer",
    issuingAuthority: "Ausstellende Behörde",
    IDIssuedOn: "Ausweis augestellt am",
    IDValidUntil: "Ausweis gültig bis",
    statusQuery: "Politisch exponierte Person (PEP)",
    beneficialOwners: "Wirtschaftlich Berechtigte",
    customerInformationEffecta: "Kundeninformation Effecta",
    forTheSafekeepingOfYoursGreenTechATokens:
      "Für die sichere Verwahrung Deiner GreenTech A Token benötigst",
    politicallyExposedPersonConfirm: `
      Sind Sie eine politisch exponierte Person (PEP), 
      ein unmittelbares Familiemitglied einer politisch exponierten Person oder bekanntermaßen eine nahestehende 
      Person einer politisch exponierten Person?
    `,
    myAccountIsOwnInterest: `
      Ich versichere, auf eigene Rechnung und im eigenen wirtschaftlichen Interesse zu handeln.
    `,
    effecta: {
      part1: "Ich habe die",
      link: "Kundeninformation Effecta",
      part2: "und",
      link2: "AGB",
      part3: "gelesen und stimme diesen zu.",
    },
    statuses: {
      link: "Link zur Postident",
      success: {
        header: "Sie sind bereit!",
        text: `Ihr Profil und Ihr Wallet stehen bereit! Überprüfen Sie Ihre E-Mails, 
                  um mit dem Investmentprozess fortzufahren.`,
      },
      successWithoutWallet: {
        header: "Haben Sie Geduld",
        text: `Ihre Identität wurde erfolgreich überprüft! 
        Wir richten gerade Ihr Anlegerprofil ein und benachrichtigen Sie, sobald es bereit ist`,
      },
      declined: {
        header: "Oh nein!",
        text: "Die Identitätsprüfung wurde abgelehnt. Bitte versuchen Sie es erneut!",
      },
      coupon: {
        header: "Fortfahren",
        text: `Um Ihr Gesicht zu verifizieren und in die Welt der Token einzutauchen, 
         müssen Sie den KYC-Prozess bei Ihrem Postamt abschließen.`,
      },
      pending: {
        header: "Haben Sie Geduld",
        text: `Sie erhalten eine E-Mail, wenn wir die Prüfung Ihrer Unterlagen abgeschlossen haben.
         In der Regel dauert dies ein paar Tage.`,
      },
      incomplete: {
        header: "Der Vorgang wurde nicht abgeschlossen.",
        text1: `KYC-Prozess wurde nicht vollständig durchgeführt,`,
        text2: `bitte benutzen Sie den Link und versuchen es erneut`,
      },
      expiredData: {
        header: "Ihr Ausweisdokument läuft in naher Zeit aus",
        text: `Bitte aktualisieren Sie Ihre persönlichen Daten`,
      },
      entryScreen: {
        header:
          "Tätigen Sie Ihre erste Investition, um die Funktionen der App freizuschalten",
      },
    },
    confirmInfo: "Bitte bestätigen Sie alle erforderlichen Angaben",
  },
};
export default translation;
