import { TRANSACTION_STATUS } from "../../store/consts";
import styles from "./Transaction.module.scss";
import formatMoney from "../../utils/formatMoney";

export const TransactionTextAmount = ({ status, currency, amount }) => {
  const className =
    status === TRANSACTION_STATUS.SUCCESS ? styles.success : styles.error;

  return (
    <span className={className}>
      {formatMoney(amount)} {currency}
    </span>
  );
};
