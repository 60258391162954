import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ReactComponent as LocationIcon } from "../../icons/location.svg";
import styles from "./PropertiesPage.module.scss";
import { Gallery } from "../../components/UI/Gallery/Gallery";
import { Breadcrumb } from "../../components/UI/Breadcrumb/Breadcrumb";
import useStore from "../../hooks/useStore";
import { Page } from "../../components/Page/Page";
import { InfoBlock } from "../../components/InfoBlock/InfoBlock";
import { Documents } from "../../components/Documents/Documents";

export const PropertiesPage = observer(() => {
  const { tokenId, propertyId } = useParams();
  const { t } = useTranslation();
  const {
    tokens: { property, getProperty },
    app: { markLoading },
  } = useStore();

  const images = useMemo(() => {
    return property?.images?.map((image) => {
      return {
        id: image.id,
        original: image.imageUrl,
        thumbnail: image.imageUrl,
      };
    });
  }, [property.images.length]);

  const crumbs = [
    {
      to: "/",
      text: t("mainNav.home"),
    },
    {
      to: `/home/${tokenId}/investment/new`,
      text: `${t("properties.property.tokenName")} ${
        property?.token?.name || ""
      }`,
    },
    {
      to: `/tokens/${tokenId}/properties/${propertyId}`,
      text: t("properties.property.propertyName", {
        propertyName: property?.name,
      }),
    },
  ];

  useEffect(() => {
    const getData = async () => {
      markLoading(true);
      await getProperty({ propertyId });
      markLoading(false);
    };
    getData();
  }, [markLoading, getProperty]);

  return (
    <Page>
      <div className={styles.propertiesWrapper}>
        <Breadcrumb
          crumbs={crumbs}
          className={styles.propertiesWrapper__breadcrumbs}
        />

        <h5 className={styles.propertiesWrapper__header}>{property?.name}</h5>
        <a
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${property?.addressGeocoded}`}
          className={styles.propertiesWrapper__location}
          target="_blank"
        >
          <LocationIcon /> {property?.addressGeocoded}
        </a>
        <Gallery images={images} />

        <InfoBlock
          title={t("risk.riskNotice")}
          content={t("risk.riskDescription")}
          className={styles.margin24}
        />

        <h4 className={styles.propertiesWrapper__header2}>
          {t("properties.property.type")}
        </h4>
        <div className={styles.properties}>
          <div className={styles.properties__block}>
            <div className={styles.properties__item}>
              <span className={styles.title}>Erwartete Rendite p.a.</span>
              <span>{property?.expectedReturn || "-"}</span>
            </div>
            <div className={styles.properties__item}>
              <span className={styles.title}>Geplante Laufzeit</span>
              <span>{property?.plannedTerm || "-"}</span>
            </div>
            <div className={styles.properties__item}>
              <span className={styles.title}>Ausschüttung</span>
              <span>{property?.distribution || "-"}</span>
            </div>
          </div>
          <div className={styles.properties__block}>
            <div className={styles.properties__item}>
              <span className={styles.title}>Mindestanlagesumme</span>
              <span>{property?.minimumInvestment || "-"}</span>
            </div>
            <div className={styles.properties__item}>
              <span className={styles.title}>Anlageform</span>
              <span>{property?.investmentForm || "-"}</span>
            </div>
            <div className={styles.properties__item}>
              <span className={styles.title}>Vermittler</span>
              <span>{property?.mediator || "-"}</span>
            </div>
            <div className={styles.properties__item}>
              <span className={styles.title}>Emmitent </span>
              <span>{property?.emmitent || "Lehmann & Lehmann GbR"}</span>
            </div>
          </div>
        </div>
        <h4 className={styles.propertiesWrapper__header2}>
          {/* {t("properties.property.about")} */}
          Produktbeschreibung
        </h4>
        {property?.description?.map((item) => (
          <p key={item} className={styles.propertiesWrapper__description}>
            {item}
          </p>
        ))}
      </div>
      <Documents
        className={styles.margin24}
        title={t("transactions.transaction.documents")}
        list={property?.documents}
      />
    </Page>
  );
});
