import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/UI/Button/Button";
import { ReactComponent as Network } from "../../icons/network.svg";
import { NoData } from "../NoData/NoData";

export const NoConnection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const onCLickTryAgain = () => {
    if (location?.state?.backTo) {
      navigate(location.state.backTo);
    }
  };

  return (
    <NoData
      icon={<Network />}
      title={t("noConnection.noInternetConnection")}
      text={t("noConnection.pleaseCheckYourConnection")}
      renderButton={() => {
        return (
          <Button width={265} height={48} onClick={onCLickTryAgain}>
            {t("noConnection.tryAgain")}
          </Button>
        );
      }}
    />
  );
};
