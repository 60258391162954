/* eslint-disable */
import { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./TextInput.module.scss";
import { ReactComponent as ErrorIcon } from "../../../icons/errorIcon.svg";
import { ReactComponent as CopyIcon } from "../../../icons/copy.svg";
import { ReactComponent as EyeIcon } from "../../../icons/eye-icon.svg";
import { ReactComponent as EyeOutlinedIcon } from "../../../icons/eye-outlined.svg";
import { message } from "../../Message/Message";

export const MASKS = {
  CARD_NUMBER: "CARD_NUMBER",
  GERMANY_RANK: "GERMANY_RANK",
};

const toCardNumber = (value) => {
  return value
    ?.split("")
    ?.map((i) => i.trim())
    ?.filter((i) => !!i)
    ?.reduce((acc, letter, index) => {
      if (index !== 0 && (index + 1) % 4 === 0) {
        return `${acc + letter} `;
      }
      return acc + letter;
    }, "");
};

export const toGermanyRank = (value) => {
  const val = Number.parseInt(value, 10);

  if (Number.isNaN(val)) {
    return "";
  }

  return val.toLocaleString("de-DE");
};

const fromGermanyRank = (value) => {
  const val = value.replaceAll(".", "");
  return Number.isNaN(parseInt(val, 10)) ? "" : parseInt(val, 10).toString();
};

export const TextInput = ({
  onSubmit = () => {},
  onChange = () => {},
  onBlur = () => {},
  icon,
  type = "text",
  defaultValue,
  wrapperStyles = {},
  name,
  label,
  placeholder,
  error,
  environment = "default",
  labelClassName,
  showCopy = false,
  showEye = false,
  mask,
  disabled,
  ...inputRest
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(+defaultValue || "");
  const [maskedValue, setMaskedValue] = useState(+defaultValue || "");
  const [inputType, setInputType] = useState(type);

  const onChangeHandler = (e) => {
    let { value } = e.target;

    if (mask && mask === MASKS.GERMANY_RANK) {
      value = fromGermanyRank(value);
    }
    setValue(value);
    onChange(e, value);
  };

  const onKeyPressHandler = (e) => {
    if (e.code === 13) {
      onSubmit(value);
    }
  };

  const getInputClassName = () => {
    let result = `${styles.textInput} `;
    if (icon) {
      result += `${styles.inputIcon} `;
    }
    if (error) {
      result += `${styles.inputError} `;
    }
    if (environment === "modal") {
      result += `${styles.modalInput} `;
    }
    return result;
  };

  const onCopyHandler = async () => {
    await navigator.clipboard.writeText(value);
    message({ text: t("general.copiedToClipboard"), title: "success" });
  };

  const onClickEye = async () => {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  };

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  useEffect(() => {
    if (mask && mask === MASKS.CARD_NUMBER) {
      setMaskedValue(toCardNumber(value));
    }
  }, [value, mask]);

  useEffect(() => {
    if (mask && mask === MASKS.GERMANY_RANK) {
      setMaskedValue(toGermanyRank(value));
    }
  }, [value, mask]);

  return (
    <div className={cn(styles.label, labelClassName)} style={wrapperStyles}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.textInputWrapper}>
        <input
          id={name}
          name={name}
          className={getInputClassName()}
          type={inputType}
          autoComplete="off"
          style={{
            backgroundImage: icon ? `url(${icon})` : undefined,
          }}
          value={mask ? maskedValue : value}
          onKeyPress={onKeyPressHandler}
          onChange={onChangeHandler}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          {...inputRest}
        />
        {error && !showEye && (
          <span className={styles.errorIcon}>
            <ErrorIcon />
          </span>
        )}
        {showEye && value.length > 0 && (
          <span
            className={styles.eyeIcon}
            onClick={onClickEye}
            role="presentation"
          >
            {inputType === "password" ? <EyeIcon /> : <EyeOutlinedIcon />}
          </span>
        )}
        {showCopy && (
          <div
            className={styles.copy}
            role="presentation"
            onClick={onCopyHandler}
          >
            <CopyIcon />
          </div>
        )}
      </div>
      {error && (
        <>
          <span className={styles.errorText}>{error}</span>
        </>
      )}
    </div>
  );
};
