import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./KYCPage.module.scss";
import useStore from "../../../../hooks/useStore";
import { Breadcrumb } from "../../../../components/UI/Breadcrumb/Breadcrumb";
import { TextInput } from "../../../../components/UI/TextInput/TextInput";
import { Dropdown } from "../../../../components/UI/Dropdown/Dropdown";
import { Checkbox } from "../../../../components/UI/Checkbox/Checkbox";
import { RadioGroup } from "../../../../components/UI/RadioGroup/RadioGroup";
import { Datepicker } from "../../../../components/UI/Datepicker/Datepicker";
import { Button } from "../../../../components/UI/Button/Button";
import { useKYCSchema } from "./useKYCSchema";
import { getRequestAmount } from "../../../../utils/localStorage";
import { useTypeIdOptions } from "../../../../hooks/useTypeIdOptions";
import { formatDate } from "../../../../utils/dates";
import { useCountriesOptions } from "../../../../hooks/useCountriesOptions";
import { Page } from "../../../../components/Page/Page";
import useSizes from "../../../../hooks/useSizes";
import { useResetKYC } from "../../../../hooks/useResetKYC";
import { GenderDropdown } from "../../../../components/GenderDropdown/GenderDropdown";
import { message } from "../../../../components/Message/Message";

let messageId = "";

export const KYCPage = observer(() => {
  const {
    app: { markLoading },
    investment: { getKYC, saveKYC, getCountries, countries },
    tokens: { amount },
  } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedMyOwnAccount, setCheckedMyOwnAccount] = useState(false);
  const [checkedPEP, setCheckedPEP] = useState(false);
  const { tokenId } = useParams();
  const schema = useKYCSchema();
  const storageAmount = getRequestAmount();
  const { isMobile } = useSizes();

  const onSubmit = async () => {
    try {
      const validated = await validateForm();
      if (Object.keys(validated).length === 0) {
        markLoading(true);

        const success = await saveKYC({
          kyc: values,
          tokenId,
          amount: amount || storageAmount,
        });
        navigate(`/tokens/${tokenId}/investment/steps/1`);
      }
    } catch (err) {
      //
    } finally {
      markLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
    validateForm,
    setTouched,
  } = useFormik({
    onSubmit,
    validationSchema: schema,
    initialValues: {
      idNumber: "",
      country: "",
      idValidUntil: "",
      birthDate: "",
      idIssuedOn: "",
      issuingAuthority: "",
      zipCode: "",
      citizenship: "",
      educationLevel: [],
      employmentType: [],
      salutation: [],
      idType: [],
      countryOfBorn: "",
      lastName: "",
      isPolitician: false,
      politicianSpecialization: "",
      name: "",
      appendix: "",
      address: "",
    },
  });

  const crumbs = [{ text: t("tokens.myTokens"), to: "/tokens" }];

  const radioItems = [
    { title: t("general.yes"), value: true },
    { title: t("general.no"), value: false },
  ];

  const updateDate = (field) => {
    return function updateFieldValue(date) {
      setFieldValue(field, formatDate(date));
    };
  };

  const onRadioChange = ({ value }) => {
    setFieldValue("isPolitician", value);
  };

  const educationLevelOptions = useMemo(() => {
    return (
      values?.educationLevel?.map((level) => ({
        title: t(`dropdown.kyc.studentLevel.${level.value}`),
        value: level.value,
      })) || []
    );
  }, [values.educationLevel.length]);

  const employmentTypeOptions = useMemo(() => {
    return (
      values?.employmentType?.map((level) => ({
        title: t(`dropdown.kyc.employmentType.${level.value}`),
        value: level.value,
      })) || []
    );
  }, [values.employmentType.length]);

  const idTypeOptions = useTypeIdOptions({ list: values?.idType });
  const countriesOptions = useCountriesOptions({ countries });

  const isDisabledSave = () => {
    return !checkedPEP || !checkedMyOwnAccount;
    // return false;
  };

  const handleSubmitKyc = () => {
    if (messageId) {
      toast.dismiss(messageId);
    }

    if (isDisabledSave()) {
      messageId = message({
        type: "error",
        title: t("general.error"),
        text: t("kyc.confirmInfo"),
      });
      return;
    }

    handleSubmit();
  };

  const updateDropdown = (field) => {
    return (item) => {
      const newValues = values?.[field]?.map((id) => {
        if (id.value === item.value) {
          return {
            ...id,
            selected: true,
          };
        }
        return {
          ...id,
          selected: false,
        };
      });
      setTouched({ ...touched, [field]: true });
      setFieldValue(field, newValues);
    };
  };

  const changeCitizenship = ({ country }) => {
    setFieldValue("citizenship", country?.shortName);
  };

  const changeCountry = ({ country }) => {
    setFieldValue("country", country?.shortName);
  };

  const changeCountryOfBirth = ({ country }) => {
    setFieldValue("countryOfBorn", country?.shortName);
  };

  const activeCountry = useMemo(() => {
    const country = countries?.find((c) => values.country === c.shortName);
    return {
      title: country?.shortName,
      value: country?.id,
    };
  }, [values.country, countries]);

  const activeCitizenship = useMemo(() => {
    const country = countries?.find((c) => values.citizenship === c.shortName);
    return {
      title: country?.shortName,
      value: country?.id,
    };
  }, [values.citizenship, countries]);

  const activeCountryOfBirth = useMemo(() => {
    const country = countries?.find(
      (c) => values.countryOfBorn === c.shortName
    );
    return {
      title: country?.shortName,
      value: country?.id,
    };
  }, [values.countryOfBorn, countries]);

  useEffect(() => {
    const getData = async () => {
      try {
        markLoading(true);
        const {
          countryOfBorn,
          idValidUntil,
          birthDate,
          idIssuedOn,
          placeOfBirth,
          politicianSpecialization,
          city,
          address,
          appendix,
          name,
          zipCode,
          issuingAuthority,
          lastName,
          idNumber,
          country,
          citizenship,
          salutation,
          ...kyc
        } = await getKYC();

        await getCountries();

        await setValues((prev) => ({
          ...prev,
          ...kyc,
          countryOfBorn: countryOfBorn || "",
          politicianSpecialization: politicianSpecialization || "",
          city: city || "",
          placeOfBirth: placeOfBirth || "",
          name: name || "",
          appendix: appendix || "",
          zipCode: zipCode || "",
          issuingAuthority: issuingAuthority || "",
          idNumber: idNumber || "",
          lastName: lastName || "",
          country: country || "",
          citizenship: citizenship || "",
          address: address || "",
          salutation: salutation || [],
          idValidUntil: formatDate(
            idValidUntil || dayjs(new Date()).format("YYYY-MM-DD")
          ),
          birthDate: formatDate(
            birthDate ||
              dayjs(
                new Date().setFullYear(new Date().getFullYear() - 18)
              ).format("YYYY-MM-DD")
          ),
          idIssuedOn: formatDate(
            idIssuedOn || dayjs(new Date()).format("YYYY-MM-DD")
          ),
        }));
      } catch (err) {
        //
      } finally {
        markLoading(false);
      }
    };
    getData();
  }, []);

  useResetKYC();

  return (
    <Page>
      <div className={styles.wrapper}>
        <Breadcrumb crumbs={crumbs} />
        <div className={styles.kyc}>
          <h4 className={cn(styles.kyc__header, styles.marginTop40)}>
            {t("kyc.personalInformation")}
          </h4>
          <div className={cn(styles.kyc__grid, styles.marginTop24)}>
            <div className={styles.kyc__inputRow}>
              <GenderDropdown
                onChange={updateDropdown("salutation")}
                defaultValue={values?.salutation?.find((i) => !!i.selected)}
                error={touched.salutation && errors.salutation}
              />
              <TextInput
                label={t("kyc.firstName")}
                onChange={handleChange}
                defaultValue={values.name}
                error={touched.name && errors.name}
                onBlur={handleBlur}
                name="name"
              />
            </div>

            <TextInput
              label={t("kyc.lastName")}
              onChange={handleChange}
              defaultValue={values.lastName}
              error={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              name="lastName"
            />
          </div>
          {/* <div className={styles.headers}> */}
          {/*   <h4 className={styles.kyc__header2}>{t("kyc.addressData")}</h4> */}
          {/*   {!isMobile && ( */}
          {/*     <h4 className={styles.kyc__header2}>{t("kyc.personalData")}</h4> */}
          {/*   )} */}
          {/* </div> */}
          <div className={styles.kyc__grid}>
            <Dropdown
              label={t("kyc.country")}
              options={countriesOptions}
              onChange={changeCountry}
              showResetOption={false}
              defaultValue={activeCountry}
              error={touched.country && errors.country}
            />

            <Dropdown
              label={t("kyc.professionOrActivity")}
              showResetOption={false}
              options={employmentTypeOptions}
              onChange={updateDropdown("employmentType")}
              defaultValue={values?.employmentType?.find((i) => !!i.selected)}
              error={touched.employmentType && errors.employmentType}
            />

            <TextInput
              label={t("kyc.location")}
              onChange={handleChange}
              defaultValue={values.city}
              error={touched.city && errors.city}
              onBlur={handleBlur}
              name="city"
              wrapperStyles={{ height: 100 }}
            />

            <Dropdown
              label={t("kyc.highestDegree")}
              onChange={updateDropdown("educationLevel")}
              options={educationLevelOptions}
              showResetOption={false}
              defaultValue={values?.educationLevel?.find((i) => !!i.selected)}
              error={touched.educationLevel && errors.educationLevel}
            />

            <TextInput
              onChange={handleChange}
              label={t("kyc.streetAndHouseNumber")}
              defaultValue={values.address}
              error={touched.address && errors.address}
              onBlur={handleBlur}
              name="address"
            />

            <div className={styles.kyc__subColumn}>
              <Dropdown
                label={t("kyc.citizenship")}
                options={countriesOptions}
                onChange={changeCitizenship}
                showResetOption={false}
                defaultValue={activeCitizenship}
                error={touched.citizenship && errors.citizenship}
              />
              <Datepicker
                label={t("kyc.dateOfBirth")}
                initialDate={values.birthDate}
                onChange={updateDate("birthDate")}
                error={touched.birthDate && errors.birthDate}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                showMonthDropdown
                showYearDropdown
              />
            </div>

            <TextInput
              label={t("kyc.zipCode")}
              onChange={handleChange}
              defaultValue={values.zipCode}
              error={touched.zipCode && errors.zipCode}
              onBlur={handleBlur}
              name="zipCode"
              wrapperStyles={{ height: 100 }}
            />

            <TextInput
              label={t("kyc.placeOfBirth")}
              onChange={handleChange}
              defaultValue={values.placeOfBirth}
              error={touched.placeOfBirth && errors.placeOfBirth}
              onBlur={handleBlur}
              name="placeOfBirth"
            />

            <TextInput
              onChange={handleChange}
              label={t("kyc.addressSupplement")}
              defaultValue={values.appendix}
              name="appendix"
            />

            {isMobile && (
              <div className={styles.headers}>
                <h4 className={styles.kyc__header2}>{t("kyc.personalData")}</h4>
              </div>
            )}

            <Dropdown
              label={t("kyc.countryOfBirth")}
              options={countriesOptions}
              onChange={changeCountryOfBirth}
              showResetOption={false}
              defaultValue={activeCountryOfBirth}
              error={touched.countryOfBorn && errors.countryOfBorn}
            />
          </div>
          <h4 className={styles.kyc__header2}>{t("kyc.statusQuery")}</h4>
          <div className={cn(styles.text, styles.marginTop16)}>
            {t("kyc.PEPPolicy")}
          </div>
          <div className={cn(styles.text, styles.marginTop16)}>
            {t("kyc.PEPExplanation")}
          </div>
          <RadioGroup
            className={styles.marginTop16}
            name="PPE"
            defaultActiveItem={radioItems.find(
              (i) => i.value === values?.isPolitician
            )}
            items={radioItems}
            onChange={onRadioChange}
          />
          {values?.isPolitician && (
            <div className={cn(styles.marginTop16)}>
              <div className={styles.kyc__item}>
                <TextInput
                  label={t("kyc.specify")}
                  labelClassName={styles.fullWidthInput}
                  onChange={handleChange}
                  defaultValue={values.politicianSpecialization}
                  error={
                    touched.politicianSpecialization &&
                    errors.politicianSpecialization
                  }
                  onBlur={handleBlur}
                  name="politicianSpecialization"
                />
              </div>
            </div>
          )}

          <h4
            className={cn({
              [styles.kyc__header2]: true,
              [styles.marginTop24]: !values.isPolitician,
            })}
          >
            {t("kyc.identificationData")}
          </h4>
          <div className={cn(styles.kyc__grid, styles.ids)}>
            <Dropdown
              label={t("kyc.typeId")}
              options={idTypeOptions}
              onChange={updateDropdown("idType")}
              showResetOption={false}
              error={touched.idType && errors.idType}
              defaultValue={values.idType?.find((i) => !!i.selected)}
            />
            <TextInput
              label={t("kyc.numberId")}
              onChange={handleChange}
              defaultValue={values.idNumber}
              error={touched.idNumber && errors.idNumber}
              onBlur={handleBlur}
              name="idNumber"
            />
          </div>

          <div className={styles.kyc__item}>
            <TextInput
              label={t("kyc.issuingAuthority")}
              onChange={handleChange}
              labelClassName={styles.fullWidthInput}
              defaultValue={values.issuingAuthority}
              error={touched.issuingAuthority && errors.issuingAuthority}
              onBlur={handleBlur}
              name="issuingAuthority"
            />
          </div>
          <div className={cn(styles.kyc__grid, styles.dates)}>
            <Datepicker
              label={t("kyc.IDIssuedOn")}
              maxDate={new Date()}
              initialDate={values.idIssuedOn}
              onChange={updateDate("idIssuedOn")}
              error={touched.idIssuedOn && errors.idIssuedOn}
              showMonthDropdown
              showYearDropdown
            />
            <Datepicker
              label={t("kyc.IDValidUntil")}
              initialDate={values.idValidUntil}
              onChange={updateDate("idValidUntil")}
              error={touched.idValidUntil && errors.idValidUntil}
              minDate={values.idIssuedOn || null}
              showMonthDropdown
              showYearDropdown
            />
          </div>

          <h4 className={styles.kyc__header2}>{t("kyc.beneficialOwners")}</h4>

          <Checkbox
            mode="dark"
            label={t("kyc.myAccountIsOwnInterest")}
            onChange={() => setCheckedMyOwnAccount((prev) => !prev)}
            initialState={checkedMyOwnAccount}
          />
          <h4 className={cn(styles.marginTop24, styles.kyc__header2)}>
            {t("kyc.customerInformationEffecta")}
          </h4>
          <Checkbox
            mode="dark"
            label={
              <span className={styles.kyc__effecta}>
                {t("kyc.effecta.part1")}{" "}
                <a
                  href={values?.effecta?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {values?.effecta?.name}
                </a>{" "}
                {t("kyc.effecta.part2")}{" "}
                <a
                  href={values?.agb?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {values?.agb?.name}
                </a>{" "}
                {t("kyc.effecta.part3")}
              </span>
            }
            onChange={() => setCheckedPEP((prev) => !prev)}
            initialState={checkedPEP}
          />
        </div>
        <div className={styles.footer}>
          <Button isGray maxWidth={360} onClick={navigate.bind(null, "/")}>
            {t("general.billing")}
          </Button>
          <Button maxWidth={360} onClick={handleSubmitKyc}>
            {t("general.save")}
          </Button>
        </div>
      </div>
    </Page>
  );
});
