/* eslint-disable react/destructuring-assignment */
import ReactSelect, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import styles from "./Dropdown.module.scss";

const getCustomStyles = ({ error, width, mode, maxWidth }) => ({
  control: (provided, state) => ({
    ...provided,
    fontSize: 14,
    background: mode === "dark" ? "#252737" : "#2A3143!important",
    "&:hover": {},
    width,
    maxWidth,
    boxShadow: {},
    // eslint-disable-next-line no-nested-ternary
    border: error
      ? "1px solid #E96F8C"
      : state.selectProps.menuIsOpen
      ? "1px solid #5B7DF3"
      : "1px solid transparent",
    cursor: "pointer",
    minHeight: 41,
    ...(state.isDisabled && {
      pointerEvents: "auto",
      cursor: "not-allowed",
      border: "none",
    }),
  }),
  indicatorsContainer: () => {},
  dropdownIndicator: (provided, state) => ({
    ...provided,
    "&:hover": {
      color: state.isDisabled ? "transparent" : "#bcc3d6",
    },
    color: state.isDisabled ? "transparent" : "#bcc3d6",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#2A3143",
    color: "#bcc3d6",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  input: (provided) => ({
    ...provided,
    color: "#bcc3d6",
    fontSize: 14,
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: 14,
      backgroundColor: isSelected ? "rgba(91,125,243,.149)" : "",
      ":hover": {
        backgroundColor: "rgba(186,200,250,.149)",
        cursor: "pointer",
      },
      ":active": {},
    };
  },
});
const { Option, ValueContainer } = components;

const IconOption = (props) => {
  const isWindows = navigator.platform.startsWith("Win");

  return (
    <Option {...props}>
      {props.data.icon && !isWindows && (
        <span className={styles.optionContent}>
          <span className={styles.optionContent__icon}>
            {props?.data?.icon}
          </span>
          {props?.data?.label}
        </span>
      )}
      {!(props.data.icon && !isWindows) && props?.data?.label}
    </Option>
  );
};

const IconValueContainer = ({ children, ...props }) => {
  const value = props.getValue();
  const icon = value?.[0]?.icon;
  const isWindows = navigator.platform.startsWith("Win");

  return (
    ValueContainer && (
      <ValueContainer {...props}>
        <span className={styles.valueIconContainer}>
          {icon && !isWindows && (
            <span className={styles.valueIconContainer__icon}>{icon}</span>
          )}
          {children}
        </span>
      </ValueContainer>
    )
  );
};

export const Dropdown = ({
  placeholder = "Select an item",
  width,
  options: data = [],
  onChange = () => {},
  mode,
  disabled = false,
  showResetOption = true,
  label,
  wrapperClassName,
  maxWidth,
  defaultValue,
  error,
  menuPlacement = "auto",
}) => {
  const { t } = useTranslation();
  const firstOption = { value: null, label: placeholder };
  const [selected, setSelected] = useState(
    showResetOption ? firstOption : null
  );

  const options = useMemo(() => {
    const d = showResetOption ? [firstOption, ...data] : data;
    return (
      d?.map(({ title, value, ...rest }) => {
        return {
          label: title,
          value,
          ...rest,
        };
      }) || []
    );
  }, [data.length]);

  const onChangeHandler = (value) => {
    setSelected(value);
    onChange(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(options.find((opt) => opt.value === defaultValue.value));
    }
  }, [defaultValue, options.length]);

  return (
    <div className={wrapperClassName}>
      {label && <div className={styles.label}>{label}</div>}

      <ReactSelect
        placeholder={null}
        noOptionsMessage={() => t("dropdown.noOptions")}
        isDisabled={disabled}
        options={options}
        styles={getCustomStyles({ error, width, mode, maxWidth })}
        onChange={onChangeHandler}
        value={selected}
        components={{ Option: IconOption, ValueContainer: IconValueContainer }}
        menuPlacement={menuPlacement}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
