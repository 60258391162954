import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import useStore from "../../hooks/useStore";

export const PasswordRestore = observer(() => {
  const { t } = useTranslation();
  const schema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string().email(t("login.errors.enterCorrectEmail")),
    });
  }, []);
  const {
    me: { restorePassword, updateField },
  } = useStore();
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      await restorePassword({ email: values.email });
      navigate("/verification-code");
    } catch (err) {
      if (err?.response?.status === 404) {
        setErrors({ ...errors, email: t("login.errors.userDoesNotExists") });
      }
    }
  };

  const {
    values,
    handleSubmit,
    touched,
    errors,
    handleBlur,
    setValues,
    setErrors,
  } = useFormik({
    validationSchema: schema,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: "",
    },
  });

  const onChangeEmail = ({ target: { value } }) => {
    updateField("resetEmail", value);
    setValues({ ...values, email: value });
  };

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.signInContainer}>
        <div className={styles.signIn}>
          <div className={styles.header}>{t("login.resetPassword")}</div>
          <div className={styles.subHeader} style={{ fontSize: 14 }}>
            {t("login.enterYourEmail")}
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.input}>
              <TextInput
                label={t("login.email")}
                error={touched.email && errors.email}
                environment="modal"
                name="email"
                onBlur={handleBlur}
                onChange={onChangeEmail}
              />
            </div>

            <Button type="submit" className={styles.footerButton}>
              {t("general.next")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
});
