import { flow, types } from "mobx-state-tree";
import * as api from "../api/tokens";
import { setActiveToken } from "../utils/localStorage";

const Token = types.model("Token", {
  id: types.maybeNull(types.number),
  investment: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  providerInfo: types.maybeNull(types.string),
  sharePrice: types.maybeNull(types.string),
  shares: types.maybeNull(types.string),
  timeTotal: types.maybeNull(types.string),
  tokenPrice: types.maybeNull(types.string),
  total: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  funded: types.maybeNull(types.string),
  interestRate: types.maybeNull(types.string),
  duration: types.maybeNull(types.string),
  available: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  expectedYield: types.maybeNull(types.string),
  rentPerToken: types.maybeNull(types.string),
  totalPrice: types.maybeNull(types.string),
  totalTokens: types.maybeNull(types.string),
  yourInvestment: types.maybeNull(types.string),
  productType: types.maybeNull(types.string),
  availableTokens: types.maybeNull(types.string),
  fundedMy: types.maybeNull(types.string),
  maximumInvestment: types.maybeNull(types.string),
  minimumInvestment: types.maybeNull(types.string),
  nettoIstMiete: types.maybeNull(types.string),
  investmentForm: types.maybeNull(types.string),
  formula: types.maybeNull(types.string),
  investedMy: types.maybeNull(types.string),
  equivalentMy: types.maybeNull(types.string),
});

const TokenListItem = types.model("TokenListItem", {
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  availableTokens: types.maybeNull(types.string),
  properties: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  amount: types.maybeNull(types.string),
  interestRate: types.maybeNull(types.string),
  received: types.maybeNull(types.string),
  soldOut: types.maybeNull(types.boolean),
  tokenPrice: types.maybeNull(types.string),
  tokensStatistics: types.maybeNull(types.string),
  investedMy: types.maybeNull(types.string),
  equivalentMy: types.maybeNull(types.string),
});

const PropertyImage = types.model({
  id: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
});

export const PropertyDocument = types.model({
  id: types.optional(types.number, 1),
  url: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  needFullReview: types.maybeNull(types.boolean),
  needCheckBox: types.maybeNull(types.boolean),
});

const Property = types.model("Property", {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  addressGeocoded: types.maybeNull(types.string),
  bathRoomCount: types.maybeNull(types.number),
  constructionYear: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  description: types.maybeNull(types.array(types.string)),
  imageUrl: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  livingArea: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  roomCount: types.maybeNull(types.number),
  unitsCount: types.maybeNull(types.number),
  images: types.maybeNull(types.optional(types.array(PropertyImage), [])),
  mapUrl: types.maybeNull(types.string),
  expectedReturn: types.maybeNull(types.string),
  plannedTerm: types.maybeNull(types.string),
  distribution: types.maybeNull(types.string),
  minimumInvestment: types.maybeNull(types.string),
  investmentForm: types.maybeNull(types.string),
  mediator: types.maybeNull(types.string),
  emmitent: types.maybeNull(types.string),
  documents: types.maybeNull(types.array(PropertyDocument)),
});

export const TokensModel = types
  .model("TokensModel", {
    activeToken: types.maybeNull(Token),
    amount: types.optional(types.string, ""),
    tokens: types.optional(types.array(TokenListItem), []),
    token: types.optional(Token, {}),
    selfTokens: types.optional(types.array(TokenListItem), []),
    selfToken: types.optional(TokenListItem, {}),
    properties: types.optional(types.array(Property), []),
    property: types.optional(Property, {}),
    accumulatedTokenValue: types.maybeNull(types.number),
  })
  .actions((self) => ({
    updateField(field, value) {
      self[field] = value;
    },
    updateTokenAmount: flow(function* updateTokenAmount({ tokenId, amount }) {
      try {
        const response = yield api.updateTokenAmount({ tokenId, amount });
        self.token = response;
      } catch (err) {
        // todo handle error
      }
    }),
    getAccumulatedTokenValue: flow(function* updateTokenAmount({
      tokenId,
      amount,
    }) {
      try {
        const response = yield api.getAccumulatedTokenValue({
          tokenId,
          amount,
        });
        self.accumulatedTokenValue = response;
      } catch (err) {
        // todo handle error
      }
    }),
    updateMyTokenAmount: flow(function* updateMyTokenAmount({
      tokenId,
      amount,
    }) {
      try {
        const response = yield api.updateMyTokenAmount({ tokenId, amount });
        self.token = response;
      } catch (err) {
        // todo handle error
      }
    }),
    getSelfToken: flow(function* getSelfToken({ tokenId, amount }) {
      try {
        const response = yield api.getSelfToken({ tokenId, amount });
        self.selfToken = response;
      } catch (err) {
        // todo handle error
      }
    }),
    getTokens: flow(function* getTokens() {
      try {
        const response = yield api.getTokens();
        self.tokens = response;
      } catch (err) {
        // todo handle error
      }
    }),
    getSelfTokens: flow(function* getSelfTokens() {
      try {
        const response = yield api.getSelfTokens();
        self.selfTokens = response;
        return response;
      } catch (err) {
        // todo handle error
        return [];
      }
    }),
    setActiveToken(token) {
      const selfToken = token;
      setActiveToken(selfToken);
      self.selfToken = token;
    },
    getProperties: flow(function* getProperties({ tokenId }) {
      try {
        const response = yield api.getProperties({ tokenId });
        self.properties = response;
      } catch (err) {
        // todo handle error
      }
    }),
    getProperty: flow(function* getProperty({ propertyId }) {
      try {
        const response = yield api.getProperty({ propertyId });
        self.property = response;
      } catch (err) {
        // todo handle error
      }
    }),
  }));
