import { toast } from "react-toastify";
import styles from "./Message.module.scss";
import { ReactComponent as Success } from "../../icons/success.svg";
import { ReactComponent as Error } from "../../icons/error.svg";
import { ReactComponent as Warning } from "../../icons/warning.svg";

const icons = {
  success: <Success />,
  warn: <Warning />,
  error: <Error />,
};

const ToastMessage = ({ title, text }) => {
  return (
    <div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export function message({ title, text, type = "success" }) {
  return toast[type](<ToastMessage title={title} text={text} />, {
    icon: icons[type],
  });
}
