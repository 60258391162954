import ReactImageGallery from "react-image-gallery";
import "./Gallery.scss";
import { useMemo, useRef } from "react";
import useSizes from "../../../hooks/useSizes";

export const Gallery = ({ images }) => {
  const galleryRef = useRef(null);
  const { isMobile } = useSizes(galleryRef);
  const items = useMemo(() => {
    return images.map((i) => {
      return {
        ...i,
        originalHeight: isMobile ? 194 : 455,
        // originalWidth: 800,
        thumbnailHeight: 80,
        thumbnailWidth: 140,
      };
    });
  }, [images, isMobile]);

  return (
    <div ref={galleryRef}>
      <ReactImageGallery
        showFullscreenButton={false}
        showPlayButton={false}
        showArrows={false}
        showNav={false}
        thumbnailPosition={isMobile ? "bottom" : "right"}
        items={items}
      />
    </div>
  );
};
