import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Auth.module.scss";
import { Logo } from "../../components/Logo/Logo";
import { TextInput } from "../../components/UI/TextInput/TextInput";
import { Button } from "../../components/UI/Button/Button";
import { Helpers } from "./Helpers";
import useStore from "../../hooks/useStore";

export const ChangePassword = observer(() => {
  const { t } = useTranslation();
  const {
    me: { confirmRestore, updateField, confirmNewPassword, newPassword, code },
  } = useStore();
  const navigate = useNavigate();
  const schema = useMemo(() => {
    return Yup.object().shape({
      password: Yup.string()
        .min(8, "Min length 8 symbols")
        .max(24, "Max length 24 symbols")
        .matches(
          /(.*[A-Z].*)/,
          "Value must include at least one capital symbol"
        )
        .matches(/(.*[0-9].*)/, "Value must include at least one number")
        .matches(
          /(.*[a-z].*)/,
          "Value must include at least one letter in small case"
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password")],
        t("login.errors.passwordMustMatch")
      ),
    });
  }, []);

  const onSubmit = async () => {
    const { password } = values;
    const success = await confirmRestore({ newPassword: password, key: code });
    navigate("/sign-in");
  };

  const { values, setFieldValue, errors, handleBlur, handleSubmit, touched } =
    useFormik({
      onSubmit,
      validationSchema: schema,
      validateOnChange: true,
      initialValues: {
        confirmPassword: "",
        password: "",
      },
    });

  const onChangeField = (field) => {
    return function updateStorageField({ target: { value } }) {
      updateField(field, value);
    };
  };

  useEffect(() => {
    setFieldValue("password", newPassword);
  }, [newPassword]);

  useEffect(() => {
    setFieldValue("confirmPassword", confirmNewPassword);
  }, [confirmNewPassword]);

  useEffect(
    () => () => {
      updateField("code", "");
    },
    []
  );

  return (
    <div className={styles.wrapper}>
      <Logo className={styles.logo} />
      <div className={styles.signInContainer}>
        <div className={styles.signIn}>
          <div className={styles.header}>{t("login.createNewPassword")}</div>
          <div className={styles.subHeader} />
          <form onSubmit={handleSubmit} autoComplete="new-password">
            <div className={styles.input}>
              <TextInput
                label={t("login.password")}
                type="password"
                showEye
                environment="modal"
                defaultValue={values.password}
                name="password"
                autoComplete="new-password"
                onChange={onChangeField("newPassword")}
                onBlur={handleBlur}
              />
            </div>
            <Helpers value={newPassword} />
            <div className={styles.input}>
              <TextInput
                label={t("login.confirmPass")}
                type="password"
                showEye
                environment="modal"
                autoComplete="new-password"
                name="confirmPassword"
                onChange={onChangeField("confirmNewPassword")}
                defaultValue={values.confirmPassword}
                error={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
              />
            </div>

            <Button type="submit" className="marginTop40">
              {t("general.save")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
});
