import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./MainNav.module.scss";
import { ReactComponent as HomeIcon } from "../../icons/home-icon.svg";
import { ReactComponent as HomeIconActive } from "../../icons/home-icon-active.svg";
import { ReactComponent as TokenIcon } from "../../icons/my-tokens-icon.svg";
import { ReactComponent as MyTokensIconActive } from "../../icons/my-tokens-icon-active.svg";
import { ReactComponent as TransactionsIcon } from "../../icons/transactions-icon.svg";
import { ReactComponent as TransactionsIconActive } from "../../icons/transactions-icon-active.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as SettingsIconActive } from "../../icons/settings-icon-active.svg";

import { Logo } from "../Logo/Logo";
import { Button } from "../UI/Button/Button";
import { LOCAL_STORAGE_KEYS } from "../../utils/localStorage";

const CustomLink = ({ text, Icon, to, ActiveIcon }) => {
  const location = useLocation();
  const match =
    location.pathname.split("/").slice(0, 2).join("/") === to ||
    (location.pathname.includes("home") && to === "/");

  return (
    <NavLink
      className={`${styles.mainNav__item} ${match && styles.active}`}
      to={to}
    >
      <div className={styles.icon}>{match ? <ActiveIcon /> : <Icon />}</div>
      <div className={styles.text}>{text}</div>
    </NavLink>
  );
};

export const MainNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logout = async () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    navigate("/sign-in");
  };

  return (
    <div className={styles.mainNavWrapper}>
      <aside>
        <Logo className={styles.mainNavLogo} />
        <nav role="presentation" className={styles.mainNav}>
          <div className={styles.nav_list}>
            <CustomLink
              text={t("mainNav.home")}
              to="/"
              Icon={HomeIcon}
              ActiveIcon={HomeIconActive}
            />
            <CustomLink
              text={t("mainNav.tokens")}
              to="/tokens"
              Icon={TokenIcon}
              ActiveIcon={MyTokensIconActive}
            />
            <CustomLink
              text={t("mainNav.transactions")}
              to="/transactions"
              Icon={TransactionsIcon}
              ActiveIcon={TransactionsIconActive}
            />
            <CustomLink
              text={t("mainNav.settings")}
              to="/settings"
              Icon={SettingsIcon}
              ActiveIcon={SettingsIconActive}
            />
          </div>
          <Button className={styles.danger} onClick={logout}>
            <span className={styles.danger__text}>{t("settings.logout")}</span>
          </Button>
        </nav>
      </aside>
    </div>
  );
};
