import { useTranslation } from "react-i18next";
import styles from "./Transaction.module.scss";

export const EmptyRecentTransactions = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyResetTransactions}>
      <h3 className={styles.emptyResetTransactions__header}>
        {t("transactions.yourTransactionHistoryIsEmpty")}
      </h3>
      <p className={styles.emptyResetTransactions__text}>
        {t("transactions.investAndDiveIntoTokensWorld")}
      </p>
    </div>
  );
};
