import { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./Transaction.module.scss";
import { SORT_ORDERS } from "../../store/consts";
import { TransactionTextType } from "./TransactionTextType";
import { TransactionTextStatus } from "./TransactionTextStatus";
import { TransactionTextAmount } from "./TransactionTextAmount";
import useStore from "../../hooks/useStore";
import { useFirstUpdate } from "../../hooks/useFirstUpdate";

const SORT_SEQUENCE = {
  [SORT_ORDERS.NONE]: SORT_ORDERS.ASC,
  [SORT_ORDERS.ASC]: SORT_ORDERS.DESC,
  [SORT_ORDERS.DESC]: SORT_ORDERS.NONE,
};

const SortOrderIcon = ({
  fieldName,
  className,
  onClick,
  type = SORT_ORDERS.NONE,
}) => {
  const onClickHandler = () => {
    onClick(fieldName);
  };

  return (
    <span
      tabIndex={0}
      aria-label="1"
      role="button"
      onKeyDown={() => {}}
      onClick={onClickHandler}
      className={className}
    />
  );
};

export const TransactionsTable = observer(({ getTransactions = () => {} }) => {
  const { t } = useTranslation();
  const {
    me: {
      transactions: {
        tableTransactions,
        loading,
        pagination,
        updatePagination,
      },
    },
  } = useStore();
  const firstRender = useFirstUpdate();

  const getSortOrderClassNames = (field) => {
    let result = `${styles.transactionTable__sortOrder} `;

    if (pagination[field] === SORT_ORDERS.ASC) {
      result += `${styles.ascend} `;
    }
    if (pagination[field] === SORT_ORDERS.DESC) {
      result += `${styles.descend} `;
    }

    return result;
  };

  const onChangeSortOrder = (field) => {
    const nextSort = SORT_SEQUENCE[pagination[field]];
    updatePagination(field, nextSort);
  };

  const debouncedQuery = useCallback(
    debounce((pagination) => {
      getTransactions(pagination);
    }, 400),
    []
  );

  useEffect(() => {
    getTransactions(pagination);
  }, [
    pagination.transactionType,
    pagination.amount,
    pagination.date,
    pagination.month,
    pagination.status,
    pagination.pageNumber,
  ]);

  useEffect(() => {
    if (!firstRender) {
      debouncedQuery(pagination);
    }
  }, [debouncedQuery, pagination.query]);

  if ((!loading && tableTransactions.length === 0) || loading) {
    return false;
  }

  return (
    <div className={styles.transactionTableWrapper}>
      <table className={styles.transactionTable} cellSpacing={10}>
        <thead>
          <tr>
            <th>
              {t("transactions.transactionTable.date")}{" "}
              <SortOrderIcon
                fieldName="date"
                onClick={onChangeSortOrder}
                type={pagination.date}
                className={getSortOrderClassNames("date")}
              />
            </th>
            <th>
              {t("transactions.transactionTable.transactionType")}{" "}
              <SortOrderIcon
                fieldName="transactionType"
                onClick={onChangeSortOrder}
                type={pagination.transactionType}
                className={getSortOrderClassNames("transactionType")}
              />
            </th>
            <th>
              {t("transactions.transactionTable.transactionStatus")}{" "}
              <SortOrderIcon
                fieldName="status"
                type={pagination.status}
                onClick={onChangeSortOrder}
                className={getSortOrderClassNames("status")}
              />
            </th>
            <th>{t("transactions.transactionTable.time")}</th>
            <th>
              {t("transactions.transactionTable.amount")}{" "}
              <SortOrderIcon
                fieldName="amount"
                type={pagination.amount}
                onClick={onChangeSortOrder}
                className={getSortOrderClassNames("amount")}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {tableTransactions
            ?.slice(pagination.pageNumber, pagination.pageSize)
            ?.map((transaction) => {
              return (
                <tr key={transaction.id}>
                  <td className={styles.transactionTable__date}>
                    {transaction.date}
                  </td>
                  <td>
                    {transaction.productName}
                    {/* <TransactionTextType type={transaction.transactionType} /> */}
                  </td>
                  <td>
                    <TransactionTextStatus status={transaction.status} />
                  </td>
                  <td>{transaction.time}</td>
                  <td>
                    <TransactionTextAmount
                      amount={transaction.amount}
                      status={transaction.status}
                      currency={transaction.currency}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
});
