import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../../pages/Tokens/Tokens.module.scss";
import { MASKS, TextInput } from "../UI/TextInput/TextInput";
import useStore from "../../hooks/useStore";
import { getRequestAmount, LOCAL_STORAGE_KEYS } from "../../utils/localStorage";
import formatMoney from "../../utils/formatMoney";

export const Calculate = observer(
  ({ icon, onAmountChange = () => {}, onError, availableTokens }) => {
    const { t } = useTranslation();
    const {
      tokens: {
        updateTokenAmount,
        token,
        amount,
        updateField,
        getAccumulatedTokenValue,
        accumulatedTokenValue,
      },
    } = useStore();
    const { tokenId } = useParams();
    const [hasError, setHasError] = useState(false);

    const { maximumInvestment, minimumInvestment } = token;

    const checkError = (v) => {
      const requestAmount = getRequestAmount();
      const price = +token.tokenPrice.replace(/,/g, ".");

      const error =
        +v * price + +accumulatedTokenValue * price < minimumInvestment ||
        +v * price + +accumulatedTokenValue * price > maximumInvestment ||
        +requestAmount > +availableTokens.replace(/[.,]/g, "") ||
        +v * price < minimumInvestment;
      setHasError(error);
      onError(error);
    };

    const debouncedUpdate = useCallback(
      debounce(async (value) => {
        await updateTokenAmount({ tokenId, amount: value });
        checkError(value);
      }, 500),
      [updateTokenAmount, tokenId, availableTokens, accumulatedTokenValue]
    );

    const onChangeHandler = (e, value) => {
      updateField("amount", value);
      localStorage.setItem(LOCAL_STORAGE_KEYS.REQUEST_AMOUNT, value);
      onAmountChange(value);
      debouncedUpdate(value);
    };

    useEffect(() => {
      const getData = async () => {
        await getAccumulatedTokenValue({ tokenId });
      };
      getData();
    }, [tokenId]);

    return (
      <>
        <h3 className={styles.profitHeader}>
          {t("tokens.token.calculateProfit")}
        </h3>
        <TextInput
          wrapperStyles={{ maxWidth: 345 }}
          icon={icon}
          error={
            hasError &&
            t("tokens.token.amountError", {
              minimumInvestment: formatMoney(minimumInvestment),
              maximumInvestment: formatMoney(maximumInvestment),
            })
          }
          name="amount"
          label={t("tokens.token.amount")}
          onChange={onChangeHandler}
          mask={MASKS.GERMANY_RANK}
          defaultValue={amount}
        />
      </>
    );
  }
);
