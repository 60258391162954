import { useEffect, useState } from "react";
import dayjs from "dayjs";
import VendorDatepicker from "react-multi-date-picker";
import cn from "classnames";
import styles from "./Datepicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";

const months = [
  ["Januar", "j"],
  ["Februar", "f"],
  ["März", "m"],
  ["April", "a"],
  ["Mai", "m"],
  ["Juni", "j"],
  ["Juli", "j"],
  ["August", "a"],
  ["September", "s"],
  ["Oktober", "o"],
  ["November", "n"],
  ["Dezember", "d"],
];

const weekDays = [
  ["So", "So"],
  ["Mo", "Mo"],
  ["Di", "Di"],
  ["Mi", "Mi"],
  ["Do", "Do"],
  ["Fr", "Fr"],
  ["Sa", "Sa"],
];

export const Datepicker = ({
  initialDate,
  onChange = () => {},
  label,
  error,
  minDate,
  showMonthDropdown,
  showYearDropdown,
  ...calendarRest
}) => {
  const [startDate, setStartDate] = useState(null);

  const onChangeDate = (date) => {
    const d = new Date(date?.year, date?.month - 1, date?.day);
    setStartDate(d);
    onChange(d);
  };

  useEffect(() => {
    if (initialDate && initialDate !== "Invalid Date") {
      setStartDate(dayjs(initialDate).toDate());
    }
  }, [initialDate]);

  return (
    <div
      className={cn({
        [`${styles.datepicker}`]: true,
        [`${styles.error}`]: !!error,
      })}
    >
      <div
        className={cn({
          [`${styles.datepicker__input}`]: true,
          [`${styles.error}`]: !!error,
        })}
      >
        <div className={styles.datepicker__label}>{label}</div>
        <VendorDatepicker
          portal
          showOtherDays
          editable
          calendarPosition="top"
          months={months}
          weekDays={weekDays}
          value={startDate}
          onChange={onChangeDate}
          minDate={minDate}
          placeholder="dd.mm.jjjj"
          className={styles.placeholder}
          format="DD.MM.YYYY"
          {...calendarRest}
        />
      </div>
      {error && <div className={styles.datepicker__error_message}>{error}</div>}
    </div>
  );
};
