import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./Transaction.module.scss";
import { TRANSACTION_STATUS } from "../../store/consts";

export const TransactionTextStatus = ({ status }) => {
  const { t } = useTranslation();

  const getClassName = () => {
    if (status === TRANSACTION_STATUS.SUCCESS) {
      return cn(styles.transaction__status, styles.success);
    }
    if (status === TRANSACTION_STATUS.PROCESSING) {
      return cn(styles.transaction__status, styles.processing);
    }
    if (status === TRANSACTION_STATUS.FAIL) {
      return cn(styles.transaction__status, styles.fail);
    }

    return cn(styles.transaction__status, styles.waiting);
  };

  return (
    <span className={getClassName()}>
      {t(`transactions.transactionStatuses.${status}`)}
    </span>
  );
};
