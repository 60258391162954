import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import useStore from "../../../hooks/useStore";
import { Checkbox } from "../../../components/UI/Checkbox/Checkbox";
import styles from "../InvestmentData.module.scss";
import { Dropdown } from "../../../components/UI/Dropdown/Dropdown";
import {
  BOUNDS,
  EXPERIENCE_DURATION,
  EXPERIENCE_VALUE,
} from "../../../store/consts";
import { StepsButtons } from "./StepsButtons";
import useSizes from "../../../hooks/useSizes";

const Row = observer(({ rowName, title, disabled, options }) => {
  const {
    investment: {
      steps: { activeExperience },
    },
  } = useStore();

  const onChangeExperience = ({ value }) => {
    activeExperience.updateRow(rowName, "selected", value);
  };

  const onChangeHasKnowledge = (value) => {
    const newValues = activeExperience[rowName]?.map((i) => ({
      ...i,
      hasKnowledge: value,
    }));

    activeExperience.updateHasKnowledge(rowName, newValues);
  };

  const checked = activeExperience?.[rowName]?.some((i) => i.hasKnowledge);
  const option = activeExperience?.[rowName]?.find((i) => i.selected);

  return (
    <div className={styles.experience__row}>
      <div>{title}</div>
      <div>
        <Checkbox
          disabled={disabled}
          mode="dark"
          initialState={checked}
          onChange={onChangeHasKnowledge}
        />
      </div>
      <div>
        <Dropdown
          mode="dark"
          disabled={disabled}
          options={options}
          onChange={onChangeExperience}
          showResetOption={false}
          defaultValue={option}
        />
      </div>
    </div>
  );
});

export const KnowledgeAndExperience = observer(({ setDisabledNext }) => {
  const { t } = useTranslation();
  const {
    app: { markLoading },
    investment: {
      getUserExperience,
      userExperience,
      updateUserExperience,
      steps: { noInfo, updateField, activeExperience },
    },
  } = useStore();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useSizes();

  const [disabledFields, setDisabledFields] = useState(false);
  const [showCheckNotPossible, setShowCheckNotPossible] = useState(false);
  const [showNotEnoughExperience, setShowNotEnoughExperience] = useState(false);
  const [experienceChecked, setExperienceChecked] = useState(false);

  const onChangeDontGiveInformation = () => {
    setDisabledFields((prev) => {
      return !prev;
    });
    activeExperience.updateField("noInfo", !activeExperience.noInfo);
  };
  const onChangeInvestingExperience = ({ value }) => {
    activeExperience.updateRow("investingExperience", "selected", value);
  };

  const onChangeWealth = ({ value }) => {
    activeExperience.updateRow("wealthInvestingExperience", "selected", value);
  };

  const onChangeIndependentInvestingExperience = ({ value }) => {
    activeExperience.updateRow(
      "independentInvestingExperience",
      "selected",
      value
    );
  };

  const onChangeInvestmentsPerYear = ({ value }) => {
    activeExperience.updateRow("investmentsPerYear", "selected", value);
  };

  const onChangeAverageInvestment = ({ value }) => {
    activeExperience.updateRow("averageInvestment", "selected", value);
  };

  const createExperienceOptions = (options) => {
    return options.map((option) => ({
      ...option,
      title: t(
        `investment.knowledgeAndExperience.experienceConstants.${
          EXPERIENCE_VALUE[option.value]
        }`
      ),
    }));
  };

  const createBoundsOptions = (options) => {
    return options.map((option) => ({
      ...option,
      title: t(
        `investment.knowledgeAndExperience.bondsConstants.${
          BOUNDS[option.value]
        }`
      ),
    }));
  };

  const createDurationOptions = (options) => {
    return options.map((option) => ({
      ...option,
      title: t(
        `investment.knowledgeAndExperience.durationConstants.${
          EXPERIENCE_DURATION[option.value]
        }`
      ),
    }));
  };

  const toStep1 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/1`);
  };
  const toStep3 = () => {
    navigate(`/tokens/${tokenId}/investment/steps/3`);
  };

  const checkKnowledge = async () => {
    try {
      markLoading(true);
      const response = await updateUserExperience();
      setExperienceChecked(true);
      if (response === "NOT_ENOUGH") {
        setShowNotEnoughExperience(true);
      } else if (response === "NO_INFO") {
        setShowCheckNotPossible(true);
      } else {
        navigate(`/tokens/${tokenId}/investment/steps/3`);
      }
    } catch (err) {
      //
    } finally {
      markLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        markLoading(true);
        await getUserExperience();
      } catch (err) {
        //
      } finally {
        markLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    setDisabledNext(true);

    if (experienceChecked) {
      setDisabledNext(false);
    }
  }, [experienceChecked]);

  if (showCheckNotPossible) {
    return (
      <div className={styles.info}>
        <h4 className={styles.header}>
          {t(
            "investment.knowledgeAndExperience.appropriatenessCheckNotPossible"
          )}
        </h4>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.appropriatenessCheckNotPossibleDescription1"
          )}
        </p>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.appropriatenessCheckNotPossibleDescription2"
          )}
        </p>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.appropriatenessCheckNotPossibleDescription3"
          )}
        </p>
        <StepsButtons onNextClick={toStep3} onPrevClick={toStep1} />
      </div>
    );
  }
  if (showNotEnoughExperience) {
    return (
      <div className={styles.info}>
        <h4 className={styles.header}>
          {t("investment.knowledgeAndExperience.confirmationInappropriateness")}
        </h4>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.confirmationInappropriatenessDescription1"
          )}
        </p>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.confirmationInappropriatenessDescription2"
          )}
        </p>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.confirmationInappropriatenessDescription3"
          )}
        </p>
        <p className={styles.info__text}>
          {t(
            "investment.knowledgeAndExperience.confirmationInappropriatenessDescription4"
          )}
        </p>
        <StepsButtons onNextClick={toStep3} onPrevClick={toStep1} />
      </div>
    );
  }

  return (
    <div className={styles.investigation}>
      <h4 className={styles.header}>
        {t("investment.knowledgeAndExperience.knowledgeAndExperience")}
      </h4>
      <p className={styles.text}>
        {t("investment.knowledgeAndExperience.provideInformation1")}
      </p>

      <p className={styles.text}>
        {t("investment.knowledgeAndExperience.provideInformation2")}
      </p>

      <div className={styles.margin24}>
        <Checkbox
          mode="dark"
          className={styles.smallFont}
          onChange={onChangeDontGiveInformation}
          initialState={activeExperience.noInfo}
          label={t(
            "investment.knowledgeAndExperience.iDontWantToGiveAnyInformation"
          )}
        />
      </div>
      <div className={cn(styles.itemBlock, styles.margin24)}>
        <h4 className={styles.header}>
          {t(
            "investment.knowledgeAndExperience.experienceWithCapitalMarketProduct"
          )}
        </h4>
        <div className={styles.experience}>
          {!isMobile && (
            <div className={styles.experience__header}>
              <div>{t("investment.knowledgeAndExperience.product")}</div>
              <div>{t("investment.knowledgeAndExperience.knowledge")}</div>
              <div>{t("investment.knowledgeAndExperience.experiences")}</div>
            </div>
          )}

          <Row
            disabled={disabledFields}
            rowName="investmentFunds"
            title={t("investment.knowledgeAndExperience.investmentFunds")}
            options={createDurationOptions(userExperience.investmentFunds)}
          />
          <Row
            disabled={disabledFields}
            rowName="bonds"
            title={t("investment.knowledgeAndExperience.bonds")}
            options={createDurationOptions(userExperience.bonds)}
          />
          <Row
            disabled={disabledFields}
            rowName="moneyMarketFunds"
            title={t("investment.knowledgeAndExperience.moneyMarketFunds")}
            options={createDurationOptions(userExperience.moneyMarketFunds)}
          />
          <Row
            disabled={disabledFields}
            rowName="profitRights"
            title={t(
              "investment.knowledgeAndExperience.profitParticipationRights"
            )}
            options={createDurationOptions(userExperience.profitRights)}
          />
          <Row
            disabled={disabledFields}
            rowName="subordinated"
            title={t("investment.knowledgeAndExperience.subordinated")}
            options={createDurationOptions(userExperience.subordinated)}
          />
          <Row
            disabled={disabledFields}
            rowName="virtualCurrencies"
            title={t("investment.knowledgeAndExperience.virtualCurrencies")}
            options={createDurationOptions(userExperience.virtualCurrencies)}
          />
        </div>
      </div>

      <div className={cn(styles.itemBlock, styles.margin24)}>
        <h4 className={styles.header}>
          {t(
            "investment.knowledgeAndExperience.experienceWithSecuritiesServices"
          )}
        </h4>
        <Dropdown
          mode="dark"
          disabled={disabledFields}
          showResetOption={false}
          wrapperClassName={styles.margin16}
          options={createDurationOptions(userExperience.investingExperience)}
          onChange={onChangeInvestingExperience}
          label={t(
            "investment.knowledgeAndExperience.howMuchExperienceInvestingInSecuritiesAdvice"
          )}
          defaultValue={activeExperience.investingExperience.find(
            (i) => i.selected
          )}
        />
        <Dropdown
          mode="dark"
          wrapperClassName={styles.margin24}
          disabled={disabledFields}
          showResetOption={false}
          options={createDurationOptions(
            userExperience.independentInvestingExperience
          )}
          onChange={onChangeIndependentInvestingExperience}
          label={t(
            "investment.knowledgeAndExperience.howMuchExperienceInvestingInSecuritiesAdviceFree"
          )}
          defaultValue={activeExperience.independentInvestingExperience.find(
            (i) => i.selected
          )}
        />
        <Dropdown
          mode="dark"
          wrapperClassName={styles.margin24}
          disabled={disabledFields}
          showResetOption={false}
          options={createDurationOptions(
            userExperience.wealthInvestingExperience
          )}
          onChange={onChangeWealth}
          label={t(
            "investment.knowledgeAndExperience.experienceViaInvestmentAdvice"
          )}
          defaultValue={activeExperience.wealthInvestingExperience.find(
            (i) => i.selected
          )}
        />
      </div>

      <div className={cn(styles.itemBlock, styles.margin24)}>
        <h4 className={styles.header}>
          {t(
            "investment.knowledgeAndExperience.frequencyAndVolumeOfTransaction"
          )}
        </h4>
        <Dropdown
          wrapperClassName={styles.margin16}
          mode="dark"
          label={t(
            "investment.knowledgeAndExperience.howManyInvestmentPerYear"
          )}
          showResetOption={false}
          options={createBoundsOptions(userExperience.investmentsPerYear)}
          onChange={onChangeInvestmentsPerYear}
          disabled={disabledFields}
          defaultValue={activeExperience.investmentsPerYear.find(
            (i) => i.selected
          )}
          menuPlacement="top"
        />
        <Dropdown
          wrapperClassName={styles.margin16}
          mode="dark"
          label={t(
            "investment.knowledgeAndExperience.averageValueOfTransaction"
          )}
          showResetOption={false}
          options={createExperienceOptions(userExperience.averageInvestment)}
          onChange={onChangeAverageInvestment}
          disabled={disabledFields}
          defaultValue={activeExperience.averageInvestment.find(
            (i) => i.selected
          )}
          menuPlacement="top"
        />
      </div>

      {/* <h4 className={cn(styles.header, styles.margin24)}> */}
      {/*   {t("investment.knowledgeAndExperience.knowledgeAndExperience")} */}
      {/* </h4> */}
      {/* <div className={styles.whiteText}> */}
      {/*   {t( */}
      {/*     "investment.knowledgeAndExperience.knowledgeAndExperienceRiskFactor" */}
      {/*   )} */}
      {/* </div> */}

      <StepsButtons onNextClick={checkKnowledge} onPrevClick={toStep1} />
    </div>
  );
});
