import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "../../pages/Tokens/Tokens.module.scss";
import formatMoney from "../../utils/formatMoney";

export const Balance = ({
  rentPerToken,
  investment,
  tokenPrice,
  investmentMy,
  minimumInvestment,
  maximumInvestment,
  nettoIstMiete,
  investmentForm,
  formula,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.info}>
        <div className={cn(styles.info__block, styles.block)}>
          <h4 className="marginTop24">{t("tokens.token.balance")}</h4>
          <div className={styles.block__item}>
            <p className={styles.title}>Anlageform</p>
            <p className={styles.value}>{investmentForm}</p>
          </div>
          <div className={styles.block__item}>
            <p className={styles.title}>{t("tokens.token.tokenPrice")}</p>
            <p className={styles.value}>{tokenPrice} €</p>
          </div>
          <div className={styles.block__item}>
            <p className={styles.title}>Mindestanlagesumme</p>
            <p className={styles.value}>{formatMoney(minimumInvestment)} €</p>
          </div>
          <div className={styles.block__item}>
            <p className={styles.title}>Maximalanlagesumme</p>
            <p className={styles.value} style={{ whiteSpace: "nowrap" }}>
              {formatMoney(maximumInvestment)} €
            </p>
          </div>
          <>
            <div className={styles.block__item}>
              <p className={styles.title}>Meine Investition</p>
              <p className={styles.value}>
                {formatMoney(+investmentMy * parseInt(tokenPrice, 10))} €
              </p>
            </div>
            <div className={styles.block__item}>
              <p className={styles.title}>Meine Tokens</p>
              <p className={styles.value}>{investmentMy || 0}</p>
            </div>
          </>
        </div>
        <div className={cn(styles.info__block, styles.block)}>
          <h4 className="marginTop24">Rechenbeispiel</h4>
          <div className={styles.block__item}>
            <p className={styles.title}>Netto-IST-Miete (variabel)</p>
            <p className={styles.value}>{formatMoney(nettoIstMiete)} €</p>
          </div>
          <div className={styles.block__item}>
            <p className={styles.title}>Investition</p>
            <p className={styles.value}>{investment}</p>
          </div>
          <div className={styles.block__item}>
            <p className={styles.title}>Rendite (jährlich)</p>
            <p className={styles.value}>{rentPerToken}</p>
          </div>
          <div className={styles.block__item}>
            <p className={styles.title}>
              Formel/Berechnung
              <span className={styles.value}>{formula}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
