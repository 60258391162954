import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import dayjs from "dayjs";
import styles from "./ActiveTransactions.module.scss";
import { TRANSACTION_STATUS } from "../../../store/consts";
import { Actions } from "./Actions";
import { TokenImageByURI } from "../../../components/TokenImageByURI/TokenImageByURI";
import useStore from "../../../hooks/useStore";
import formatMoney from "../../../utils/formatMoney";

export const ACTIVE_TRANSACTION_FORMAT = "HH:mm:ss";

export const ActiveTransactions = observer(({ transactions }) => {
  const { t } = useTranslation();
  const [openAction, setOpenAction] = useState(null);
  const {
    app: { loading },
  } = useStore();

  const openActions = (transactionId) => {
    setOpenAction((prev) => (prev === transactionId ? null : transactionId));
  };

  useEffect(() => {
    const event = (e) => {
      const action = e.target.closest("[data-action='action']");
      if (!action && openAction) {
        setOpenAction(null);
      }
    };
    document.addEventListener("click", event);
    return () => document.removeEventListener("click", event);
  }, [openAction]);

  if (!loading && transactions.length === 0) {
    return (
      <div className={styles.empty}>
        <h4>{t("transactions.activeTransactionsEmptyHeader")}</h4>
        <p>{t("transactions.activeTransactionsEmptyText")}</p>
      </div>
    );
  }

  return (
    <div className={styles.activeTransactions}>
      <h3 className={styles.activeTransactions__header}>
        {t("transactions.activeTransactions")}
      </h3>
      <div className={styles.activeTransactions__list}>
        {transactions?.map((transaction) => {
          return (
            <div key={transaction?.id} className={styles.transaction}>
              <div className={styles.transaction__line} />
              <div className={styles.transaction__content}>
                <div className={styles.transaction__icon}>
                  <TokenImageByURI tokenId={transaction?.tokenId} />
                </div>
                <div className={styles.transaction__block}>
                  <div className={styles.transaction__type}>
                    {/* {t( */}
                    {/*   `transactions.transactionTypes.${transaction.transactionType}` */}
                    {/* )} */}
                    {transaction.productName}
                  </div>
                  <div
                    className={cn({
                      [styles.transaction__status]: true,
                      [styles.transaction__processing]:
                        transaction.status === TRANSACTION_STATUS.PROCESSING,
                      [styles.transaction__error]:
                        transaction.status === TRANSACTION_STATUS.FAIL,
                      [styles.transaction__waiting]:
                        transaction.status === TRANSACTION_STATUS.WAITING,
                    })}
                  >
                    {t(
                      `transactions.transactionStatuses.${transaction.status}`
                    )}
                  </div>
                  <div className={styles.transaction__amount}>
                    {formatMoney(transaction.amount)} {transaction.currency}
                  </div>
                  <div className={styles.transaction__time}>
                    {dayjs
                      .unix(transaction.unix)
                      .format(ACTIVE_TRANSACTION_FORMAT)}
                  </div>
                </div>

                <Actions
                  transaction={transaction}
                  isOpen={openAction === transaction?.id}
                  onClickHandler={openActions}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
