import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import styles from "./Auth.module.scss";

export const Helpers = ({ value = "", onChangeError = () => {} }) => {
  const { t } = useTranslation();

  useEffect(() => {
    onChangeError(
      value.length >= 8 &&
        value
          .split("")
          .some((i) => /[a-zA-Z]/.test(i) && i === i.toUpperCase()) &&
        value
          .split("")
          .some((i) => /[a-zA-Z]/.test(i) && i === i.toLowerCase()) &&
        value.split("").some((i) => !Number.isNaN(parseInt(i, 10)))
    );
  }, [value]);

  return (
    <div className={styles.helpers}>
      <div
        className={cn({
          [`${styles.helpers__item}`]: true,
          [`${styles.active}`]: value.length >= 8,
        })}
      >
        {t("login.errors.minLength", { count: 8 })}
      </div>
      <div
        className={cn({
          [`${styles.helpers__item}`]: true,
          [`${styles.active}`]: value
            .split("")
            .some((i) => /[a-zA-Z]/.test(i) && i === i.toUpperCase()),
        })}
      >
        {t("login.errors.capitalLetter")}
      </div>
      <div
        className={cn({
          [`${styles.helpers__item}`]: true,
          [`${styles.active}`]: value
            .split("")
            .some((i) => /[a-zA-Z]/.test(i) && i === i.toLowerCase()),
        })}
      >
        {t("login.errors.lowerCase")}
      </div>
      <div
        className={cn({
          [`${styles.helpers__item}`]: true,
          [`${styles.active}`]: value
            .split("")
            .some((i) => !Number.isNaN(parseInt(i, 10))),
        })}
      >
        {t("login.errors.number")}
      </div>
    </div>
  );
};
