import { useState } from "react";
import styles from "./SearchInput.module.scss";

export const SearchInput = ({
  onSubmit = () => {},
  onChange = () => {},
  placeholder,
  width,
  className,
}) => {
  const [value, setValue] = useState("");

  const onChangeHandler = ({ target: { value } }) => {
    setValue(value);
    onChange(value);
  };
  const onKeyPressHandler = (e) => {
    if (e.code === 13) {
      onSubmit(value);
    }
  };

  return (
    <input
      className={`${styles.searchInput} ${className || ""}`}
      type="text"
      value={value}
      style={{ width }}
      onKeyPress={onKeyPressHandler}
      onChange={onChangeHandler}
      placeholder={placeholder}
    />
  );
};
