import cn from "classnames";
import styles from "./infoBlock.module.scss";
import { ReactComponent as InfoBlockIcon } from "../../icons/info-icon.svg";

export const InfoBlock = ({ title, content, className }) => {
  return (
    <div className={cn(styles.infoBlock, className)}>
      <div className={styles.infoBlock__header}>
        <div className={styles.icon}>
          <InfoBlockIcon />
        </div>
        <div className={styles.text}>{title}</div>
      </div>
      <div className={styles.infoBlock__content}>{content}</div>
    </div>
  );
};
